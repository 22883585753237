export type TwilightIcon =
  | 'Calendar'
  | 'User'
  | 'ChevronRight'
  | 'CreditCard'
  | 'Clipboard'
  | 'DollarSign'
  | 'Globe'
  | 'LifeBuoy'
  | 'MessageCircle'
  | 'PhoneIncoming'
  | 'Plus'
  | 'Repeat'
  | 'Search'
  | 'Shield'
  | 'Slash'
  | 'Smartphone'
  | 'Smile'
  | 'Tag'
  | 'Trash2'
  | 'Truck'
  | 'Bell'
  | 'Sun'
  | 'Feather'
  | 'Gift'
