import { ProblemListProblem, ReferralOption, StateAbbr } from '../..'

export const YES_OR_NO = ['yes', 'no'] as const
export type YesNo = (typeof YES_OR_NO)[number]
type PosNeg = 'positive' | 'negative'

export type ClosedNoteDetails = {
  locked_at: string
  locked_by_name: string
  locked_by_calendar_id: number
  locked_by_user_id: string
  attestation?: Attestation
}

export type Attestation = {
  attestation_notes?: string
  attested_at: string
  attested_by_user_id: string
  attested_by_display_name: string
}

type ConsultationVisitNoteContentCore = {
  past_buprenorphone_exposure?: string
  patient_story?: string
  treatment_history?: string
  referral?: ReferralOption
  referralPartnerId?: string
  provider?: string
  organization?: string
  additional?: string
}
type ConsultationVisitNoteContentInsurance =
  | {
      has_insurance: 'yes'
      insurance_provider?: string
      other_insurance_provider?: string
    }
  | {
      has_insurance: 'no'
    }
export type ConsultationVisitNoteContent = ConsultationVisitNoteContentCore &
  ConsultationVisitNoteContentInsurance &
  Partial<ClosedNoteDetails>

export type UDSVisitNoteContent = {
  drug_screen_administered: YesNo
  drug_screen_alcohol_result: PosNeg
  drug_screen_amphetamines_result: PosNeg
  drug_screen_barbiturates_result: PosNeg
  drug_screen_benzodiazepines_result: PosNeg
  drug_screen_buprenorphine_result: PosNeg
  drug_screen_cocaine_result: PosNeg
  drug_screen_fentanyl_result: PosNeg
  drug_screen_marijuana_result: PosNeg
  drug_screen_methadone_result: PosNeg
  drug_screen_methamphetamine_result: PosNeg
  drug_screen_methylenedioxymethamphetamine_result: PosNeg
  drug_screen_opiates_result: PosNeg
  drug_screen_oxycodone_result: PosNeg
  drug_screen_phencyclidine_result: PosNeg
  drug_screen_synthetic_cannabinoid_result: PosNeg
  drug_screen_tramadol_result: PosNeg
  drug_screen_comments?: string
} & Partial<ClosedNoteDetails>

export type PeerCheckinVisitNoteContent = {
  focus: string
  intervention: string
  plan: string
  potentialNeeds: string
  progress: string
  response: string
} & Partial<ClosedNoteDetails>

export type LegacyVisitNoteContent = {
  acuity_content?: string
  content?: string
} & Partial<ClosedNoteDetails>

export type FollowupBHCMVisitNoteContent = {
  symptoms: string
  objective_measures: string
  treatments: string
  plan_updates: string
  problems_addressed: ProblemListProblem[]
} & Partial<ClosedNoteDetails>

export type InitialBHCMVisitNoteContent = {
  symptoms: string
  objective_measures: string
  problems_addressed: ProblemListProblem[]
} & Partial<ClosedNoteDetails>

export type InductionNoteContentForm = {
  participating_in_counseling: 'yes' | 'no'
  participating_in_recovery_services: 'yes' | 'no'
  witnessed_overdose: 'yes' | 'no'
  history_of_overdose: 'yes' | 'no'
  alcohol_use: 'yes' | 'no'
  tobacco_use: 'yes' | 'no'
  marijuana_use: 'yes' | 'no'
  benzodiazepines_use: 'yes' | 'no'
  amphetamine_use: 'yes' | 'no'
  cocaine_use: 'yes' | 'no'
  other_use: 'yes' | 'no'
  medication_name: string
  medication_strength: string
  medication_days_supply: string
  medication_quantity: string
  pdmp_consistent_with_history: 'yes' | 'no'
  plan: string
  induction_email: 'Patient not entering induction' | string
  problems_addressed: ProblemListProblem[]
  effective_date: string
  overdose_history: 'yes' | 'no'
  naloxone_supply: string
  telehealth_consent: boolean
  history_of_present_illness: string
  medical_pmh: string
  psychiatric_pmh: string
  hospitalizations_pmh: string
  surgical_pmh: string
  active_medications_pmh: string
  allergies_and_reactions_pmh: string
  social_and_family_pmh: string
  opioid_history: string
  opioid_treatment_history: string
  overdose_history_comments: string
  witnessed_overdose_comments: string
  alcohol_use_comments: string
  tobacco_use_comments: string
  marijuana_use_comments: string
  benzodiazepines_use_comments: string
  amphetamine_use_comments: string
  cocaine_use_comments: string
  other_use_comments: string
  participating_in_counseling_comments: string
  participating_in_recovery_services_comments: string
  observed_and_examination: string
  pdmp_consistent_with_history_comments: string
  treatment_goals: string
  assessment: string
  heroin_or_fentanyl_regular_basis: 'yes' | 'no' | undefined
  suboxone_use_last_week: 'yes' | 'no' | undefined
  [key: `plan_${string}`]: string
  employee_address: string
  verified_patient_location: boolean
  patient_city: string
  patient_state: StateAbbr
}

export type InductionNoteContent = InductionNoteContentForm & ClosedNoteDetails
