import { AxiosRequestConfig, Method } from 'axios'

// For stylistic + consistency reasons, only support the uppercase versions of the Axios HTTP methods
export type OpheliaMethod = Uppercase<Method>

type PathSegments<Path extends string> = Path extends `${infer SegmentA}/${infer SegmentB}`
  ? ParamOnly<SegmentA> | PathSegments<SegmentB>
  : ParamOnly<Path>

type ParamOnly<Segment extends string> = Segment extends `:${infer Param}` ? Param : never

type RouteParams<Path extends OpheliaOperation> = {
  [Key in PathSegments<Path>]: string
}

export type OpheliaSpec = Record<OpheliaOperation, OpheliaRequestResponse<OpheliaOperation>>

export type OpheliaRoute<T extends OpheliaOperation, V extends OpheliaRequestResponse<T>> = Record<
  T,
  V
>

export type OpheliaOperation = `${OpheliaMethod} /${string}`

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type OpheliaResponse = any

export type OpheliaRequestClientParams = RouteParams<OpheliaOperation>

export type OpheliaRequestRouterParams<T> = Record<keyof T, string>

// This type is a copy of ParsedQs used by express
export type OpheliaClientRequestQuery = {
  [key: string]:
    | undefined
    | string
    | string[]
    | OpheliaClientRequestQuery
    | OpheliaClientRequestQuery[]
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type OpheliaClientRequestBody = any

export type OpheliaRequest<T extends OpheliaOperation> = Partial<{
  params: RouteParams<T>
  query: OpheliaClientRequestQuery
  data: OpheliaClientRequestBody
}>

export type OpheliaRequestResponse<T extends OpheliaOperation> = {
  req: OpheliaRequest<T>
  res: OpheliaResponse
}

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export interface OpheliaClientRequestConfig<Params, Data, Query>
  // Omit the fields that are added during axios instantiation and route declaration (eg: 'GET /foo')
  extends Omit<AxiosRequestConfig<Data>, 'baseURL' | 'url' | 'method'> {
  params?: Params
  data?: Data
  query?: Query
}
