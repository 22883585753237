export type SegmentEventName =
  | 'Account Created'
  | 'Account Email Confirmed'
  | 'Account Recovery Requested'
  | 'Added to Insurance Allow List'
  | 'Addendum added'
  | 'Appointment Canceled'
  | 'Appointment Expired'
  | 'Appointment Confirmation Complete'
  | 'Appointment Marked Finished'
  | 'Appointment Reminded'
  | 'Appointment Rescheduled'
  | 'Appointment Scheduled'
  | 'Appointment Updated'
  | 'Appointment Transferred'
  | 'Autologin'
  | 'Community Early Access Request'
  | 'Consultation Call Scheduled'
  | 'Consultation Queue Config Changed'
  | 'Consultation Call Queue Status - Open'
  | 'Consultation Call Queue Status - Closed'
  | 'Consultation Call Queue Status - Closing Soon'
  | 'Consultation Call Queue Status - Error'
  | 'Free Consultation Call Scheduled'
  | 'Copay Invoice Created'
  | 'Cost Analysis Page Visited'
  | 'Cost Analysis Page Progress'
  | 'Duplicate Phone Number Submitted For Account Creation'
  | 'Duplicate Email Submitted For Account Creation'
  | 'EMR Email Reassigned'
  | 'EMR Phone Reassigned'
  | 'Existing Account Create Request'
  | 'Financial Agreement Consent Signed'
  | 'Failure to pay'
  | 'Treatment Consent Signed'
  | 'ID Uploaded'
  | 'Induction Note Locked'
  | 'Insurance Data Updated'
  | 'Insurance Review Status Updated'
  | 'Insurance Updated'
  | 'Insurance Verification Failed'
  | 'Insurance Verified'
  | 'Insurance Marked Has'
  | 'Insurance Marked None'
  | 'Intake Completed'
  | 'Lab ordered'
  | 'Lead Created'
  | 'Learn More Clicked'
  | 'Level of Care Status Changed'
  | 'Note Attested'
  | 'Note signed'
  | 'No Patient Facing Appointments Available'
  | 'Notification Marked As Seen'
  | 'Nurse Care Manager Updated'
  | 'Ophelia.com Session'
  | 'Patient Added To Payors Queue'
  | 'Patient Portal OTP Requested'
  | 'Patient Portal Password Updated'
  | 'Patient Portal Phone Updated'
  | 'Patient Portal Zoom Embed Initialization Failed'
  | 'Patient Portal Zoom Embed Initialization Succeeded'
  | 'Patient Portal Zoom Embed Join Failed'
  | 'Patient Portal Zoom Embed Join Succeeded'
  | 'Patient Portal Zoom Embed Fallback Selected'
  | 'Patient Status Changed'
  | 'Patient Suicide Risk'
  | 'Password Reset Request'
  | 'Password Reset Request (No account found)'
  | 'Password Updated'
  | 'Payment Failed'
  | 'Payment Succeeded'
  | 'Payment Source Created'
  | 'Personal Data Created'
  | 'Personal Data Updated'
  | 'Prescribing Clinician Updated'
  | 'Pre-WC Start Tasks Button Clicked'
  | 'Queued Consultation Call Created'
  | 'Send Email Again Clicked'
  | 'Self Service Appointment Scheduled'
  | 'Self Service Appointment Rescheduled'
  | 'Shipment updated'
  | 'Shipment tracking url created'
  | 'Shipping Data Updated'
  | 'State Identified From Screening Form'
  | 'Subscription Updated'
  | 'Subscription Restarted Because Removed From Allow List'
  | 'Subscription Not Started Because Patient On Allow List'
  | 'Standby Notification Clicked'
  | 'Standby Notification Triggered'
  | 'Standby Notification Preferences Set'
  | 'Standby List Task Opened'
  | 'Standby List Task Seen'
  | 'Standby List Snapshot'
  | 'Standby Appointment Rescheduled'
  | 'Standby Appointment Already Taken'
  | 'Standby Appointment No Slots Open On Calendar Load'
  | 'Send Individual MBC Form Reminder'
  | 'Send MBC Form Reminder'
  | 'Task Completed'
  | 'Welcome Call Booked'
  | 'Zoom Redirect Page Error'
  | 'Zoom Redirect Page Visited'
  | 'File Uploaded'
  | 'Pay Now Clicked'
  | 'Call Pharmacy Clicked'
  | 'Join Zoom Clicked'
  | 'No-Show Invoice Created'
  | 'No-Show Invoice Failed'
  | 'Required Tasks Completed'
  | 'State Collected In Welcome Flow'
  | 'Release of Information Created'
  | 'Release of Information Deleted'
  | 'Home Data Updated'
  | PatientReleaseOfInformationStatusChangeEvent
  | ReferralSegmentEvent
  | 'Completed MBC'
  | 'Prescription Sent'
  | 'Waived No Show Used'
  | 'Patient would like to speak with a financial counselor'
  | 'ABP Non-Compliance'
  | 'Invoice Voided'
  | 'Upcoming Subscription Invoice'
  | 'Partner Welcome Page - Have A Question Visible'
  | 'Partner Welcome Page - Have A Question Clicked'
  | 'ABP Created'
  | 'Chatbox Status Changed'
  | 'Ophelink Preview Assets Requested'

export type PatientReleaseOfInformationStatusChangeEvent =
  | 'Release of Information Approved'
  | 'Release of Information Under Review'
  | 'Release of Information Needs Changes'
  | 'Release of Information Expired'
  | 'Release of Information Revoked'
  | 'Release of Information In Progress'

export type ReferralSegmentEvent =
  | 'Provider Referral Created'
  | 'Friend Referral Link Copied'
  | 'New Referral Created'
  | 'Referral Link Clicked'
  | 'Existing Referral Found'
  | 'Referral Link Clicked & Existing Referral Found'
  | 'New Provider Referral Captured During Welcome Call'

export type SegmentMethod = 'track' | 'identify'

export type SegmentIntegrations = {
  'Google Analytics'?: {
    clientId: string
  }
}

/**
 * NOTE: This interface is almost an exact copy of the `apiObject` type from Rudderstack.
 * This only modification is that `undefined` is not a permitted value, as that would
 * violate our Firestore rule against writing undefined to the database.
 */
type SegmentRecord = {
  [index: string]:
    | string
    | number
    | boolean
    | SegmentRecord
    | (string | number | boolean | SegmentRecord)[]
}

export type SegmentContextProperties = SegmentRecord

export type SegmentTraits = SegmentRecord

export type SegmentContext = {
  event?: SegmentEventName
  properties?: SegmentContextProperties
  integrations?: SegmentIntegrations
  userId?: string
  anonymousId?: string
  traits?: SegmentTraits
}

// This matches the RS Contextual Fields
export type SegmentAdditionalContext = {
  ip?: string
  userAgent?: string
}
