import React, { Children, PropsWithChildren, ReactNode } from 'react'
// eslint-disable-next-line no-restricted-imports
import { Box, Center, Flex, Stack, useMantineTheme } from '@mantine/core'
import { Tooltip } from '../Tooltip'
import { MantineColor, colorToHex } from '../inputs'

export type TimelineItemProps = PropsWithChildren<{
  color?: MantineColor
  solidBullet?: boolean
  fullWidthBullet?: boolean
  bulletTooltip?: string
  lineTooltip?: string
  bullet: ReactNode
  bulletFooter?: ReactNode
  // Used to set every child in its corresponding row within the timeline grid
  index: number
}>

export const Timeline = ({
  children,
  frameColor,
}: PropsWithChildren<{ frameColor?: MantineColor }>) => {
  const {
    other: { colors, sizes },
    radius,
  } = useMantineTheme()
  const backgroundColor = colorToHex(frameColor, colors)
  const lastIndex = Children.toArray(children).length
  /*
   * Grid uses the same spacing between verical & horizontal items,
   * adding a bottom margin to all children will visually add more spacing.
   */
  const extraBottomSpacing = sizes.padding.lg

  return (
    <Box
      sx={[
        {
          gap: sizes.padding.lg,
          display: 'grid',
          // First column uses auto size (for bullet) while second column uses the remainder (children)
          gridTemplateColumns: 'auto 1fr',
          '.mantine-Timeline-itemBody': {
            paddingLeft: backgroundColor ? sizes.padding.lg : 0,
            paddingRight: backgroundColor ? sizes.padding.lg : 0,
            marginBottom: extraBottomSpacing,
          },
          '.mantine-Timeline-itemBullet': {
            marginBottom: extraBottomSpacing,
          },
          '.mantine-Timeline-itemBody-0': {
            paddingTop: backgroundColor ? sizes.padding.lg : 0,
          },
        },
        {
          // Moved to second array item in case `lastIndex - 1 = 0` to merge with above
          [`.mantine-Timeline-itemBody-${lastIndex - 1}`]: {
            paddingBottom: backgroundColor ? sizes.padding.lg : 0,
          },
        },
      ]}
    >
      <Box
        sx={{
          gridRowStart: 1,
          // Background must extend to the last row
          gridRowEnd: lastIndex + 1,
          // Background only surrounds the second column (children)
          gridColumnStart: 2,
          backgroundColor: backgroundColor ?? 'unset',
          borderRadius: radius.sm,
          marginBottom: extraBottomSpacing,
        }}
      />
      {children}
    </Box>
  )
}

const TimelineItem = ({
  children,
  color,
  bullet,
  bulletTooltip,
  solidBullet = false,
  fullWidthBullet = false,
  bulletFooter,
  index,
}: TimelineItemProps) => {
  const {
    other: { sizes, colors },
    radius,
  } = useMantineTheme()
  const borderColor = colorToHex(color, colors) ?? colors.actions[2]
  return (
    <>
      <Flex
        className={`mantine-Timeline-itemBullet mantine-Timeline-itemBullet-${index}`}
        direction='column'
        align='center'
        sx={{
          // Grid Row & Columns start at index 1
          gridRowStart: index + 1,
          gridColumnStart: 1,
          // When no children present, let the bullet also use the reserved space for children
          gridColumnEnd: children ? 2 : 3,
        }}
      >
        <Tooltip native withinPortal label={bulletTooltip} disabled={!bulletTooltip}>
          <Center
            sx={({ other: { sizes, colors }, radius }) => {
              const borderColor = colorToHex(color, colors) ?? colors.actions[2]

              return {
                alignSelf: 'center',
                width: fullWidthBullet ? '100%' : 'auto',
                borderRadius: radius.lg,
                // Use boxShadow to avoid borders from increasing the size
                boxShadow: `0 0 0 ${sizes.border.md} ${borderColor} inset`,
                // Keep sizes consistent
                minWidth: `calc(${sizes.padding.xl} * 4)`,
                backgroundColor: solidBullet ? borderColor : 'unset',
              }
            }}
          >
            <Box
              sx={({ other: { sizes } }) => ({
                paddingLeft: children ? sizes.padding.md : sizes.padding.xl,
                paddingRight: children ? sizes.padding.md : sizes.padding.xl,
                paddingTop: sizes.padding.sm,
                paddingBottom: sizes.padding.sm,
              })}
            >
              {bullet}
            </Box>
          </Center>
        </Tooltip>
        {bulletFooter}
        {children && (
          <Flex sx={{ flex: 1 }} direction='column' align='center'>
            <Flex sx={{ flex: 1, width: sizes.border.md, backgroundColor: borderColor }} />
            <Flex
              sx={{
                height: sizes.gap.md,
                width: sizes.gap.md,
                backgroundColor: borderColor,
                borderRadius: radius.sm,
              }}
            />
          </Flex>
        )}
      </Flex>
      {children && (
        <Stack
          className={`mantine-Timeline-itemBody mantine-Timeline-itemBody-${index}`}
          sx={{
            gridRowStart: index + 1,
            gridColumnStart: 2,
          }}
          spacing='sm'
        >
          {children}
        </Stack>
      )}
    </>
  )
}

Timeline.Item = TimelineItem
