import { useMediaQuery } from '@mantine/hooks'
import { useMantineTheme } from '@shared/components'

export const useCommunityDims = () => {
  const theme = useMantineTheme()

  return {
    isMobile: useMediaQuery(`(max-width: ${theme.breakpoints.md})`),
    mobileMediaQuery: `@media (max-width: ${theme.breakpoints.md})`,
    isDesktop: useMediaQuery(`(min-width: ${theme.breakpoints.md})`),
    desktopMediaQuery: `@media (min-width: ${theme.breakpoints.md})`,
  }
}
