import dayjs from 'dayjs'
import dayjsAdvancedFormat from 'dayjs/plugin/advancedFormat'
import dayjsIsBetween from 'dayjs/plugin/isBetween'
import dayjsIsSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import dayjsIsSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import dayjsIsToday from 'dayjs/plugin/isToday'
import dayjsIsTomorrow from 'dayjs/plugin/isTomorrow'
import dayjsIsYesterday from 'dayjs/plugin/isYesterday'
import dayjsIsoWeek from 'dayjs/plugin/isoWeek'
import dayjsRelativeTime from 'dayjs/plugin/relativeTime'
import dayjsTimezone from 'dayjs/plugin/timezone'
import dayjsUpdateLocal from 'dayjs/plugin/updateLocale'
import dayjsUtc from 'dayjs/plugin/utc'
import dayjsWeekday from 'dayjs/plugin/weekday'
import type { DateToken } from './plugins/format'
import dayjsGetBetween from './plugins/getBetween'
import dayjsisWithinLast from './plugins/isWithinLast'
import dayjsisWithinNext from './plugins/isWithinNext'
import getDay from './plugins/getDay'

dayjs.extend(dayjsIsToday)
dayjs.extend(dayjsRelativeTime)
dayjs.extend(dayjsIsTomorrow)
dayjs.extend(dayjsIsYesterday)
dayjs.extend(dayjsUtc)
dayjs.extend(dayjsWeekday)
dayjs.extend(dayjsTimezone)
dayjs.extend(dayjsAdvancedFormat)
dayjs.extend(dayjsGetBetween)
dayjs.extend(dayjsisWithinNext)
dayjs.extend(dayjsisWithinLast)
dayjs.extend(dayjsIsBetween)
dayjs.extend(dayjsUpdateLocal)
dayjs.extend(dayjsIsoWeek)
dayjs.extend(dayjsIsSameOrAfter)
dayjs.extend(dayjsIsSameOrBefore)
dayjs.extend(getDay)

export {
  DateToken,
  dayjs,
  dayjsAdvancedFormat,
  dayjsGetBetween,
  dayjsIsBetween,
  dayjsIsSameOrAfter,
  dayjsIsSameOrBefore,
  dayjsIsToday,
  dayjsIsTomorrow,
  dayjsIsYesterday,
  dayjsIsoWeek,
  dayjsRelativeTime,
  dayjsTimezone,
  dayjsUpdateLocal,
  dayjsUtc,
  dayjsWeekday,
  dayjsisWithinLast,
  dayjsisWithinNext,
  getDay,
}

export enum IANAZone {
  Eastern = 'America/New_York',
  Central = 'America/Chicago',
  Mountain = 'America/Denver',
  Phoenix = 'America/Phoenix',
  Pacific = 'America/Los_Angeles',
  UTC = 'UTC',
}

export const startOfDayInTimezone = (date: Date | string | dayjs.Dayjs, zone: IANAZone) => {
  return dayjs(date).tz(zone, true).startOf('day')
}

dayjs.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: '< 1 minute',
    m: '1 minute',
    mm: '%d minutes',
    h: '1 hour',
    hh: '%d hours',
    d: '1 day',
    dd: '%d days',
    M: '1 month',
    MM: '%d months',
    y: '1 year',
    yy: '%d years',
  },
})
