// eslint-disable-next-line no-restricted-imports
import {
  Button as MantineButton,
  ButtonProps as MantineButtonProps,
  px,
  useMantineTheme,
} from '@mantine/core'
import { PolymorphicComponentProps } from '@mantine/utils'
import React, { PropsWithRef } from 'react'
import { getAttributes, getOutline } from '../inputs'
import { ButtonChildren, ButtonProps } from './buttons.helpers'

type TertiaryButtonProps<T extends ButtonChildren = string, C = 'div'> = PolymorphicComponentProps<
  C,
  ButtonProps<T> & {
    size?: 'sm' | 'md'
    backgroundColor?: string
  }
>

function TertiaryButtonInner<T extends ButtonChildren, C = 'div'>(
  {
    size = 'md',
    children,
    backgroundColor,
    fullWidth,
    disabled,
    ...props
  }: TertiaryButtonProps<T, C>,
  ref: React.ForwardedRef<HTMLButtonElement>,
) {
  const {
    other: { colors, fontFamilies, sizes },
    fontSizes,
  } = useMantineTheme()

  return (
    <MantineButton
      ref={ref}
      compact
      p='unset'
      h='fit-content'
      w={fullWidth ? '100%' : 'fit-content'}
      bg='unset'
      fz={fontSizes[size]}
      ff={fontFamilies.bold}
      lh='normal'
      disabled={Boolean(disabled || props.loading)}
      {...(props as MantineButtonProps)}
      {...getAttributes({ ['data-icon']: !children })}
      loaderProps={{ color: colors.text[0], size: sizes.icon.md }}
      styles={{
        rightIcon: { margin: 'unset', color: colors.actions[0] },
        leftIcon: { margin: 'unset', color: colors.actions[0] },
        label: {
          paddingLeft: sizes.gap.sm,
          paddingRight: sizes.gap.sm,
          display: children ? 'flex' : 'none',
        },
        root: {
          color: colors.text[0],
          textDecoration: 'underline',
          textDecorationColor: colors.actions[0],
          textDecorationThickness: sizes.border.md,
          // 3px offset
          textUnderlineOffset: px(sizes.padding.lg) / 4,
          border: 'none',
          ':focus': {
            outline: 'none',
            boxShadow: getOutline({
              color: colors.actions[0],
              gap: sizes.outline.sm,
              width: sizes.outline.sm,
              backgroundColor: backgroundColor ?? colors.background[0],
            }),
            textDecoration: 'none',
          },
          ':before': { display: 'none' },
          ':hover': {
            color: colors.text[0],
            textDecorationColor: colors.actions[1],
            backgroundColor: 'unset',
          },
          ':active': {
            color: colors.text[0],
            boxShadow: 'unset',
            transform: 'unset',
            backgroundColor: colors.background[2],
          },
          ':disabled': {
            backgroundColor: 'unset',
            color: colors.actions[3],
            textDecorationColor: colors.actions[3],
            // Try to override color of styled icons
            '.mantine-Button-icon svg': { color: colors.actions[3] },
            '.mantine-Button-icon circle': { stroke: colors.actions[3] },
          },
        },
      }}
    >
      {children}
    </MantineButton>
  )
}

// Type assertion to allow passing a generic into forwardRef
export const TertiaryButton = React.forwardRef<HTMLButtonElement, TertiaryButtonProps>(
  TertiaryButtonInner,
) as <T extends ButtonChildren, C = 'div'>(
  props: PropsWithRef<TertiaryButtonProps<T, C>>,
) => ReturnType<typeof TertiaryButtonInner>
