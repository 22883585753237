export const emojiMap = {
  'index finger pointing up': '☝️',
  'party popper': '🎉',
  'waving hand': '👋',
  'thumbs up': '👍',
  'open lock': '🔓',
  envelope: '✉️',
  'life preserver': '🛟',
  'home with garden': '🏡',
} as const
