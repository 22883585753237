// eslint-disable-next-line no-restricted-imports
import { CSSObject, MantineSize, px, useMantineTheme } from '@mantine/core'
import { useMemo } from 'react'
import { getOutlineFocused, getOutlinePressed } from '.'

export const useInlineInputGroupThemeStyles = () => {
  const {
    fontSizes,
    other: { sizes, fontFamilies, colors },
  } = useMantineTheme()

  return useMemo(() => {
    return {
      styles: {
        label: {
          fontSize: fontSizes.md,
          marginBottom: sizes.gap.md,
          display: 'flex',
          flex: 1,
        } as CSSObject,
        root: {
          '&[data-warning]': {
            '& input': { borderColor: colors.warning[0] },
            '.mantine-InputWrapper-error': {
              color: colors.warning[0],
            },
          },
          '&[data-success]': {
            '& input': { borderColor: colors.success[0] },
            '.mantine-InputWrapper-error': {
              color: colors.success[0],
            },
          },
          '&[data-error]': {
            '& input': { borderColor: colors.error[0] },
            '.mantine-InputWrapper-error': {
              color: colors.error[0],
            },
          },
        } as CSSObject,
        error: {
          lineHeight: 'normal',
          fontFamily: fontFamilies.bold,
          fontSize: fontSizes.sm,
          marginTop: sizes.gap.md,
          color: colors.text[1],
        } as CSSObject,
      },
    }
  }, [
    colors.error,
    colors.success,
    colors.text,
    colors.warning,
    fontFamilies.bold,
    fontSizes.md,
    fontSizes.sm,
    sizes.gap.md,
  ])
}

export const useInlineInputThemeStyles = ({
  scale,
  iconless,
  description,
  iconRadius,
  inputRadius = 'sm',
  fz,
}: {
  scale: number
  iconless: boolean
  description: boolean
  iconRadius: MantineSize
  inputRadius: MantineSize | undefined
  fz: MantineSize
}) => {
  const theme = useMantineTheme()
  return useMemo(() => {
    const {
      radius,
      fontSizes,
      other: { colors, themeName, sizes, fontFamilies },
    } = theme

    let iconColor: string
    let selectedFontColor: string
    let hoverFillColor: string
    let labelColor: string
    let backgroundColor: string
    let minHeight: number

    if (themeName === 'daybreak' || themeName === 'equinox') {
      iconColor = colors.text[0]
      selectedFontColor = colors.text[0]
      hoverFillColor = colors.background[3]
      labelColor = colors.text[0]
      backgroundColor = colors.background[2]
      minHeight = px(sizes.input.md)
    } else {
      // Default to twilight
      iconColor = colors.text[2]
      selectedFontColor = colors.text[3]
      hoverFillColor = colors.background[2]
      labelColor = colors.actions[0]
      backgroundColor = colors.background[1]
      minHeight = px(sizes.input.md)
    }

    // Size of the circle inside the SVG
    const svgSize = px(sizes.icon.md) * scale
    // Size of the icon, the substraction ensures proper size
    const iconSize = px(sizes.icon.md) - px(sizes.icon.md) / 4
    // Additional padding for the circle to get the correct icon size, the border counts as inner size
    const svgPadding = Math.round((iconSize - svgSize - px(sizes.border.md)) / 2)
    // Top offset to center the icon with the first line of text
    const iconTopOffset = minHeight / 2 - px(sizes.icon.md)
    // The empty space inside the icon
    const iconOuterOffset = (px(sizes.icon.md) - iconSize) / 2
    // Center the input icon with the first line of text
    const svgMarginTop = px(sizes.padding.md) + iconOuterOffset + iconTopOffset
    const svgMarginLeft = px(sizes.gap.md) + iconOuterOffset

    return {
      iconColor,
      theme,
      styles: {
        root: {
          '&[data-checked] label': iconless && {
            color: selectedFontColor,
            fontFamily: iconless ? fontFamilies.bold : fontFamilies.regular,
          },
        },
        body: {
          // CSS value of `100%` is better compatible than `fr`
          display: 'grid',
          gridTemplateRows: '100%',
          gridTemplateColumns: '100%',
          height: '100%',
          width: '100%',
        } as CSSObject,
        inner: { gridRowStart: 1, gridColumnStart: 1, width: '100%', height: '100%' } as CSSObject,
        label: {
          padding: 'unset',
          lineHeight: 'normal',
          fontSize: fontSizes[fz],
          fontFamily: description ? fontFamilies.bold : fontFamilies.regular,
          color: description ? labelColor : colors.text[0],
          marginLeft: iconless ? sizes.gap.md : `calc(${sizes.gap.md} * 2 + ${sizes.icon.md})`,
          marginRight: sizes.gap.md,
          '&[data-disabled]': {
            color: colors.actions[2],
            '+ div': { color: colors.actions[2] },
          },
        } as CSSObject,
        description: {
          margin: 'unset',
          padding: 'unset',
          lineHeight: 'normal',
          fontSize: fontSizes.md,
          color: colors.text[0],
          marginLeft: sizes.padding.md,
          marginRight: sizes.gap.md,
        } as CSSObject,
        icon: {
          zIndex: 0,
          top: svgMarginTop,
          left: svgMarginLeft,
          margin: 'unset',
          transform: 'unset',
          height: svgSize,
          width: svgSize,
          padding: svgPadding,
          borderRadius: radius[iconRadius],
          borderWidth: sizes.border.md,
          borderColor: colors.actions[0],
          borderStyle: 'solid',
          opacity: 'unset',
          display: iconless ? 'none' : 'auto',
          '> path': { fill: iconColor, display: 'none' },
        } as CSSObject,
        labelWrapper: {
          pointerEvents: 'none',
          zIndex: 0,
          gridRowStart: 1,
          gridColumnStart: 1,
          justifyContent: 'center',
          marginTop: sizes.padding.md,
          marginBottom: sizes.padding.md,
          padding: 'unset',
          gap: sizes.gap.md,
        } as CSSObject,
      },
      inputStyle: {
        width: '100%',
        height: '100%',
        boxSizing: 'border-box',
        backgroundColor,
        borderColor: backgroundColor,
        borderWidth: sizes.border.md,
        borderRadius: radius[inputRadius],
        ':focus': {
          boxShadow: getOutlineFocused({ colors, sizes }),
          borderColor: colors.actions[0],
        },
        ':active': {
          boxShadow: getOutlinePressed({ colors, sizes }),
          borderColor: colors.actions[0],
          outline: 'unset',
        },
        ':disabled': {
          cursor: 'not-allowed',
          boxShadow: 'unset',
          borderColor: backgroundColor,
          backgroundColor,
          '+ svg': { borderColor: colors.actions[2] },
        },
        ':checked': {
          backgroundColor: iconless ? colors.actions[0] : backgroundColor,
          borderColor: colors.actions[0],
          '+ svg': { backgroundColor: colors.actions[0], '> path': { display: 'unset' } },
          ':disabled': {
            backgroundColor: iconless ? colors.actions[3] : backgroundColor,
            borderColor: iconless ? colors.actions[3] : colors.actions[2],
            '+ svg': { backgroundColor: colors.actions[2] },
          },
        },
        ':hover:not(:disabled)': iconless && {
          ':not(:checked)': { borderColor: hoverFillColor, backgroundColor: hoverFillColor },
          ':checked': {
            borderColor: colors.actions[1],
            backgroundColor: colors.actions[1],
          },
        },
      } as CSSObject,
    }
  }, [description, fz, iconRadius, iconless, inputRadius, scale, theme])
}
