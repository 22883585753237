/**
 * asyncRetry
 */

type Bail = (error: Error | string) => void
type Wrapper<T> = (bail: Bail) => Promise<T>

export const asyncRetry = async <T>(wrapper: Wrapper<T>, retries: number): Promise<T> => {
  const bail: Bail = error => {
    throw error
  }

  try {
    return await wrapper(bail)
  } catch (error) {
    if (retries) {
      retries = retries - 1
      return asyncRetry(wrapper, retries)
    }

    throw error
  }
}

/**
 * asyncFilter
 */

export const asyncFilter = async <T>(values: T[], fn: (arg: T) => Promise<boolean>) => {
  const results = await Promise.all(values.map(fn))
  return values.filter((_, i) => results[i])
}

/**
 * asyncFlowFilter
 */

export const asyncFlowFilter = <T>(...fns: ((arg: T) => boolean | Promise<boolean>)[]) => {
  return async (arg: T) => {
    for (const fn of fns) {
      const result = await fn(arg)

      // If any function returns false, return false
      if (!result) {
        return false
      }
    }

    return true
  }
}

/**
 * asyncFlow
 */

export const asyncFlow = <T>(...fns: ((arg: T) => T | Promise<T>)[]) => {
  return async (arg: T) => {
    let acc = arg

    for (const fn of fns) {
      acc = await fn(acc)
    }

    return acc
  }
}
