type EnrollmentPanelRow = {
  enrollmentCoordinatorId: string | null
  enrollmentCoordinatorName: string | null
  patientId: string | null
  patientName: string | null
  patientPhoneNumber: string | null
  patientStatus: string | null
  patientState: string | null
  patientInsurancePlanName: string | null
  patientBillingOption: string | null
  patientLastWelcomeCallDate: string | null
  patientContactCount: number | null
  patientLastWinbackCallDate: string | null
  priority: 5 | 4 | 3 | 2 | 1 | null
  claimed?: boolean
}

type ReferralLeadRow = {
  patient_id: string | null
  patientName: string | null
  patient_status: string | null
  current_patient_state: string | null
  insurance_provider: string | null
  current_billing_option: string | null
  contacts: number | null
  last_winback_Call: string | null
  priority: 5 | 4 | 3 | 2 | 1 | null
  referral_source: string | null
  enrollment_coordinator_employee_id: string | null
  claimed?: boolean
}

type RudderstackWwwSessionsRow = {
  session_id: number
  pageviews_count: number
  pages_viewed: string
  min_time: string
  max_time: string
  session_time_mins: number
  start_url: string
  anonymous_id: string
  landing_page: string
  gclid: string | null
  rdt_cid: string | null
  tt_clid: string | null
  msclkid: string | null
  utm_source: string
  utm_campaign: string
  utm_medium: string
  user_agent: string
}

type UnpaidPastDueInvoicesRow = {
  patient_id: string
  invoice_id: string
}

export type BigQueryRowTypes = {
  referralLeads: ReferralLeadRow
  enrollmentPanel: EnrollmentPanelRow
  unpaidPastDueInvoices: UnpaidPastDueInvoicesRow
  rudderstackWwwSessions: RudderstackWwwSessionsRow
}

export type BigQueryKey = keyof BigQueryRowTypes

export const BIGQUERY_QUERY_STRINGS: Record<BigQueryKey, string> = {
  referralLeads: `
  WITH
  referrals AS (
  SELECT
    referral_patient_id,
    referral_created_at_utc,
    referral_source
  FROM
   ophelia-data-marts.ophelia_core.friend_referrals
  UNION ALL
  SELECT
    patient_id AS referral_patient_id,
    referral_created_at_utc,
    'provider' AS referral_source
  FROM
    ophelia-data-marts.ophelia_core.provider_referrals ),
  count_issues AS (
  SELECT
    patient_id,
    IFNULL(COUNT(*), 0) AS calls
  FROM
    ophelia-data-marts.ophelia_core.emr_issues
  WHERE
    issue_type = 'winback_call'
  GROUP BY
    patient_id ),
  last_issue AS (
  SELECT
    patient_id,
    CAST(created_at_utc AS date) AS last_winback_call,
    row_number () OVER (PARTITION BY patient_id ORDER BY created_at_utc DESC)
  FROM
    ophelia-data-marts.ophelia_core.emr_issues
  WHERE
    issue_type = 'winback_call' QUALIFY row_number () OVER (PARTITION BY patient_id ORDER BY created_at_utc DESC) = 1 ),
  winbacks AS (
  SELECT
    c.patient_id,
    c.calls,
    l.last_winback_call
  FROM
    count_issues c
  JOIN
    last_issue l
  ON
    c.patient_id = l.patient_id )
SELECT
 i.enrollment_coordinator,
 i.enrollment_coordinator_employee_id,
  p.patient_id,
  CONCAT(p.last_name, ', ', p.first_name) AS patientName,
  p.current_patient_state,
  k.first_lead_date_et,
  CAST(r.referral_created_at_utc AS date) AS Referral_Created_Date,
  r.referral_source,
  p.patient_status,
  p.current_billing_option,
  p.insurance_provider,
  p.insurance_plan_rollup_name,
  w.calls AS contacts,
  w.last_winback_Call
FROM
  ophelia-data-marts.ophelia_core.patients p
  join ophelia-data-marts.ophelia_core_intermediate.int_patients i
  on p.patient_id = i.patient_id
JOIN
  referrals r
ON
  p.patient_id = r.referral_patient_id
JOIN
  ophelia-data-marts.ophelia_core.patient_key_dates k
ON
  p.patient_id = k.patient_id
LEFT JOIN
  winbacks w
ON
  p.patient_id = w.patient_id
WHERE
  p.patient_status = 'lead'
  AND p.patient_id NOT IN (
  SELECT
    patient_id
  FROM
    ophelia-data-marts.ophelia_core.appointments
  WHERE
    appointment_type = 'Free Consultation Call'
    AND NOT is_canceled
    AND CAST(appointment_at_utc AS date) > current_date)
  AND p.patient_id NOT IN ((
    SELECT
      patient_id
    FROM
      ophelia-data-marts.ophelia_core.appointments
    WHERE
      appointment_type = 'Initial Visit' ))
ORDER BY
  k.first_lead_date_et DESC;
  `,
  // https://console.cloud.google.com/bigquery?sq=92390295462:41e02d4bb21f4c46b14a1708ac0cdccc
  enrollmentPanel: `
  WITH
  Candidates AS (
  SELECT
    p.most_recent_enrollment_coordinator_employee_id AS enrollmentCoordinatorId,
    p.most_recent_enrollment_coordinator AS enrollmentCoordinatorName,
    CONCAT(p.last_name, ', ', p.first_name) AS patientName,
    p.patient_id AS patientId,
    p.phone_number AS patientPhoneNumber,
    p.patient_status AS patientStatus,
    p.current_patient_state AS patientState,
    p.current_billing_option AS patientBillingOption,
    p.insurance_plan_rollup_name AS patientInsurancePlanName,
    CAST(a.appointment_at_et AS date) AS patientLastWelcomeCallDate,
    CAST(k.most_recent_candidacy_date_et AS date) AS patientMostRecentCandidacyDate,
    row_number () OVER (PARTITION BY p.patient_id ORDER BY a.appointment_at_et DESC ) AS wc_rank
  FROM
    ophelia-data-marts.ophelia_core.patients p
  JOIN
    ophelia-data-marts.ophelia_core.patient_key_dates k
  ON
    p.patient_id = k.patient_id
  JOIN
    ophelia-data-marts.ophelia_core.appointments a
  ON
    p.patient_id = a.patient_id
  WHERE
    p.patient_status IN ('candidate',
      'lead')
    AND p.current_patient_state IN ('PA',
      'NY')
    AND k.most_recent_candidacy_date_et IS NOT NULL
    AND a.appointment_type = 'Free Consultation Call'
    AND NOT a.is_canceled
    AND CAST(a.appointment_at_et AS date) < current_date
    AND p.most_recent_enrollment_coordinator IS NOT NULL
    AND p.patient_id NOT IN (
    SELECT
      patient_id
    FROM
      ophelia-data-marts.ophelia_core.appointments b
    WHERE
      CAST(b.appointment_at_et AS date) >= current_date
      AND NOT b.is_canceled ) QUALIFY wc_rank = 1 ),
  intake AS (
  SELECT
    a.appointment_id,
    a.patient_id,
    a.canceled_at_utc as most_recent_canceled_intake,
    CAST(a.appointment_at_et AS date)AS appointment_at_et,
    CASE
      WHEN a.is_no_show THEN 'Noshowed intake'
      WHEN a.expiration_reason = 'autocanceled' THEN 'Did not confirm intake'
      WHEN a.is_expired THEN 'Did not complete tasks'
    ELSE
    'Asked to be canceled'
  END
    AS intake_outcome
  FROM
    ophelia-data-marts.ophelia_core.appointments a
  WHERE
    appointment_type = 'Initial Visit'
    AND a.is_canceled QUALIFY 1 = row_number () OVER (PARTITION BY a.patient_id ORDER BY a.appointment_at_et DESC ) ),
  jotform AS (
  SELECT
    userId AS patient_id,
    CAST(timestamp AS date) AS created_at_utc
  FROM
    ophelia-data-lake.reverse_etl.winback_backfill_2023_10_13 ),
  emr_winback_issues AS (
  SELECT
    patient_id,
    CAST(created_at_utc AS date) AS created_At_utc
  FROM
    ophelia-data-marts.ophelia_core.emr_issues
  WHERE
    issue_type = 'winback_call' ),
  total_issues AS (
  SELECT
    *
  FROM
    emr_winback_issues
  UNION ALL
  SELECT
    *
  FROM
    jotform ),
  total_issues_2 AS (
  SELECT
    t.*,
    i.most_recent_canceled_intake
  FROM
    total_issues t
  LEFT JOIN
    intake i
  ON
    t.patient_id = i.patient_id),
  count_issues AS (
  SELECT
    patient_id,
    IFNULL(COUNT(*), 0) AS calls
  FROM
    total_issues_2
    where created_at_utc >= ifnull(cast(total_issues_2.most_recent_canceled_intake as date), cast ('2022-01-01' as date))
  GROUP BY
    patient_id ),
  last_issue AS (
  SELECT
    patient_id,
    CAST(created_at_utc AS date) AS last_winback_call,
    row_number () OVER (PARTITION BY patient_id ORDER BY created_at_utc DESC)
  FROM
    total_issues_2
    where created_at_utc >= ifnull(cast(total_issues_2.most_recent_canceled_intake as date), cast ('2022-01-01' as date))
    QUALIFY row_number () OVER (PARTITION BY patient_id ORDER BY created_at_utc DESC) = 1 ),
  winbacks AS (
  SELECT
    c.patient_id,
    c.calls,
    l.last_winback_call
  FROM
    count_issues c
  JOIN
    last_issue l
  ON
    c.patient_id = l.patient_id ),
  base AS (
  SELECT
    c.enrollmentCoordinatorId,
    c.enrollmentCoordinatorName,
    c.patientName,
    c.patientId,
    c.patientState,
    c.patientStatus,
    c.patientPhoneNumber,
    c.patientBillingOption,
    c.patientInsurancePlanName,
    c.patientLastWelcomeCallDate,
    i.appointment_at_et AS most_recent_intake_booked_for,
    coalesce(i.intake_outcome, 'Never booked intake') as IntakeOutcome,
    i.appointment_id,
    i.most_recent_canceled_intake,
    w.calls AS contacts,
    w.last_winback_call AS Last_Winback_Call
  FROM
    candidates c
  LEFT JOIN
    winbacks w
  ON
    c.patientId = w.patient_id
  LEFT JOIN
    intake i
  ON
    c.patientId = i.patient_id )
SELECT
  base.enrollmentCoordinatorId,
  base.enrollmentCoordinatorName,
  base.patientId,
  base.patientName,
  base.patientState,
  base.patientPhoneNumber,
  base.patientStatus,
  base.patientInsurancePlanName,
  base.patientBillingOption AS patientBillingOption,
  base.patientLastWelcomeCallDate,
  case
  when base.IntakeOutcome = 'Noshowed intake' or base.IntakeOutcome = 'Did not confirm intake'  then 5
  when base.IntakeOutcome = 'Did not complete tasks' then 4
  when base.IntakeOutcome = 'Never booked intake' then 3
  when base.IntakeOutcome = 'Asked to be canceled' then 2
  else 1
  end as priority,
  base.appointment_id,
  base.most_recent_canceled_intake,
  base.most_recent_intake_booked_for,
  contacts AS patientContactCount,
  last_winback_call AS patientLastWinbackCallDate
FROM
  base
  order by base.patientLastWelcomeCallDate desc;
  `,
  unpaidPastDueInvoices: `WITH invoices AS (
    SELECT
        CASE
            WHEN due_date IS NOT NULL THEN DATETIME(due_date)
            WHEN type = 'copay' THEN DATE_ADD(
                DATETIME(TIMESTAMP_SECONDS(
                    CAST(json_extract(raw_external_data, '$.created') AS INT)
                )),
                INTERVAL 10 DAY
            )
            ELSE DATETIME(TIMESTAMP_SECONDS(
                CAST(json_extract(raw_external_data, '$.created') AS INT)
            ))
        END AS actual_due_date,
        a.patient_id,
        a.type,
        json_extract(raw_external_data, '$.amount_remaining') AS amount_remaining,
        _id AS invoice_id
    FROM
        \`ophelia-data-lake.firestore.invoices\` a
    WHERE
        json_extract_scalar(raw_external_data, '$.status') NOT IN ('void', 'deleted')
        AND CAST(json_query(raw_external_data, '$.amount_remaining') AS INT) > 0
),
current_patients AS (
    SELECT
        _ID AS patient_id,
        json_extract_scalar(statuses, '$.patient') AS patient_status,
        json_extract_scalar(discharge, '$.reason') AS discharge_reason
    FROM
        \`ophelia-data-lake.firestore.patients\`
    WHERE
        json_extract_scalar(statuses, '$.patient') != "discharged"
)
SELECT
    invoices.patient_id,
    invoice_id,
    actual_due_date,
    type
FROM
    invoices
LEFT OUTER JOIN
    current_patients
    ON invoices.patient_id = current_patients.patient_id
WHERE (
    current_patients.patient_id IS NOT NULL
    AND (
       (type = 'copay' AND actual_due_date < DATE_SUB(CURRENT_DATETIME(), INTERVAL 90 DAY))
        OR
       (type = 'subscription' AND actual_due_date < DATE_SUB(CURRENT_DATETIME(), INTERVAL 30 DAY))
    )
    AND (discharge_reason != "payment" OR discharge_reason IS NULL)
)`,
  rudderstackWwwSessions: `
    CREATE TEMP FUNCTION get_url_param(url STRING, param STRING)
    RETURNS STRING
    AS (
        REGEXP_EXTRACT(url, CONCAT(r'[&?]', param, r'=([^&]*)'))
    );


  WITH sessions AS (
    SELECT
      context_session_id as session_id,
      COUNT(DISTINCT p.context_page_path) AS pageviews_count,
      STRING_AGG(DISTINCT p.name, ',') AS pages_viewed,
      MIN(p.timestamp) AS min_time,
      MAX(p.timestamp) AS max_time,
      TIMESTAMP_DIFF(MAX(p.timestamp), MIN(p.timestamp), minute) AS session_time_mins,
      MAX(context_traits_opt_out) as opt_out_value,
      MAX(anonymous_id) as anonymous_id,
    FROM \`ophelia-data-lake.rudderstack.pages\` p
    WHERE p.timestamp > TIMESTAMP_SUB(CURRENT_TIMESTAMP(), INTERVAL 1 DAY)
    AND (context_traits_opt_out is NULL or context_traits_opt_out = false)
    AND context_traits_visited_my_ophelia IS NULL
    AND user_id IS NULL
    AND url LIKE '%https://ophelia.com%'
    GROUP BY context_session_id
    ORDER BY session_time_mins DESC
  ),
  sorted_events as (
    SELECT
    *,
    ROW_NUMBER() OVER (PARTITION BY context_session_id ORDER BY timestamp asc) AS event_num
    FROM \`ophelia-data-lake.rudderstack.pages\`
    WHERE name is not null
  ),

  session_start as (
    SELECT
      url as start_url,
      context_session_id as session_id,
      name as landing_page,
      get_url_param(url, 'gclid') AS gclid,
      get_url_param(url, 'rdt_cid') AS rdt_cid,
      get_url_param(url, 'ttclid') as ttclid,
      get_url_param(url, 'msclkid') as msclkid,
      get_url_param(url, 'utm_source') AS utm_source,
      get_url_param(url, 'utm_campaign') AS utm_campaign,
      get_url_param(url, 'utm_medium') as utm_medium,
      context_user_agent as user_agent
    FROM sorted_events
    WHERE event_num = 1
  )

  SELECT *
  FROM sessions
  INNER JOIN session_start ON session_start.session_id = sessions.session_id where utm_source IS NOT NULL;`,
} as const
