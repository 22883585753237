import { isEmail, isNotEmpty, useForm } from '@mantine/form'
import {
  Banner,
  Box,
  ExternalLinkText,
  Group,
  PhoneIcon,
  PrimaryButton,
  ResponsiveBox,
  SecondaryButton,
  Stack,
  Sx,
  Text,
  TextInput,
  TitleTwo,
  useLifecycle,
} from '@shared/components'
import { useState } from 'react'
import { useMutation } from 'react-query'
import { Footer } from './Footer'
import { OpheliaCommunity } from './OpheliaCommunity'
import { analytics } from './analytics'
import { bridgeApi } from './api'
import { useCommunityDims } from './useCommunityDims'

type Form = {
  name: string
  email: string
}

export const Join = () => {
  const { isMobile } = useCommunityDims()
  const [showSuccess, setShowSuccess] = useState(false)

  const form = useForm<Form>({
    validate: {
      name: isNotEmpty('Required'),
      email: isEmail('Must be a valid email'),
    },
  })

  const waitingListMutation = useMutation(bridgeApi.getMutation('POST /waiting-list'), {
    onSuccess: () => {
      setShowSuccess(true)
      window.scrollTo(0, 0)
    },
  })
  const onSubmit = async () => {
    if (form.validate().hasErrors) {
      return
    }

    await waitingListMutation.mutateAsync({ data: form.values })
  }

  const disabled = waitingListMutation.isLoading || showSuccess

  // Push the footer to the bottom of the page since the rest of the content doesn't fill
  const pushFooterStyles: Sx = {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    justifyContent: 'space-between',
  }

  useLifecycle({
    onMount: () => {
      analytics.page('Join')
    },
  })

  return (
    <Box sx={isMobile ? {} : pushFooterStyles}>
      <Box p='lg'>
        <OpheliaCommunity />
      </Box>
      {showSuccess && (
        <Banner
          type='success'
          label='We’ve got your email — we’ll let you know when your invitation is ready'
        />
      )}
      <Stack px='lg' spacing='lg'>
        <TitleTwo>Be one of the first to access our new Ophelia Community</TitleTwo>
        <Text>
          We’re building a first-of-its-kind community for people whose lives are impacted by
          opioids. Ask tough questions, get real answers, and feel seen without judgment. Join the
          waitlist now by filling out the form below.
        </Text>
        <Text>
          PS: Know someone who would benefit from access to this community? Share + invite them to
          join the waitlist, too!
        </Text>
        <TextInput
          label='First name or nickname'
          placeholder="Share whatever you're comfortable with"
          disabled={disabled}
          {...form.getInputProps('name')}
        />
        <TextInput
          label='Email address'
          placeholder='Email address'
          disabled={disabled}
          {...form.getInputProps('email')}
        />
        <ResponsiveBox
          mobile={
            <Stack>
              <PrimaryButton fullWidth onClick={onSubmit} disabled={disabled}>
                Submit
              </PrimaryButton>
              <ExternalLinkText href='https://my.ophelia.com/welcome?utm_source=community&utm_medium=hub&utm_campaign=join'>
                <SecondaryButton fullWidth leftIcon={<PhoneIcon />}>
                  Schedule call
                </SecondaryButton>
              </ExternalLinkText>
            </Stack>
          }
          desktop={
            <Group position='right'>
              <PrimaryButton onClick={onSubmit} disabled={disabled}>
                Submit
              </PrimaryButton>
            </Group>
          }
        />
      </Stack>
      <Footer hideActions={isMobile} />
    </Box>
  )
}
