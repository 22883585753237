// eslint-disable-next-line no-restricted-imports
import { Box } from '@mantine/core'
import React, { SVGProps } from 'react'
import { Bell, IconProps } from 'react-feather'
import { ConcentricCirclesIcon } from '../icons'

export const NotificationBell = (props: SVGProps<SVGSVGElement>) => {
  return (
    <Box sx={{ display: 'flex' }}>
      <Box
        sx={({ other: { sizes } }) => ({
          position: 'absolute',
          marginLeft: sizes.gap.md,
        })}
      >
        <ConcentricCirclesIcon size='sm' />
      </Box>
      <Bell {...(props as IconProps)} />
    </Box>
  )
}
