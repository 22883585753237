import { z } from 'zod'

import { stateAbbreviations } from './states'
import { emptyToUndefined, nonEmptyString } from './zodUtils'

export const ABF_CODE_TYPE = 'ABF' as const

export type EncounterPatientData = {
  gender?: string
  first_name?: string
  last_name?: string
  date_of_birth?: string
  external_id?: string
  address?: {
    address1: string
    address2?: string
    city?: string
    state?: string
    zip_code?: string
    zip_plus_four_code?: string
  }
}

export type InsuranceCard = {
  member_id?: string
  payer_name?: string
  payer_id?: string
  group_number?: string
  plan_name?: string
  plan_type?: keyof typeof PrimaryInsurancePlanType | null
  rx_bin?: string
  rx_pcn?: string
  image_url_front?: string
  image_url_back?: string
  useMedicaidInfo?: boolean
  medicaidInfo?: {
    member_id?: string
    payer_name?: string
    payer_id?: string
  }
}

export type EncounterSubscriber = {
  gender?: string
  first_name?: string
  last_name?: string
  date_of_birth?: string
  address?: {
    address1?: string
    address2?: string
    city?: string
    state?: string
    zip_code?: string
    zip_plus_four_code?: string
  } | null
  patient_relationship_to_subscriber_code?: string
  insurance_card?: InsuranceCard
}

export type BillingProvider = {
  organization_name?: string
  npi?: string
  tax_id?: string
  address?: {
    address1?: string
    address2?: string
    city?: string
    state?: string
    zip_code?: string
    zip_plus_four_code?: string
  }
}

/**
 * Not exhaustive. 'Wellness Visit' is deprecated (was just Dec2022-Jan2023)
 */
export const FEE_FOR_SERVICE_ENCOUNTER_APPOINTMENT_TYPES = [
  'Wellness Visit',
  'OUD Induction Visit',
  'OUD Maintenance Visit',
] as const

export const BUNDLE_ENCOUNTER_APPOINTMENT_TYPES = [
  'Collaborative Care Management',
  'Keystone First bundle',
  'Geisinger Medicaid bundle',
  'Empire Blue bundle',
  'Highmark Commercial bundle',
] as const

export const ENCOUNTER_APPOINTMENT_TYPES = [
  ...FEE_FOR_SERVICE_ENCOUNTER_APPOINTMENT_TYPES,
  ...BUNDLE_ENCOUNTER_APPOINTMENT_TYPES,
] as const

export type EncounterAppointmentType = (typeof ENCOUNTER_APPOINTMENT_TYPES)[number]

export type RenderingProvider = {
  npi?: number | null
  first_name?: string
  last_name?: string
  address?: {
    address1?: string
    address2?: string
    city?: string
    state?: string
    zip_code?: string
    zip_plus_four_code?: string
  }
}

export type ServiceFacility = {
  organization_name?: string
  address?: {
    address1?: string
    address2?: string
    city?: string
    state?: string
    zip_code?: string
    zip_plus_four_code?: string
  }
}

export const PrimaryInsurancePlanType = {
  '12': 'Preferred Provider Organization (PPO)',
  '13': 'Point of Service (POS)',
  '14': 'Exclusive Provider Organization (EPO)',
  CH: 'CHAMPUS',
  CI: 'Commercial Insurance Co.',
  FI: 'Federal Employees Program',
  HM: 'Health Maintenance Organization (HMO)',
  MB: 'Medicare Part B',
  MC: 'Medicaid',
  VA: 'Veterans Affairs Plan',
  WC: "Workers' Compensation Health Claim",
}

export const RELATIONSHIP_TO_SUBSCRIBER_CODE_OPTIONS = [
  {
    value: '01',
    label: 'Spouse',
  },
  {
    value: '04',
    label: 'Grandparent',
  },
  {
    value: '05',
    label: 'Grandchild',
  },
  {
    value: '07',
    label: 'Niece or nephew',
  },
  {
    value: '10',
    label: 'Foster child',
  },
  {
    value: '15',
    label: 'Ward of the court',
  },
  {
    value: '17',
    label: 'Stepchild',
  },
  {
    value: '18',
    label: 'Self',
  },
  {
    value: '19',
    label: 'Child',
  },
  {
    value: '20',
    label: 'Employee',
  },
  {
    value: '21',
    label: 'Unknown',
  },
  {
    value: '22',
    label: 'Handicapped dependent',
  },
  {
    value: '23',
    label: 'Sponsored dependent',
  },
  {
    value: '24',
    label: 'Dependent of minor dependent',
  },
  {
    value: '29',
    label: 'Significant other',
  },
  {
    value: '32',
    label: 'Mother',
  },
  {
    value: '33',
    label: 'Father',
  },
  {
    value: '36',
    label: 'Emancipated minor',
  },
  {
    value: '39',
    label: 'Organ donor',
  },
  {
    value: '40',
    label: 'Cadaver donor',
  },
  {
    value: '41',
    label: 'Injured plaintiff',
  },
  {
    value: '43',
    label: 'Natural child, insured does not have financial responsibility',
  },
  {
    value: '53',
    label: 'Life partner',
  },
  {
    value: 'G8',
    label: 'Other relationship',
  },
] as const

const relationshipToSubscriberCodes = RELATIONSHIP_TO_SUBSCRIBER_CODE_OPTIONS.map(
  option => option.value,
)

const addressSchema = z.object(
  {
    address1: nonEmptyString,
    address2: z.string().optional().transform(emptyToUndefined),
    city: nonEmptyString,
    state: z.enum([stateAbbreviations[0]!, ...stateAbbreviations.slice(1)]),
    zip_code: z.string().length(5),
    zip_plus_four_code: z
      .union([z.string().length(0), z.string().length(4)])
      .optional()
      .transform(emptyToUndefined),
  },
  {
    errorMap: e =>
      e.code === 'invalid_type' ? { message: 'Field is required' } : { message: 'Invalid' },
  },
)

export const SubscriberValidator = z.object({
  first_name: nonEmptyString,
  last_name: nonEmptyString,
  gender: z.enum(['male', 'female', 'other', 'not_given', 'unknown']),
  date_of_birth: z.string().regex(/^\d{4}-\d{2}-\d{2}$/),
  address: addressSchema,
  patient_relationship_to_subscriber_code: z.enum([
    relationshipToSubscriberCodes[0]!,
    ...relationshipToSubscriberCodes.slice(1),
  ]),
  insurance_card: z.object({
    member_id: nonEmptyString,
    payer_name: nonEmptyString,
    payer_id: nonEmptyString,
    group_number: z.string().optional().transform(emptyToUndefined),
    plan_name: z.string().optional().transform(emptyToUndefined),
    plan_type: z
      .enum([
        Object.keys(PrimaryInsurancePlanType)[0]!,
        ...Object.keys(PrimaryInsurancePlanType).slice(1),
      ])
      .optional(),
    rx_bin: z.string().optional().transform(emptyToUndefined),
    rx_pcn: z.string().optional().transform(emptyToUndefined),
    image_url_front: z.string().optional().transform(emptyToUndefined),
    image_url_back: z.string().optional().transform(emptyToUndefined),
  }),
})

export const RenderingProviderValidator = z.object({
  npi: z.number(),
  first_name: nonEmptyString,
  last_name: nonEmptyString,
  address: addressSchema.partial(),
})

export const BillingProviderValidator = z.object({
  npi: nonEmptyString,
  tax_id: nonEmptyString,
  organization_name: z.string().optional().transform(emptyToUndefined),
  address: addressSchema,
})

export const ServiceLineValidator = z.object({
  procedure_code: nonEmptyString,
  quantity: nonEmptyString,
  units: nonEmptyString,
  charge_amount_cents: z.number(),
  diagnosis_pointers: z.array(z.number()),
  modifiers: z.array(z.string()).optional(),
})

export const ServiceFacilityValidator = z.object({
  organization_name: nonEmptyString,
  address: addressSchema,
})

export const CandidPatientValidator = z.object({
  gender: z.enum(['male', 'female', 'other', 'not_given', 'unknown']),
  first_name: nonEmptyString,
  last_name: nonEmptyString,
  date_of_birth: z.string().regex(/^\d{4}-\d{2}-\d{2}$/),
  external_id: nonEmptyString,
  address: addressSchema,
})

export const DiagnosisValidator = z.object({
  code: nonEmptyString,
  name: nonEmptyString,
  code_type: z.enum([ABF_CODE_TYPE]),
})

export const CandidEncounterValidator = z.object({
  date_of_service: z.string().regex(/^\d{4}-\d{2}-\d{2}$/),
  end_date_of_service: z
    .union([z.string().regex(/^\d{4}-\d{2}-\d{2}$/), z.string().length(0)])
    .optional()
    .transform(emptyToUndefined),
  appointment_type: z.string().optional().transform(emptyToUndefined),
  patient_authorized_release: z.boolean(),
  benefits_assigned_to_provider: z.boolean(),
  provider_accepts_assignment: z.boolean(),
  place_of_service_code: nonEmptyString,
  external_id: nonEmptyString,
  billing_provider: BillingProviderValidator,
  rendering_provider: RenderingProviderValidator,
  service_facility: ServiceFacilityValidator,
  patient: CandidPatientValidator,
  subscriber_primary: SubscriberValidator,
  subscriber_secondary: SubscriberValidator.optional(),
  diagnoses: z.array(DiagnosisValidator),
  service_lines: z.array(ServiceLineValidator),
})

export type SubmitEncounterRequest = z.infer<typeof CandidEncounterValidator>
