import { LevelOfCareStatus, Patient, PatientModel, StateName } from '.'

export const hasStatus = (
  patient?: Patient | PatientModel,
  ...statuses: Patient['statuses']['patient'][]
) => {
  return statuses.some(status => status === patient?.statuses?.patient)
}

export const hasHomeState = (patient?: Patient | PatientModel, ...states: StateName[]) => {
  return states.some(state => state === patient?.homeData?.state)
}

export const hasLevelOfCare = (
  patient?: Patient | PatientModel | null,
  ...levelsOfCare: LevelOfCareStatus[]
) => {
  return levelsOfCare.some(levelOfCare => levelOfCare === patient?.statuses?.levelOfCare)
}

export const levelOfCareToDaysBetweenVisit: Record<LevelOfCareStatus, number> = {
  weekly: 7,
  biweekly: 14,
  monthly: 28,
  'n/a': 120,
}
