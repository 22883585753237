// eslint-disable-next-line no-restricted-imports
import { Box, Overlay, Stack, px, useMantineTheme } from '@mantine/core'
import { dayjs } from '@shared/utils'
import { Property } from 'csstype'
import React, { ReactNode, forwardRef, useCallback, useLayoutEffect, useRef, useState } from 'react'
import { InputError, InputWrapper } from '../InputWrapper'
import { ScrollAreaAutosize } from '../ScrollArea'
import { Skeleton } from '../Skeleton'
import { Radio, RadioGroup } from '../radio'

type DailyCalendarRadioGroupProps = {
  data: string[]
  value: string
  onChange: (value: string) => void
  loading?: boolean
  tz?: string | undefined | null
  // Size of the scrollable area scaled by the height of the items
  size?: number | 'full'
  disabled?: boolean
  ['test-id']?: string
  empty: ReactNode
} & InputError

export const DailyCalendarRadioGroup = forwardRef<HTMLDivElement, DailyCalendarRadioGroupProps>(
  (
    {
      data,
      value,
      onChange,
      warning,
      success,
      error,
      loading,
      tz,
      size = 'full',
      disabled,
      explanation,
      empty,
      ...props
    },
    ref,
  ) => {
    const {
      spacing,
      other: { colors, sizes },
    } = useMantineTheme()

    // eslint-disable-next-line no-magic-numbers
    const [topTransparency, setTopTransparency] = useState<number>(100)
    // eslint-disable-next-line no-magic-numbers
    const [bottomTransparency, setBottomTransparency] = useState<number>(100)
    const viewport = useRef<HTMLDivElement>(null)
    const itemHeight = px(sizes.input.md)

    /*
     * The range (in pixels) from which fading will start to happen once the scroll passes this threshold
     * ie, if you scroll more than fadeRange pixels from the top, fade will begin
     */
    const fadeRange = px(spacing.xl)

    const onScrollPositionChange = useCallback(
      (position: { x: number; y: number }) => {
        if (viewport.current) {
          const overflow = viewport.current.scrollHeight - viewport.current.clientHeight
          // eslint-disable-next-line no-magic-numbers
          setBottomTransparency(100 - Math.min(((overflow - position.y) / fadeRange) * 30, 30))
          // eslint-disable-next-line no-magic-numbers
          setTopTransparency(100 - Math.min((position.y / fadeRange) * 30, 30))
        }
      },
      [fadeRange],
    )

    useLayoutEffect(() => {
      onScrollPositionChange({ x: 0, y: 0 })
    }, [onScrollPositionChange])

    if (loading) {
      return <DailyCalendarLoadingGroup test-id='radio-group:time-slot-loading' />
    }

    if (data.length === 0) {
      return <>{empty}</>
    }

    let maxHeight: Property.MaxHeight<string | number> = 0
    // Use unlimited height if timeslots less than size or size equals all
    if (size === 'full' || data.length <= size) {
      maxHeight = 'unset'
    } else {
      maxHeight = itemHeight * (size + 0.5) + px(spacing.sm) * (size - 1) + px(sizes.gap.md)
    }

    return (
      <InputWrapper
        warning={warning}
        error={error}
        success={success}
        explanation={explanation}
        test-id={props['test-id']}
      >
        <ScrollAreaAutosize
          maxHeight={maxHeight}
          styles={({ other: { sizes } }) => ({
            root: {
              flexGrow: 1,
              display: 'flex',
              marginLeft: -sizes.gap.md,
              marginRight: -sizes.gap.md,
            },
            viewport: { flexGrow: 1, height: 'auto', padding: sizes.gap.md },
          })}
          viewportRef={viewport}
          onScrollPositionChange={onScrollPositionChange}
        >
          <Box ref={ref}>
            <RadioGroup
              warning={Boolean(warning)}
              error={Boolean(error)}
              success={Boolean(success)}
              value={value}
              onChange={onChange}
              test-id='radio-group:time-slot'
            >
              {data.map(datetime => (
                <Radio
                  disabled={disabled}
                  value={datetime}
                  label={dayjs(datetime)
                    .tz(tz ?? undefined)
                    .format('h:mma z')}
                  key={datetime}
                />
              ))}
            </RadioGroup>
          </Box>
          <Overlay
            gradient={`linear-gradient(to top,transparent ${topTransparency}%, ${colors.background[0]})`}
            sx={{ pointerEvents: 'none' }}
          />
          <Overlay
            gradient={`linear-gradient(transparent ${bottomTransparency}%, ${colors.background[0]})`}
            sx={{ pointerEvents: 'none' }}
          />
        </ScrollAreaAutosize>
      </InputWrapper>
    )
  },
)

DailyCalendarRadioGroup.displayName = 'DailyCalendarRadioGroup'

export const DailyCalendarLoadingGroup = () => {
  const {
    other: { sizes },
  } = useMantineTheme()

  const height = sizes.input.md

  return (
    <Stack spacing='sm'>
      <Skeleton height={height} />
      <Skeleton height={height} />
      <Skeleton height={height} />
      <Skeleton height={height} />
      <Skeleton height={height} />
      <Skeleton height={height} />
    </Stack>
  )
}
