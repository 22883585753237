import { StateAbbr } from './api'

// In alphabetical order by state name
export const states = [
  { state: 'Alabama', abbr: 'AL', active: false, paused: false },
  { state: 'Alaska', abbr: 'AK', active: false, paused: false },
  { state: 'Arizona', abbr: 'AZ', active: true, paused: false },
  { state: 'Arkansas', abbr: 'AR', active: false, paused: false },
  { state: 'California', abbr: 'CA', active: true, paused: false },
  { state: 'Colorado', abbr: 'CO', active: false, paused: false },
  { state: 'Connecticut', abbr: 'CT', active: true, paused: false },
  { state: 'Delaware', abbr: 'DE', active: false, paused: false },
  { state: 'District of Columbia', abbr: 'DC', active: false, paused: false },
  { state: 'Florida', abbr: 'FL', active: true, paused: false },
  { state: 'Georgia', abbr: 'GA', active: false, paused: false },
  { state: 'Hawaii', abbr: 'HI', active: false, paused: false },
  { state: 'Idaho', abbr: 'ID', active: false, paused: false },
  { state: 'Illinois', abbr: 'IL', active: false, paused: false },
  { state: 'Indiana', abbr: 'IN', active: false, paused: false },
  { state: 'Iowa', abbr: 'IA', active: false, paused: false },
  { state: 'Kansas', abbr: 'KS', active: false, paused: false },
  { state: 'Kentucky', abbr: 'KY', active: false, paused: false },
  { state: 'Louisiana', abbr: 'LA', active: false, paused: false },
  { state: 'Maine', abbr: 'ME', active: true, paused: false },
  { state: 'Maryland', abbr: 'MD', active: true, paused: false },
  { state: 'Massachusetts', abbr: 'MA', active: false, paused: false },
  { state: 'Michigan', abbr: 'MI', active: false, paused: false },
  { state: 'Minnesota', abbr: 'MN', active: false, paused: false },
  { state: 'Mississippi', abbr: 'MS', active: false, paused: false },
  { state: 'Missouri', abbr: 'MO', active: false, paused: false },
  { state: 'Montana', abbr: 'MT', active: true, paused: false },
  { state: 'Nebraska', abbr: 'NE', active: false, paused: false },
  { state: 'Nevada', abbr: 'NV', active: false, paused: false },
  { state: 'New Hampshire', abbr: 'NH', active: false, paused: false },
  { state: 'New Jersey', abbr: 'NJ', active: true, paused: false },
  { state: 'New Mexico', abbr: 'NM', active: false, paused: false },
  { state: 'New York', abbr: 'NY', active: true, paused: false },
  { state: 'North Carolina', abbr: 'NC', active: false, paused: false },
  { state: 'North Dakota', abbr: 'ND', active: false, paused: false },
  { state: 'Ohio', abbr: 'OH', active: false, paused: false },
  { state: 'Oklahoma', abbr: 'OK', active: false, paused: false },
  { state: 'Oregon', abbr: 'OR', active: false, paused: false },
  { state: 'Pennsylvania', abbr: 'PA', active: true, paused: false },
  { state: 'Rhode Island', abbr: 'RI', active: false, paused: false },
  { state: 'South Carolina', abbr: 'SC', active: false, paused: false },
  { state: 'South Dakota', abbr: 'SD', active: false, paused: false },
  { state: 'Tennessee', abbr: 'TN', active: false, paused: false },
  { state: 'Texas', abbr: 'TX', active: true, paused: false },
  { state: 'Utah', abbr: 'UT', active: false, paused: false },
  { state: 'Vermont', abbr: 'VT', active: true, paused: true },
  { state: 'Virginia', abbr: 'VA', active: true, paused: false },
  { state: 'Washington', abbr: 'WA', active: true, paused: false },
  { state: 'West Virginia', abbr: 'WV', active: false, paused: false },
  { state: 'Wisconsin', abbr: 'WI', active: false, paused: false },
] as const

export const stateAbbreviations = states.map(state => state.abbr)

export function isStateAbbreviation(state: string): state is StateAbbr {
  return stateAbbreviations.includes(state as StateAbbr)
}

export const stateNames = states.map(state => state.state)
export type StateName = (typeof stateNames)[number]

export const activeStates = states.filter(state => state.active)

export const stateTimeZones: Map<StateAbbr, string> = new Map([
  // not necessarily true
  ['AZ', 'America/Phoenix'],
  ['CA', 'America/Los_Angeles'],
  ['CT', 'America/New_York'],
  ['FL', 'America/New_York'],
  ['ME', 'America/New_York'],
  ['MD', 'America/New_York'],
  ['MT', 'America/Denver'],
  ['NJ', 'America/New_York'],
  ['NY', 'America/New_York'],
  ['PA', 'America/New_York'],
  // not necessarily true
  ['TX', 'America/Chicago'],
  ['VT', 'America/New_York'],
  ['VA', 'America/New_York'],
  ['WA', 'America/Los_Angeles'],
])
