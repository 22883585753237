import {
  Appointment,
  AppointmentTypeString,
  Employee,
  NonVisitEvent,
  OpheliaRoute,
  Patient,
} from '..'

export type TwilioApi = OpheliaRoute<
  'GET /patients',
  {
    req: {
      query: {
        phoneNumber: string
      }
    }
    res: {
      patient: Partial<Patient>
      pc?: Employee
    }
  }
> &
  OpheliaRoute<
    'GET /patients/:patientId/appointments',
    {
      req: {
        params: {
          patientId: string
        }
        query: {
          start?: string
          end?: string
          canceled?: 'yes' | 'no'
          confirmed?: 'yes' | 'no'
          type?: AppointmentTypeString
          dateOrder?: 'asc' | 'desc'
        }
      }
      res: Appointment[]
    }
  > &
  OpheliaRoute<
    'GET /patients/:patientId/non-visit-events',
    {
      req: {
        params: {
          patientId: string
        }
      }
      res: NonVisitEvent[]
    }
  > &
  OpheliaRoute<
    'GET /clinicians/:employeeId',
    {
      req: {
        params: {
          employeeId: string
        }
      }
      res: Employee
    }
  >
