// eslint-disable-next-line no-restricted-imports
import { Divider, Group, Stack, useMantineTheme } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import React from 'react'
import { Menu } from './Menu'
import { Pill, PillStatus } from './Pill'
import { SecondaryButton, TertiaryButton } from './buttons'
import { ArrowRightIcon, MoreVerticalIcon } from './icons'
import { Text } from './typography'

export type DocumentCardProps = {
  title: string
  status?: [string, PillStatus] | undefined
  bodyText?: string | undefined
  dateText?: [string] | [string, string] | undefined
  actions?: { label: string; callback: () => void }[]
}

export const DocumentCard = ({
  title,
  status,
  bodyText,
  dateText,
  actions = [],
}: DocumentCardProps): JSX.Element => {
  const primaryDateText = dateText?.[0]
  const secondaryDateText = dateText?.[1]

  const titleComponent = <Text bold>{title}</Text>
  const pill = status && <Pill status={status[1]}>{status[0]}</Pill>
  const bodyTextComponent = bodyText && <Text size='xs'>{bodyText}</Text>
  const primaryDateTextComponent = primaryDateText && <Text size='xs'>{primaryDateText}</Text>
  const secondaryDateTextComponent = secondaryDateText && <Text size='xs'>{secondaryDateText}</Text>
  const actionsDropdown = actions.length > 1 && (
    <Menu position='bottom-end'>
      <Menu.Target>
        <SecondaryButton size='xs' leftIcon={<MoreVerticalIcon />} />
      </Menu.Target>
      <Menu.Dropdown>
        {actions.map(({ label, callback }) => (
          <Menu.Item key={label} onClick={callback}>
            {label}
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  )
  const primaryActionButton = actions.length === 1 && (
    <TertiaryButton rightIcon={<ArrowRightIcon />} onClick={actions[0]?.callback}>
      {actions[0]!.label}
    </TertiaryButton>
  )

  const theme = useMantineTheme()
  // smallerThan returns a string with '@media' in it
  const isMobile = useMediaQuery(theme.fn.smallerThan('sm').replace('@media', ''))

  return (
    <Stack
      sx={theme => ({
        borderWidth: theme.other.sizes.border.md,
        borderStyle: 'solid',
        borderColor: theme.other.colors.background[1],
        borderRadius: theme.radius.sm,
        padding: theme.other.sizes.padding.lg,
      })}
    >
      {isMobile ? (
        <>
          <Group position='apart'>
            {status ? pill : titleComponent}
            {actionsDropdown}
          </Group>
          {status && titleComponent}
          {bodyTextComponent}
          {primaryDateTextComponent && (
            <Stack spacing='sm'>
              {primaryDateTextComponent}
              {secondaryDateTextComponent}
            </Stack>
          )}
          {primaryActionButton}
        </>
      ) : (
        <>
          <Group position='apart'>
            <Group>
              {titleComponent}
              {pill}
            </Group>
            {actionsDropdown}
            {primaryActionButton}
          </Group>
          {bodyTextComponent}
          {primaryDateText && (
            <Group spacing='sm'>
              {primaryDateTextComponent}
              {secondaryDateText && (
                <>
                  <Divider
                    orientation='vertical'
                    sx={theme => ({ borderColor: theme.other.colors.text[1] })}
                  />
                  {secondaryDateTextComponent}
                </>
              )}
            </Group>
          )}
        </>
      )}
    </Stack>
  )
}
