import { H } from 'highlight.run'
import * as RS from './analytics'
import { Config } from './config'

export const init = () => {
  if (Config.NODE_ENV === 'development' || Config.NODE_ENV === 'staging') {
    H.init(Config.REACT_APP_HIGHLIGHT_IO_PROJECT_KEY, {
      serviceName: 'Bridge',
      tracingOrigins: true,
      networkRecording: {
        enabled: true,
        recordHeadersAndBody: true,
        urlBlocklist: [],
      },
    })

    const anonymousId = RS.analytics.getAnonymousId()

    H.identify(anonymousId, {
      id: anonymousId,
    })
  } else {
    return
  }
}
