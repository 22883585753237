// eslint-disable-next-line no-restricted-imports
import {
  Box,
  Flex,
  FocusTrap,
  Drawer as MantineDrawer,
  DrawerProps as MantineDrawerProps,
  useMantineTheme,
} from '@mantine/core'
import { useViewportSize } from '@mantine/hooks'
import React, { PropsWithChildren, ReactNode, forwardRef } from 'react'
import { Divider } from './Divider'
import { ScrollArea } from './ScrollArea'
import { TertiaryButton } from './buttons'
import { ArrowLeftCircleIcon, XIcon } from './icons'
import { TitleThree } from './typography'

type AllowedSizes = 'sm' | 'lg' | 'xl' | '80%'

export type DrawerProps = Omit<
  MantineDrawerProps,
  'padding' | 'styles' | 'sx' | 'withCloseButton' | 'title' | 'size'
> & {
  title: ReactNode
  footer?: JSX.Element
  onBack?: () => void
  size: AllowedSizes
}

export type BetterDrawerProps = Omit<
  MantineDrawerProps,
  'padding' | 'styles' | 'sx' | 'withCloseButton' | 'title' | 'size' | 'trapFocus'
> & {
  size: AllowedSizes
  float?: boolean
}

const sizes: Record<AllowedSizes, string> = {
  sm: '24rem',
  lg: '32rem',
  xl: '42rem',
  '80%': '80%',
}

export const Drawer = React.forwardRef<HTMLDivElement, DrawerProps>(
  ({ children, footer, title, onClose, onBack, trapFocus = true, ...rest }, scrollableRef) => {
    return (
      <BetterDrawer onClose={onClose} {...rest}>
        <DrawerHeader onClose={onClose} onBack={onBack}>
          {title}
        </DrawerHeader>
        <DrawerContent trapFocus={trapFocus} ref={scrollableRef}>
          {children}
        </DrawerContent>
        <DrawerFooter>{footer}</DrawerFooter>
      </BetterDrawer>
    )
  },
)

Drawer.displayName = 'Drawer'

export const BetterDrawer = ({ children, size = 'lg', ...rest }: BetterDrawerProps) => {
  const { height } = useViewportSize()

  return (
    <MantineDrawer
      padding={0}
      withCloseButton={false}
      trapFocus={false}
      size={sizes[size]}
      styles={{ overlay: { backgroundColor: 'rgba(42, 43, 46, 0.5)' } }}
      {...rest}
    >
      <Flex direction='column' sx={{ height }}>
        {children}
      </Flex>
    </MantineDrawer>
  )
}

BetterDrawer.displayName = 'BetterDrawer'

export const DrawerHeader = ({
  onBack,
  onClose,
  children,
}: PropsWithChildren<{ onBack?: (() => void) | undefined; onClose: () => void }>) => {
  const {
    other: { colors, sizes },
  } = useMantineTheme()

  return (
    <>
      <Flex justify='space-between' align='flex-start' sx={{ padding: sizes.gap.xl }}>
        <Flex align='flex-start' sx={{ gap: sizes.gap.xl }}>
          {onBack && (
            <TertiaryButton size='sm' onClick={onBack} leftIcon={<ArrowLeftCircleIcon styled />} />
          )}
          <TitleThree color={colors.text[0]}>{children}</TitleThree>
        </Flex>
        <TertiaryButton size='sm' onClick={onClose} leftIcon={<XIcon color={colors.text[0]} />} />
      </Flex>
      <Divider />
    </>
  )
}

export const DrawerContent = forwardRef<HTMLDivElement, PropsWithChildren<{ trapFocus?: boolean }>>(
  ({ children, trapFocus = true }, scrollableRef) => {
    return (
      <ScrollArea styles={{ root: { flex: 1 } }} ref={scrollableRef}>
        <FocusTrap active={trapFocus}>{children}</FocusTrap>
      </ScrollArea>
    )
  },
)

DrawerContent.displayName = 'DrawerContent'

export const DrawerFooter = ({ children }: PropsWithChildren) => {
  return (
    <Box
      sx={({ other: { sizes, colors } }) => ({
        padding: sizes.gap.xl,
        backgroundColor: colors.background[1],
      })}
    >
      {children}
    </Box>
  )
}

export default Drawer
