const formatParamKey = (key: string) => `{${key}}`

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isTruthy = (val: any) => val !== undefined && val !== null && val !== false && val !== ''

export const template = <T extends string>(
  pattern: string | string[],
  params: Record<string, string | number | null | undefined | boolean>,
) => {
  const normalizedPattern = Array.isArray(pattern) ? pattern : [pattern]

  return (
    normalizedPattern
      .filter((part, idx) => {
        // The first part is always included, even if the params in it have non-truthy values
        if (idx === 0) {
          return true
        }

        // Keep current part if any of the params in it have a truthy value
        return Object.keys(params).some(key => {
          const val = params[key]
          return (
            // Ensure that param is in the part and has some value, else remove whole part from the final string
            part.includes(formatParamKey(key)) && isTruthy(val)
          )
        })
      })
      .map(part => {
        for (const [key, value] of Object.entries(params)) {
          // The following code will replace all instances of a keyword, instead of just the first instance
          const regex = new RegExp(formatParamKey(key), 'g')
          part = part.replace(regex, String(value || '')) as T
        }

        return part
      })
      .map(part => {
        // Remove and trim all extra spaces that resulted from empty params
        return part.split(' ').filter(Boolean).join(' ')
      })
      // Join together the parts so the final string is a single string again
      .join(' ')
  )
}
