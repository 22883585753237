import { StateAbbr } from './api'
import {
  Employee,
  EmployeeItem,
  EmployeeModel,
  EmployeeStatus,
  EmploymentStatus,
  VerifiedClinician,
} from './models/employee'

export const roleName: { [key in Employee['role']]: string } = {
  admin: 'Admin',
  consultationCallQueue: 'Consultation Call Queue',
  payorOps: 'Payor Operations',
  engineer: 'Engineer',
  legal: 'Legal Team Member',

  employee: 'Employee',

  spc: 'Supervising Prescribing Clinician',
  pc: 'Prescribing Clinician',
  ncm: 'Clinical Care Manager',
  tn: 'Triage Nurse',
  ncm_tn: 'Clinical Care Manager / Triage Nurse',

  leadCareCoordinator: 'Lead Care Coordinator',
  careCoordinator: 'Care Coordinator',
  resourceNavigator: 'Resource Navigator',
  financialCounselor: 'Financial Counselor',

  leadEnrollmentCoordinator: 'Lead Enrollment Coordinator',
  enrollmentCoordinator: 'Enrollment Coordinator',

  student: 'Student',
}

export const careTeamPods = ['alpha', 'gamma'] as const
export type CareTeamPod = (typeof careTeamPods)[number]
export const podName: { [key in CareTeamPod]: string } = {
  alpha: 'Alpha',
  gamma: 'Gamma',
}

export const getEmployeePod = (employee: Employee): CareTeamPod | null => {
  return 'pod' in employee ? employee.pod : null
}

export const getProviderInfo = (employee: Employee) => {
  return 'providerInfo' in employee ? employee.providerInfo : null
}

export const employmentStatusLabel: { [key in EmploymentStatus]: string } = {
  employee: 'Employee',
  contractor: 'Contractor',
}

type Role = Employee['role']
export type EmployeeGroupRole =
  | 'admin'
  | 'careCoordinator'
  | 'ccm'
  | 'clinician'
  | 'consultationCallQueue'
  | 'engineer'
  | 'enrollmentCoordinator'
  | 'hqEmployee'
  | 'leadCareCoordinator'
  | 'leadEnrollmentCoordinator'
  | 'primaryClinician'
  | 'student'

type EmployeeRole<T extends EmployeeGroupRole> = T extends 'clinician'
  ? Employee<'clinician'>
  : T extends 'consultationCallQueue'
  ? Employee<'consultation-call-queue'>
  : Employee<'basic'>

type EmployeeItemRole<T extends EmployeeGroupRole> = T extends 'clinician'
  ? EmployeeItem<'clinician'>
  : T extends 'consultationCallQueue'
  ? EmployeeItem<'consultation-call-queue'>
  : EmployeeItem<'basic'>

type EmployeeModelRole<T extends EmployeeGroupRole> = T extends 'clinician'
  ? EmployeeModel<'clinician'>
  : T extends 'consultationCallQueue'
  ? EmployeeModel<'consultation-call-queue'>
  : EmployeeModel<'basic'>

export const employeeGroupRoles: Record<EmployeeGroupRole, Role[]> = {
  admin: ['admin', 'engineer', 'legal', 'payorOps'],
  careCoordinator: [
    'careCoordinator',
    'enrollmentCoordinator',
    'financialCounselor',
    'leadCareCoordinator',
    'leadEnrollmentCoordinator',
    'resourceNavigator',
  ],
  ccm: ['ncm', 'ncm_tn'],
  clinician: ['ncm', 'pc', 'spc', 'tn', 'ncm_tn'],
  consultationCallQueue: ['consultationCallQueue'],
  engineer: ['engineer'],
  enrollmentCoordinator: ['enrollmentCoordinator', 'leadEnrollmentCoordinator'],
  hqEmployee: ['employee'],
  leadCareCoordinator: ['leadCareCoordinator', 'leadEnrollmentCoordinator'],
  leadEnrollmentCoordinator: ['leadEnrollmentCoordinator'],
  primaryClinician: ['pc', 'spc'],
  student: ['student'],
}

type Nullable<T> = T | null | undefined

export function hasGroupRole<T extends EmployeeGroupRole>(
  employee?: Employee,
  ...roles: T[]
): employee is EmployeeRole<T>
export function hasGroupRole<T extends EmployeeGroupRole>(
  employee: EmployeeItem,
  ...roles: T[]
): employee is EmployeeItemRole<T>
export function hasGroupRole<T extends EmployeeGroupRole>(
  employee: EmployeeModel,
  ...roles: T[]
): employee is EmployeeModelRole<T>

export function hasGroupRole<T extends EmployeeGroupRole>(
  employee: Nullable<Employee>[],
  ...roles: T[]
): EmployeeRole<T>[]
export function hasGroupRole<T extends EmployeeGroupRole>(
  employee: Nullable<EmployeeItem>[],
  ...roles: T[]
): EmployeeItemRole<T>[]
export function hasGroupRole<T extends EmployeeGroupRole>(
  employee: Nullable<EmployeeModel>[],
  ...roles: T[]
): EmployeeModelRole<T>[]

export function hasGroupRole<T extends EmployeeGroupRole>(
  ...roles: T[]
): {
  (employee: Employee): employee is EmployeeRole<T>
  (employee: EmployeeItem): employee is EmployeeItemRole<T>
  (employee: EmployeeModel): employee is EmployeeModelRole<T>
  (employee: Nullable<Employee>[]): EmployeeRole<T>[]
  (employee: Nullable<EmployeeItem>[]): EmployeeItemRole<T>[]
  (employee: Nullable<EmployeeModel>[]): EmployeeModelRole<T>[]
}

export function hasGroupRole<T extends EmployeeGroupRole>(
  value:
    | Employee
    | EmployeeItem
    | EmployeeModel
    | Nullable<Employee>[]
    | Nullable<EmployeeItem>[]
    | Nullable<EmployeeModel>[]
    | T,
  ...roles: T[]
) {
  if (typeof value === 'string') {
    return (employee: Employee) => hasGroupRole(employee, ...[value, ...roles])
  }

  if (Array.isArray(value)) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unnecessary-type-assertion
    return (value as any[]).filter(v => v && hasGroupRole(v, ...roles))
  }

  for (const role of roles) {
    if (employeeGroupRoles[role]?.some(role => role === value?.role)) {
      return true
    }
  }

  return false
}

export const hasRole = (
  employee:
    | Employee
    | EmployeeItem
    | EmployeeModel
    | Nullable<Employee>
    | Nullable<EmployeeItem>
    | Nullable<EmployeeModel>,
  ...roles: Employee['role'][]
) => {
  return roles.some(role => role === employee?.role)
}

export const isAdmin = hasGroupRole('admin')
export const isClinician = hasGroupRole('clinician')
export const isCareCoordinator = hasGroupRole('careCoordinator')
export const isHqEmployee = hasGroupRole('hqEmployee')
export const isStudent = hasGroupRole('student')
export const isPrimaryClinician = hasGroupRole('primaryClinician')
export const isCcm = hasGroupRole('ccm')
export const isConsultationCallQueue = hasGroupRole('consultationCallQueue')

export function isVerifiedClinician(
  clinician: Employee<'clinician'>,
): clinician is VerifiedClinician {
  return Boolean(clinician.providerInfo) && Boolean(clinician.name)
}

export function isClinicianVerifiedInState(clinician: Employee<'clinician'>, state: StateAbbr) {
  const states = clinician.providerInfo?.licenses?.map(license => license.state)
  return states?.includes(state)
}

export function hasEmployeeStatus(employee: Employee | undefined, status: EmployeeStatus) {
  return employee?.status === status
}
