// Pulled from https://docs.google.com/spreadsheets/d/1T7JT9GVB7Ehdazt7mFlcHsQEjhj5hAbJb8rdkZOwj6Y/edit#gid=0

import capitalize from 'lodash/capitalize'
import { OtherString } from '.'
import { EMRTaskPriority } from './models/tasks'

export type TaskType =
  | 'systems_failure'
  | 'merged_accounts'
  | 'duplicate_accounts'
  | 'change_personal_information'
  | 'change_status_to_not_responding'
  | 'verification'
  | 'in_network_check'
  | 'documentation_of_care'
  | 'roi_coordination'
  | 'external_roi_review'
  | 'compliance_consult'
  | 'medication_administration'
  | 'non_urgent_medical_concern'
  | 'urgent_medical_concern'
  | 'shipment'
  | 'photo_submission'
  | 'photo_request'
  | 'quest_requisition'
  | 'labcorp_requisition'
  | 'resource_request'
  | 'resource_navigation'
  | 'support_plan'
  | 'external_advocacy'
  | 'pausing_care'
  | 'self_determined_end_of_care'
  | 'non_compliance_with_policy'
  | 'failure_to_pay'
  | 'failure_to_pay_addressed'
  | 'alternative_provider'
  | 'higher_level_of_care'
  | 'out_of_state'
  | 'resend_prescription'
  | 'bridge_prescription'
  | 'general_refill_request'
  | 'prescription_override'
  | 'prescription_investigation'
  | 'early_fill'
  | 'pharmacy_transfer'
  | 'pharmacist_education'
  | 'coverage_investigation'
  | 'submission'
  | 'appeal'
  | 'approval'
  | 'schedule_intake'
  | 'reschedule_intake'
  | 'intake_attendance_policy_discussion'
  | 'schedule_follow_up'
  | 'reschedule_follow_up'
  | 'follow_up_attendance_policy_discussion'
  | 'clinician_transfer'
  | 'payment_plan'
  | 'change_next_billing_date'
  | 'financial_assistance'
  | 'payment_policy_discussion'
  | 'forgive_invoice'
  | 'claim_status'
  | 'clinical_discharge'
  | 'co_payment'
  | 'oon_claim'
  | 'non_adherent'
  | 'disengagement'
  | 'mbc_form'
  | 'schedule_wellness_visit'
  | 'insurance_inactive'
  | 'coverage_of_benefits'
  | 'superbill_request'
  | 'eligibility_review'
  | 'outstanding_balance'
  | 'credentialing'
  | 'refund'
  | 're_enrollment'
  | 'review_time'
  | 'clinical_contact'
  | 'discharge_cocm_patient'
  | 'account_balance_payment_agreement'
  | 'winback_call'
  | 'account_balance_payment_non_compliance'
  | 'process_discharge'

export type ConsultationCallTaskType =
  | 'scheduled'
  | 'call_me_now'
  | 'intake_cancelation_reason'
  | 'support_call'

export type EMRTaskType = TaskType | ConsultationCallTaskType
export type EMRTaskTypeMapType = {
  value: EMRTaskType
  label: string
  priority: EMRTaskPriority
  cocm_billable: boolean
  est_time_in_mins: number
  category: string
  clinician_visible: boolean
}

export type TaskTypeMapType = {
  value: TaskType
  label: string
  priority: EMRTaskPriority
  cocm_billable: boolean
  est_time_in_mins: number
  category: string
  clinician_visible: boolean
}

export type ConsultationCallTaskTypeMapType = {
  value: ConsultationCallTaskType
  label: string
  priority: EMRTaskPriority
  cocm_billable: boolean
  est_time_in_mins: number
  category: string
  clinician_visible: boolean
}

export const TaskTypeMap: TaskTypeMapType[] = [
  {
    value: 'systems_failure',
    label: 'Systems failure',
    priority: 4,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'patient_data.technical',
    clinician_visible: false,
  },
  {
    value: 'merged_accounts',
    label: 'Merged accounts',
    priority: 4,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'patient_data.technical',
    clinician_visible: false,
  },
  {
    value: 'duplicate_accounts',
    label: 'Duplicate accounts',
    priority: 2,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'patient_data.technical',
    clinician_visible: false,
  },
  {
    value: 'change_personal_information',
    label: 'Update patient information',
    priority: 1,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'patient_data',
    clinician_visible: true,
  },
  {
    value: 'change_status_to_not_responding',
    label: 'Change status to not responding',
    priority: 3,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'patient_data',
    clinician_visible: false,
  },
  {
    value: 'verification',
    label: 'Verification',
    priority: 3,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'patient_data.insurance_investigation',
    clinician_visible: false,
  },
  {
    value: 'in_network_check',
    label: 'In-network check',
    priority: 3,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'patient_data.insurance_investigation',
    clinician_visible: false,
  },
  {
    value: 'documentation_of_care',
    label: 'Documentation of care',
    priority: 3,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'documentation',
    clinician_visible: true,
  },
  {
    value: 'roi_coordination',
    label: 'ROI coordination',
    priority: 3,
    cocm_billable: true,
    est_time_in_mins: 5,
    category: 'documentation.roi',
    clinician_visible: true,
  },
  {
    value: 'external_roi_review',
    label: 'External ROI review',
    priority: 3,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'documentation.roi',
    clinician_visible: false,
  },
  {
    value: 'compliance_consult',
    label: 'Compliance consult',
    priority: 3,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'documentation',
    clinician_visible: false,
  },
  {
    value: 'medication_administration',
    label: 'Medication administration',
    priority: 5,
    cocm_billable: true,
    est_time_in_mins: 10,
    category: 'medical',
    clinician_visible: false,
  },
  {
    value: 'non_urgent_medical_concern',
    label: 'Non-urgent medical concern',
    priority: 4,
    cocm_billable: true,
    est_time_in_mins: 3,
    category: 'medical',
    clinician_visible: false,
  },
  {
    value: 'urgent_medical_concern',
    label: 'Urgent medical concern',
    priority: 5,
    cocm_billable: true,
    est_time_in_mins: 10,
    category: 'medical',
    clinician_visible: false,
  },
  {
    value: 'shipment',
    label: 'Shipment',
    priority: 3,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'medical.uds',
    clinician_visible: true,
  },
  {
    value: 'photo_submission',
    label: 'Photo submission',
    priority: 3,
    cocm_billable: true,
    est_time_in_mins: 0,
    category: 'medical.uds',
    clinician_visible: false,
  },

  {
    value: 'photo_request',
    label: 'Photo request',
    priority: 3,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'medical.uds',
    clinician_visible: false,
  },
  {
    value: 'quest_requisition',
    label: 'Quest requisition',
    priority: 3,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'medical.uds',
    clinician_visible: true,
  },
  {
    value: 'labcorp_requisition',
    label: 'LabCorp requisition',
    priority: 3,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'medical.uds',
    clinician_visible: true,
  },
  {
    value: 'resource_request',
    label: 'Resource request',
    priority: 3,
    cocm_billable: true,
    est_time_in_mins: 20,
    category: 'support.resource_management',
    clinician_visible: true,
  },
  {
    value: 'resource_navigation',
    label: 'Resource navigation',
    priority: 3,
    cocm_billable: true,
    est_time_in_mins: 20,
    category: 'support.resource_management',
    clinician_visible: false,
  },
  {
    value: 'support_plan',
    label: 'Support plan',
    priority: 3,
    cocm_billable: true,
    est_time_in_mins: 20,
    category: 'support.behavioral_support',
    clinician_visible: true,
  },
  {
    value: 'external_advocacy',
    label: 'External advocacy',
    priority: 3,
    cocm_billable: true,
    est_time_in_mins: 20,
    category: 'support.behavioral_support',
    clinician_visible: false,
  },
  {
    value: 'clinical_discharge',
    label: 'Clinical discharge',
    priority: 3,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'discontinuation',
    clinician_visible: false,
  },
  {
    value: 'pausing_care',
    label: 'Pausing care',
    priority: 3,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'discontinuation',
    clinician_visible: false,
  },
  {
    value: 'self_determined_end_of_care',
    label: 'Self-determined end of care',
    priority: 3,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'discontinuation',
    clinician_visible: false,
  },
  {
    value: 'non_compliance_with_policy',
    label: 'Non-compliance with policy',
    priority: 3,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'discontinuation',
    clinician_visible: false,
  },
  {
    value: 'failure_to_pay',
    label: 'Failure to pay',
    priority: 3,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'discontinuation',
    clinician_visible: false,
  },
  {
    value: 'failure_to_pay_addressed',
    label: 'Failure to pay addressed',
    priority: 3,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'discontinuation',
    clinician_visible: false,
  },
  {
    value: 'alternative_provider',
    label: 'Alternative provider',
    priority: 3,
    cocm_billable: true,
    est_time_in_mins: 20,
    category: 'discontinuation.transfer',
    clinician_visible: false,
  },
  {
    value: 'higher_level_of_care',
    label: 'Higher level of care',
    priority: 3,
    cocm_billable: true,
    est_time_in_mins: 20,
    category: 'discontinuation.transfer',
    clinician_visible: false,
  },
  {
    value: 'out_of_state',
    label: 'Out-of-state',
    priority: 3,
    cocm_billable: true,
    est_time_in_mins: 20,
    category: 'discontinuation.transfer',
    clinician_visible: false,
  },
  {
    value: 'resend_prescription',
    label: 'Resend prescription',
    priority: 5,
    cocm_billable: true,
    est_time_in_mins: 3,
    category: 'prescription.queuing',
    clinician_visible: false,
  },
  {
    value: 'bridge_prescription',
    label: 'Bridge prescription',
    priority: 5,
    cocm_billable: true,
    est_time_in_mins: 3,
    category: 'prescription.queuing',
    clinician_visible: false,
  },
  {
    value: 'general_refill_request',
    label: 'General refill request',
    priority: 5,
    cocm_billable: true,
    est_time_in_mins: 3,
    category: 'prescription.queuing',
    clinician_visible: false,
  },
  {
    value: 'prescription_override',
    label: 'Prescription override',
    priority: 5,
    cocm_billable: true,
    est_time_in_mins: 10,
    category: 'prescription.pharmacy_engagement',
    clinician_visible: false,
  },
  {
    value: 'prescription_investigation',
    label: 'Prescription investigation',
    priority: 5,
    cocm_billable: true,
    est_time_in_mins: 3,
    category: 'prescription.pharmacy_engagement',
    clinician_visible: true,
  },
  {
    value: 'early_fill',
    label: 'Early fill',
    priority: 5,
    cocm_billable: true,
    est_time_in_mins: 10,
    category: 'prescription.pharmacy_engagement',
    clinician_visible: false,
  },
  {
    value: 'pharmacy_transfer',
    label: 'Pharmacy transfer',
    priority: 5,
    cocm_billable: true,
    est_time_in_mins: 5,
    category: 'prescription.pharmacy_engagement',
    clinician_visible: false,
  },
  {
    value: 'pharmacist_education',
    label: 'Pharmacist education',
    priority: 4,
    cocm_billable: true,
    est_time_in_mins: 10,
    category: 'prescription.pharmacy_engagement',
    clinician_visible: false,
  },
  {
    value: 'coverage_investigation',
    label: 'Coverage investigation',
    priority: 4,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'prescription.prior_authorization',
    clinician_visible: false,
  },
  {
    value: 'submission',
    label: 'Submission',
    priority: 4,
    cocm_billable: true,
    est_time_in_mins: 20,
    category: 'prescription.prior_authorization',
    clinician_visible: true,
  },
  {
    value: 'appeal',
    label: 'Appeal',
    priority: 4,
    cocm_billable: true,
    est_time_in_mins: 20,
    category: 'prescription.prior_authorization',
    clinician_visible: false,
  },
  {
    value: 'approval',
    label: 'Approval',
    priority: 2,
    cocm_billable: true,
    est_time_in_mins: 20,
    category: 'prescription.prior_authorization',
    clinician_visible: false,
  },
  {
    value: 'schedule_intake',
    label: 'Schedule',
    priority: 3,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'scheduling.intake',
    clinician_visible: false,
  },
  {
    value: 'reschedule_intake',
    label: 'Reschedule',
    priority: 4,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'scheduling.intake',
    clinician_visible: false,
  },
  {
    value: 'intake_attendance_policy_discussion',
    label: 'Attendance policy discussion',
    priority: 2,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'scheduling.intake',
    clinician_visible: false,
  },
  {
    value: 'schedule_follow_up',
    label: 'Schedule',
    priority: 3,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'scheduling.follow_up',
    clinician_visible: true,
  },
  {
    value: 'reschedule_follow_up',
    label: 'Reschedule',
    priority: 4,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'scheduling.follow_up',
    clinician_visible: false,
  },
  {
    value: 'follow_up_attendance_policy_discussion',
    label: 'Attendance policy discussion',
    priority: 2,
    cocm_billable: true,
    est_time_in_mins: 10,
    category: 'scheduling.follow_up',
    clinician_visible: false,
  },
  {
    value: 'clinician_transfer',
    label: 'Clinician transfer',
    priority: 4,
    cocm_billable: true,
    est_time_in_mins: 10,
    category: 'scheduling.follow_up',
    clinician_visible: true,
  },
  {
    value: 'schedule_wellness_visit',
    label: 'Schedule',
    priority: 3,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'scheduling.wellness_visit',
    clinician_visible: false,
  },
  {
    value: 'discharge_cocm_patient',
    label: 'Ineligible',
    priority: 3,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'cocm.cocm_discharge',
    clinician_visible: true,
  },
  {
    value: 'payment_plan',
    label: 'Payment plan',
    priority: 4,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'billing',
    clinician_visible: false,
  },
  {
    value: 'refund',
    label: 'Refund',
    priority: 2,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'billing',
    clinician_visible: false,
  },
  {
    value: 'outstanding_balance',
    label: 'Outstanding balance',
    priority: 3,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'billing',
    clinician_visible: false,
  },
  {
    value: 'account_balance_payment_agreement',
    label: 'Account balance payment agreement',
    priority: 3,
    cocm_billable: false,
    est_time_in_mins: 10,
    category: 'billing',
    clinician_visible: false,
  },
  {
    value: 'change_next_billing_date',
    label: 'Change next billing date',
    priority: 2,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'billing.subscription',
    clinician_visible: false,
  },
  {
    value: 'financial_assistance',
    label: 'Financial assistance',
    priority: 3,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'billing.subscription',
    clinician_visible: true,
  },
  {
    value: 'payment_policy_discussion',
    label: 'Payment policy discussion',
    priority: 3,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'billing.subscription',
    clinician_visible: false,
  },
  {
    value: 'forgive_invoice',
    label: 'Forgive invoice',
    priority: 2,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'billing.subscription',
    clinician_visible: false,
  },
  {
    value: 'superbill_request',
    label: 'Superbill request',
    priority: 2,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'billing.subscription',
    clinician_visible: false,
  },
  {
    value: 'claim_status',
    label: 'Claim status',
    priority: 2,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'billing.insurance',
    clinician_visible: false,
  },
  {
    value: 'co_payment',
    label: 'Co-payment',
    priority: 4,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'billing.insurance',
    clinician_visible: false,
  },
  {
    value: 'credentialing',
    label: 'Credentialing',
    priority: 3,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'billing.insurance',
    clinician_visible: false,
  },
  {
    value: 'eligibility_review',
    label: 'Eligibility review',
    priority: 3,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'billing.insurance',
    clinician_visible: false,
  },
  {
    value: 'coverage_of_benefits',
    label: 'Coverage of benefits',
    priority: 3,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'billing.insurance',
    clinician_visible: false,
  },
  {
    value: 'insurance_inactive',
    label: 'Insurance inactive',
    priority: 3,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'billing.insurance',
    clinician_visible: false,
  },
  {
    value: 'non_adherent',
    label: 'Patient outreach due to non-adherence',
    priority: 2,
    cocm_billable: true,
    est_time_in_mins: 10,
    category: 'patient_outreach.non_adherent',
    clinician_visible: false,
  },
  {
    value: 'disengagement',
    label: 'Patient outreach due to disengagement',
    priority: 2,
    cocm_billable: true,
    est_time_in_mins: 10,
    category: 'patient_outreach.disengagement',
    clinician_visible: false,
  },
  {
    value: 're_enrollment',
    label: 'Re-enrollment',
    priority: 4,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'enrollment.re_enrollment',
    clinician_visible: false,
  },
  {
    value: 'winback_call',
    label: 'Winback call',
    priority: 3,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'enrollment.winback_call',
    clinician_visible: false,
  },
  {
    value: 'mbc_form',
    label: 'MBC form',
    priority: 4,
    cocm_billable: true,
    est_time_in_mins: 10,
    category: 'cocm.panel_management',
    clinician_visible: false,
  },
  {
    value: 'review_time',
    label: 'Review time',
    priority: 5,
    cocm_billable: true,
    est_time_in_mins: 3,
    category: 'cocm.panel_management',
    clinician_visible: false,
  },
  {
    value: 'clinical_contact',
    label: 'Clinical contact',
    priority: 4,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'cocm.panel_management',
    clinician_visible: false,
  },
  {
    value: 'account_balance_payment_non_compliance',
    label: 'ABP non-compliance',
    priority: 3,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'billing',
    clinician_visible: false,
  },
  {
    value: 'process_discharge',
    label: 'Process discharge',
    priority: 3,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'discontinuation',
    clinician_visible: false,
  },
]

export const ConsultationCallTaskTypeMap: ConsultationCallTaskTypeMapType[] = [
  {
    value: 'call_me_now',
    label: 'Call me now',
    priority: 5,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'enrollment.welcome_call',
    clinician_visible: false,
  },
  {
    value: 'scheduled',
    label: 'Scheduled',
    priority: 4,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'enrollment.welcome_call',
    clinician_visible: false,
  },
  {
    value: 'intake_cancelation_reason',
    label: 'Intake Cancelation Reason',
    priority: 2,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'enrollment.documentation',
    clinician_visible: false,
  },
  {
    value: 'support_call',
    label: 'Support Call',
    priority: 4,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'enrollment.support_call',
    clinician_visible: false,
  },
]

export const EMRTaskTypeMap: EMRTaskTypeMapType[] = [...TaskTypeMap, ...ConsultationCallTaskTypeMap]

export const getPriorityForEmrTask = (taskType: EMRTaskType): EMRTaskPriority => {
  // Safe to typecast because we know that all task types have a priority
  return EMRTaskTypeMap.find(type => type.value === taskType)?.priority as EMRTaskPriority
}

export const getFormattedTaskCategory = (category: string) =>
  category.includes('.')
    ? category
        .split('.')
        .map(text => capitalize(text).replaceAll('_', ' '))
        .join(' | ')
    : capitalize(category).replaceAll('_', ' ')
export const EMRTaskCategories = EMRTaskTypeMap.map(task => getFormattedTaskCategory(task.category))
export type EMRTaskCategoryType = (typeof EMRTaskCategories)[number]

export const getNonVisitEventTitleForTask = (taskType: EMRTaskTypeMapType) => {
  const subcategory = taskType.category.includes('.')
    ? capitalize(taskType.category.split('.')[1]).replace(/_/g, ' ')
    : ''

  return subcategory ? `${taskType.label} | ${subcategory}` : `${taskType.label}`
}
export const NonVisitEventTitlesForTasks = EMRTaskTypeMap.map(task =>
  getNonVisitEventTitleForTask(task),
)

export type NonVisitEventTitleForTaskType = OtherString
