import {
  Group,
  HeartIcon,
  PlusIcon,
  TertiaryButton,
  Text,
  ThumbsUpIcon,
  UnstyledButton,
} from '@shared/components'
import { KudosboardPost } from '@shared/types'
import { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { bridgeApi } from '../api'
import { PostLoading } from './PostLoading'
import { PostTemplate } from './PostTemplate'

export type PostProps = {
  postId: string
}

const maxPostLength = 400

export const Post = ({ postId }: PostProps) => {
  const [seeMore, setSeeMore] = useState(false)

  const postQuery = useQuery(
    ...bridgeApi.getQuery('GET /posts/:id', {
      params: {
        id: postId,
      },
    }),
  )
  const post = postQuery.data?.post as KudosboardPost

  const heartMutation = useMutation(bridgeApi.getMutation('POST /posts/:id/heart'), {
    onSuccess: () => postQuery.refetch(),
  })
  const likeMutation = useMutation(bridgeApi.getMutation('POST /posts/:id/like'), {
    onSuccess: () => postQuery.refetch(),
  })

  if (!post) {
    return <PostLoading />
  }

  const shouldTruncate = !seeMore && post.text.length > maxPostLength
  const text = shouldTruncate ? `${post.text.substring(0, maxPostLength)}...` : post.text

  return (
    <PostTemplate
      footer={
        <Group position='center' spacing='lg'>
          <UnstyledButton onClick={() => heartMutation.mutate({ params: { id: postId } })}>
            <Group spacing='xs'>
              <HeartIcon />
              <Text>{post.hearts}</Text>
            </Group>
          </UnstyledButton>
          <UnstyledButton onClick={() => likeMutation.mutate({ params: { id: postId } })}>
            <Group spacing='xs'>
              <ThumbsUpIcon />
              <Text>{post.likes}</Text>
            </Group>
          </UnstyledButton>
        </Group>
      }
    >
      <Text>{text}</Text>
      {post.text.length > maxPostLength && !seeMore && (
        <TertiaryButton pt='sm' leftIcon={<PlusIcon />} onClick={() => setSeeMore(true)}>
          See more
        </TertiaryButton>
      )}
    </PostTemplate>
  )
}
