// eslint-disable-next-line no-restricted-imports
import { MantineTheme } from '@mantine/core'
import {
  NotificationProps as MantineNotificationProps,
  showNotification as mantineShowNotification,
} from '@mantine/notifications'

type NotificationProps = MantineNotificationProps & {
  variant: NotificationVariant
  soundUrl?: string
}

export type NotificationVariant = 'success' | 'error' | 'warning' | 'neutral'

const getVariantColor = (variant: NotificationVariant, theme: MantineTheme) => {
  switch (variant) {
    case 'success':
      return theme.other.colors.success[0]
    case 'error':
      return theme.other.colors.error[0]
    case 'warning':
      return theme.other.colors.warning[0]
    case 'neutral':
      return theme.other.colors.actions[0]
  }
}

const ACTIVE_TIME_MS = 5000

export const showNotification = ({
  id = '',
  title,
  message,
  autoClose = ACTIVE_TIME_MS,
  variant,
  soundUrl,
}: NotificationProps) => {
  mantineShowNotification({
    id,
    title,
    message,
    autoClose,
    py: '10px',
    px: '14px',
    styles: theme => ({
      root: {
        '::before': {
          backgroundColor: getVariantColor(variant, theme),
          left: 0,
          top: 0,
          bottom: 0,
          borderRadius: 0,
        },
        borderColor: theme.other.colors.background[3],
        borderRadius: `calc(${theme.other.sizes.border.md} * 4)`,
        borderWidth: theme.other.sizes.border.md,
      },
      title: {
        color: theme.other.colors.text[0],
        fontSize: theme.fontSizes.md,
      },
      description: {
        color: theme.other.colors.text[1],
        fontSize: theme.fontSizes.md,
      },
      closeButton: {
        color: theme.other.colors.text[0],
        border: theme.other.sizes.border.md,
        position: 'absolute',
        top: 8,
        right: 8,
      },
    }),
  })

  if (soundUrl) {
    const alertSound = new Audio(soundUrl)
    alertSound.muted = false
    void alertSound.play()
  }
}

export { hideNotification } from '@mantine/notifications'
