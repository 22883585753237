import {
  AddressData,
  AppointmentTypeString,
  CareTeamPod,
  DatabaseMetadata,
  PatientStatus,
  StateAbbr,
} from '..'
import { HourRanges, WorkingHours } from '../calendar'
import { VerifiableProviderTypeName, VerifiableState } from '../verifiable'

export type EmploymentStatus = 'employee' | 'contractor'
export type EmployeeStatus = 'currentEmployee' | 'formerEmployee'
export type PatientCounts = { [status in PatientStatus]: number }

/**
 * The pared down version of VerifiableProviderInfo that contains
 * only the data necessary for Ophelia clients. Expand as needed.
 */
type ProviderInfo = {
  npi: number | null
  nadea: string
  providerType: VerifiableProviderTypeName | null
  providerId: string
  /**
   * @deprecated - use `deaRegistrations` instead
   */
  deaRegistrationNumbers: string[]
  certificates: {
    type: string
    issueDate: string | null
    expirationDate: string | null
  }[]
  boardCertifications: {
    type: string
    nonVerifiedInfo: {
      initialCertificationDate: string
      expirationDate: string
    }
  }[]
  deaRegistrations: {
    registrationNumber: string
    results: {
      active: boolean | null
      expirationDate: string | null
    }
  }[]
  licenses: {
    isCurrentlyPracticing: boolean
    state: VerifiableState
    licenseNumber: string
    currentVerificationStatus: string
    currentVerifications: {
      issued: string
      expires: string
    }[]
  }[]
}

export type VerifiedClinician = Omit<Employee<'clinician'>, 'providerInfo'> &
  Required<Pick<Employee<'clinician'>, 'providerInfo'>>

export type EmployeeType = 'clinician' | 'care-coordinator' | 'basic' | 'consultation-call-queue'

export type EmployeeItem<T extends EmployeeType = EmployeeType> = T extends 'clinician'
  ? Omit<Employee<'clinician'>, 'providerInfo'> & { providerType: VerifiableProviderTypeName }
  : Employee<T>

export type Employee<T extends EmployeeType = EmployeeType> = DatabaseMetadata & EmployeeModel<T>

export type SupportedVisitType = {
  timezone: string
  /**
   * Additional settings to reserve certain appointment times
   */
  settings?: SupportedVisitTypeSettings
}

export type SupportedVisitTypeSettings = { [key in 'high-risk-patient']?: { schedule: HourRanges } }

export type EmployeeModel<T extends EmployeeType = EmployeeType> = {
  /**
   * Email used for employee registration.
   */
  email: string
  /**
   * Personal name for employee registration.
   */
  name: string
  /**
   * Profile photo URL.
   */
  profilePhotoURL?: string
  /**
   * Legal first name.
   */
  legalFirstName?: string
  /**
   * Legal last name.
   */
  legalLastName?: string
  /**
   * An id of the calendar that represents an Employee's availability in Acuity.
   */
  calendarId: number
  /**
   * Settings of an appointment type string
   */
  supportedVisitTypes?: Record<AppointmentTypeString, SupportedVisitType>
  /**
   * Date of birth.
   */
  dob: string
  /**
   * Is the employee full-time or contractor.
   */
  employmentStatus: EmploymentStatus
  /**
   * Rather than deleting an employee we use employment status to keep a history of Ophelia employees.
   */
  status: EmployeeStatus
  /**
   * Personal email of an employee.
   */
  personalEmail: string
  /**
   * Phone number.
   */
  phone: string
  /**
   * Start date of employment.
   */
  startDate: string
  /**
   * Indicates whether or not an employee is a psych consultant for case review. This needs to be on the generic employee base
   * for now because Dr. Bisaga is an "employee" role type without credentialing data in our system, but is a psych consultant.
   * Once that is no longer true and all our psych consultants are clinicians, this should be moved to Clinician type -3/16/2023
   */
  isPsychConsultant?: boolean
  /**
   * Main address that the employee works from
   */
  workAddress?: AddressData
  /**
   * spc - Supervising Clinician (etymology: "Supervising Prescribing Clinician")
   * pc - Prescribing Clinician
   * ncm - Clinical Care Manager (etymology: historically "Nurse Care Manager")
   * tn - Triage Nurse
   * ncm_tn - Clinical Care Manager and Triage Nurse. This is a special role for employees transitioning from TN to NCM, but still need both roles.
   */
} & (T extends 'clinician'
  ? {
      role: 'spc' | 'pc' | 'ncm' | 'tn' | 'ncm_tn'
      /**
       * Date of birth.
       */
      dob: string
      /**
       * An id that represents a vendor for prescribing.
       */
      doseSpotId: string
      /**
       * Number of patients that a clincian can prescribe to per month.
       * Legal requirements of how many patients a clinician can prescribe to per month.
       */
      prescribingCap: string
      /**
       * Number of patients that a clinician is prescribing to outside of Ophelia.
       */
      nonOpheliaPatients: string
      /**
       * Credentialing system verifiable information.
       */
      providerInfo?: ProviderInfo
      /**
       * Count for each patient status
       */
      patientCounts?: PatientCounts
      /**
       * Ability to turn off clinicians for being assigned new patients or being scheduled for followups
       * (New clinicians should have this as "false" unitl they're trained and ready to start seeing patients)
       */
      availableForScheduling?: 'open' | 'closed'
      /**
       * Allow TN to take checkin calls
       */
      availableForCheckins?: 'open' | 'closed'
      /**
       * Licensed states
       * Added here instead of in providerInfo because we need to be able to search by this field in the database.
       */
      licensedStates?: StateAbbr[]
      /**
       * The pod this care team member belongs to
       */
      pod?: CareTeamPod
      /**
       * The working hours for this clinician
       */
      workingHours?: WorkingHours
    }
  : T extends 'consultation-call-queue'
  ? {
      role: 'consultationCallQueue'
      /**
       * The working hours for the consultation call queue
       */
      workingHours?: WorkingHours
    }
  : T extends 'care-coordinator'
  ? {
      role: 'careCoordinator' | 'leadCareCoordinator'
      /**
       * The pod this care team member belongs to
       */
      pod?: CareTeamPod
    }
  : {
      role:
        | 'admin'
        | 'payorOps'
        | 'engineer'
        | 'legal'
        | 'employee'
        | 'student'
        | 'resourceNavigator'
        | 'financialCounselor'
        | 'enrollmentCoordinator'
        | 'leadEnrollmentCoordinator'
    })
