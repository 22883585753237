// eslint-disable-next-line no-restricted-imports
import {
  Box,
  FocusTrap,
  Group,
  Modal as MantineModal,
  ModalProps as MantineModalProps,
  Stack,
} from '@mantine/core'
import React, { PropsWithChildren, ReactNode } from 'react'
import { Divider } from './Divider'
import { TertiaryButton } from './buttons'
import { XIcon } from './icons'
import { TitleThree } from './typography'

export type ModalProps = Omit<
  MantineModalProps,
  'padding' | 'withCloseButton' | 'styles' | 'sx' | 'size' | 'centered'
> & {
  footer?: ReactNode
  onBack?: () => void
  size?: 'unset' | string
}

export type BetterModalProps = Omit<
  MantineModalProps,
  'padding' | 'withCloseButton' | 'styles' | 'sx' | 'size' | 'centered' | 'title'
> & {
  onBack?: () => void
  size?: 'unset' | string
}

export const Modal = ({
  children,
  title,
  footer,
  onClose,
  trapFocus = true,
  ...rest
}: ModalProps) => {
  return (
    <BetterModal onClose={onClose} {...rest}>
      {title && <ModalHeader onClose={onClose}>{title}</ModalHeader>}
      <ModalContent trapFocus={trapFocus}>{children}</ModalContent>
      {footer && <ModalFooter>{footer}</ModalFooter>}
    </BetterModal>
  )
}

export const BetterModal = ({ maw, ...props }: BetterModalProps) => {
  return (
    <MantineModal
      {...props}
      centered
      padding={0}
      withCloseButton={false}
      trapFocus={false}
      styles={({ other: { colors } }) => ({
        root: { '.mantine-Modal-inner': { padding: 0, bottom: '20%' } },
        body: { maxWidth: maw as string },
        content: { backgroundColor: colors.background[0] },
      })}
    />
  )
}

export const ModalHeader = ({ children, onClose }: PropsWithChildren<{ onClose: () => void }>) => {
  return (
    <Stack
      sx={({ other: { sizes } }) => ({
        paddingTop: sizes.gap.xl,
        paddingLeft: sizes.gap.xl,
        paddingRight: sizes.gap.xl,
        gap: sizes.gap.md,
      })}
    >
      <Group position='apart' align='flex-start' noWrap>
        <TitleThree>
          <Group align='flex-start'>{children}</Group>
        </TitleThree>
        <TertiaryButton size='sm' onClick={onClose} leftIcon={<XIcon />} />
      </Group>
      <Divider />
    </Stack>
  )
}

export const ModalContent = ({
  children,
  trapFocus = true,
}: PropsWithChildren<{ trapFocus?: boolean }>) => {
  return (
    <FocusTrap active={trapFocus}>
      <Box sx={({ other: { sizes } }) => ({ padding: sizes.gap.xl })}>{children}</Box>
    </FocusTrap>
  )
}

export const ModalFooter = ({ children }: PropsWithChildren) => {
  return (
    <Box
      sx={({ other: { colors, sizes } }) => {
        return {
          padding: sizes.gap.xl,
          backgroundColor: colors.background[1],
        }
      }}
    >
      {children}
    </Box>
  )
}
