import dayjs, { PluginFunc } from 'dayjs'

declare module 'dayjs' {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Dayjs {
    getBetween(date: dayjs.ConfigType, unit: 'days' | 'months' | 'hours' | 'years'): string[]
  }
}

/**
 * Gets the days or months between two dates (inclusive of both dates).
 */
const getBetween: PluginFunc<unknown> = (option, dayjsClass) => {
  dayjsClass.prototype.getBetween = function (
    date: dayjs.ConfigType,
    unit: 'days' | 'months' | 'hours' | 'years',
  ) {
    const startOfUnit = this.startOf(unit)
    const endOfUnit = dayjs(date).endOf(unit)
    const unitsBetween = endOfUnit.diff(startOfUnit, unit)

    const units = []
    for (let i = 0; i <= unitsBetween; i++) {
      units.push(getFormatted(startOfUnit.add(i, unit), unit))
    }

    return units
  }
}

const getFormatted = (date: dayjs.Dayjs, unit: 'days' | 'months' | 'hours' | 'years') => {
  switch (unit) {
    case 'days':
      return date.format('YYYY-MM-DD')
    case 'months':
      return date.format('YYYY-MM')
    case 'years':
      return date.format('YYYY')
    default:
      return date.toISOString()
  }
}

export default getBetween
