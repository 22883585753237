import {
  Group,
  MessageCircleIcon,
  PrimaryButton,
  Stack,
  TertiaryButton,
  Text,
  TitleTwo,
  UsersIcon,
  useLifecycle,
} from '@shared/components'
import { url } from '@shared/utils'
import range from 'lodash/range'
import { useQuery } from 'react-query'
import { CardMasonry } from './CardMasonry'
import { Footer } from './Footer'
import { OpheliaCommunity } from './OpheliaCommunity'
import { analytics } from './analytics'
import { bridgeApi } from './api'
import { Config } from './config'
import { Post } from './posts/Post'
import { PostLoading } from './posts/PostLoading'
import { routes } from './routes'

const FloatingChatboxLink = () => {
  return (
    <PrimaryButton
      component='a'
      target='_blank'
      href={
        url({
          base: Config.PORTAL_BASE_URL,
          pathname: 'welcome',
          searchParams: {
            utm_source: 'community',
            utm_medium: 'hub',
            utm_campaign: 'chatbox',
            // This param opens the chatbox on page load in the portal
            chatbox: 'true',
          },
        }).href
      }
      style={{
        position: 'fixed',
        bottom: 50,
        right: 25,
        borderRadius: '50%',
      }}
      leftIcon={<MessageCircleIcon />}
    />
  )
}

export const Landing = () => {
  const chatboxStatusQuery = useQuery(...bridgeApi.getQuery('GET /chatbox/status'))
  const isChatboxAvailable = chatboxStatusQuery.data?.isAvailable

  const postsQuery = useQuery(...bridgeApi.getQuery('GET /posts'))
  const postIds = postsQuery.data?.posts || []

  // We want to ensure visitors are seeing different posts so they all can be upvoted
  const shuffledPostIds = postIds.sort(() => Math.random() - 0.5)
  const posts = [...shuffledPostIds.map(postId => <Post key={postId} postId={postId} />)]

  useLifecycle({
    onMount: () => {
      analytics.page('Landing')
    },
  })

  return (
    <>
      <Stack p='lg' spacing='lg'>
        <Group position='apart'>
          <OpheliaCommunity />
          <TertiaryButton leftIcon={<UsersIcon />} component='a' href={routes.join.index}>
            Join community
          </TertiaryButton>
        </Group>
        <Stack spacing='xs'>
          <TitleTwo>Welcome to the Ophelia Community's Hub of Happiness</TitleTwo>
          <Text>
            Whether you’re coping with opioid addiction yourself or supporting a loved one, get
            inspiration from people who are finding happiness again through treatment.
          </Text>
        </Stack>
        <Stack>
          <CardMasonry>
            {postIds.length > 0 ? posts : range(0, 7).map(key => <PostLoading key={key} />)}
          </CardMasonry>
        </Stack>
      </Stack>
      <Footer />
      {isChatboxAvailable && <FloatingChatboxLink />}
    </>
  )
}
