// eslint-disable-next-line no-restricted-imports
import { Stack } from '@mantine/core'
import React, { ReactNode } from 'react'
import { TertiaryButton } from '../buttons'
import { Dropzone, DropzoneProps } from '../Dropzone'
import { InputError, InputWrapper } from '../InputWrapper'
import { Text } from '../typography'

export type UploadProps = InputError &
  DropzoneProps & {
    primaryText: string
    subText: string
    label: ReactNode
    preview?: boolean
    'test-id'?: string | undefined
    ratio?: number
  }

export const Upload = React.forwardRef<HTMLDivElement, UploadProps>(
  (
    {
      primaryText,
      subText,
      label,
      disabled = false,
      warning,
      success,
      error,
      explanation,
      children,
      ...rest
    },
    ref,
  ) => {
    return (
      <InputWrapper
        label={label}
        error={error}
        warning={warning}
        success={success}
        disabled={disabled}
        explanation={explanation}
        test-id={rest['test-id']}
      >
        <Dropzone
          {...rest}
          ref={ref}
          disabled={disabled}
          error={error}
          warning={warning}
          success={success}
          preview={Boolean(children)}
          borderStyle={children ? 'solid' : 'dashed'}
        >
          {children ?? (
            <Stack sx={{ pointerEvents: 'none' }} align='center' justify='center' spacing='xs'>
              <TertiaryButton disabled={disabled}>{primaryText}</TertiaryButton>
              <Text size='xs'>{subText}</Text>
            </Stack>
          )}
        </Dropzone>
      </InputWrapper>
    )
  },
)

Upload.displayName = 'Upload'
