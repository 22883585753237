export type Day = 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday'

/**
 * To help identify when reading date/time types its intention
 */

// 2023-07-11
export type YYYYMMDD = `${number}-${number}-${number}`

// 2023-07-11T17:56:14Z
export type ISOString = `${number}-${number}-${number}T${number}:${number}:${number}.${number}Z`

// 07/11/2023
export type MMDDYYYY = `${number}/${number}/${number}`

// July 11, 2023, 5:56 PM
export type MMMMDoYYYYhmma = `${string} ${string}, ${number}, ${number}:${number} ${string}`
