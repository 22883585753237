// eslint-disable-next-line no-restricted-imports
import { useMantineTheme } from '@mantine/core'
import {
  RichTextEditor as MantineRichTextEditor,
  RichTextEditorProps as MantineRichTextEditorProps,
} from '@mantine/tiptap'
import Link from '@tiptap/extension-link'
import Mention from '@tiptap/extension-mention'
import Placeholder from '@tiptap/extension-placeholder'
import { useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import React, { useEffect } from 'react'
import { InputError, InputWrapper } from '../../InputWrapper'
import { Skeleton } from '../../Skeleton'
import { suggestion } from './suggestion'

export type MentionData = {
  label: string
  id: string
}

export type RichTextEditorProps = Omit<MantineRichTextEditorProps, 'editor' | 'children'> & {
  value: string
  onChange?: (value: string) => void
  editable?: boolean
  mentionData?: MentionData[]
} & InputError & { label?: string }

export const RichTextEditor = ({
  value,
  onChange,
  editable = true,
  mentionData,
  error,
  warning,
  success,
  explanation,
  label,
  placeholder,
  ...props
}: RichTextEditorProps) => {
  const {
    other: { colors },
  } = useMantineTheme()

  // Only enable mentions if we've pre-loaded the results
  const extensions = [
    StarterKit,
    Placeholder.configure({
      placeholder: placeholder ?? '',
    }),
    Mention.configure({
      HTMLAttributes: {
        class: 'mention',
        style: 'font-family:Helvetica Now Text Bold',
      },
      suggestion: suggestion(mentionData ?? []),
    }),
    Link.configure({
      openOnClick: false,
      HTMLAttributes: {
        style: `color:${colors.text[0]};font-family:Helvetica Now Text Bold;cursor:pointer`,
      },
    }),
  ]

  const editor = useEditor(
    {
      editable,
      extensions,
      content: value,
      onUpdate: ({ editor }) => {
        onChange?.(editor.getHTML())
      },
    },
    [colors],
  )

  useEffect(() => {
    /*
     * Sadly, Mantine's RichTextEditor is not a controlled component
     * that works with useForm.
     *
     * This is probably the best way to allow this component's
     * controller clear out text, like after pressing save on a
     * message you've written in it for example.
     *
     * Context:
     * https://github.com/mantinedev/mantine/issues/1625
     */
    if (value === '') {
      editor?.commands.setContent(value)
    }
  }, [value, editor])

  if (!editor) {
    return <Skeleton />
  }

  return (
    <InputWrapper
      label={label}
      error={error}
      warning={warning}
      success={success}
      explanation={explanation}
    >
      <MantineRichTextEditor
        {...props}
        styles={{
          content: {
            wordBreak: 'break-word',
            // This div comes from tiptap and is unreachable from any props on this or the Content component
            '.ProseMirror': {
              /*
               * 0.5rem matches the TextArea padding, but when we're just
               * rendering content, we want no padding.
               */
              padding: editable ? '0.5rem' : 0,
            },
          },
          root: {
            overflow: editable ? 'scroll' : 'visible',
            // When we're rendering for display only, we can let the editor expand to show all content with no scroll
            maxHeight: editable ? '9em' : 'none',
            boxShadow: editable ? '0 0 0 0.094rem #A0ADD8' : 'none',
            border: 'none',
            padding: 0,
          },
        }}
        editor={editor}
      >
        <MantineRichTextEditor.Content bg='none' sx={{ color: colors.text[0] }} />
      </MantineRichTextEditor>
    </InputWrapper>
  )
}
