// eslint-disable-next-line no-restricted-imports
import { Select as MantineSelect, SelectProps as MantineSelectProps } from '@mantine/core'
import React from 'react'
import { getAttributes, useInputThemeStyles } from './inputs'
import { InputError } from './InputWrapper'

export type SelectProps = Omit<
  MantineSelectProps,
  'error' | 'clearButtonLabel' | 'variant' | 'description'
> &
  InputError & {
    explanation?: string
    // Useful in cases where select is treated as a search input
    hideChevron?: boolean
  }

export const Select = ({
  error,
  warning,
  success,
  disabled = false,
  explanation,
  hideChevron = false,
  ...props
}: SelectProps) => {
  const {
    iconColor,
    selectedFontColor,
    clearButtonStyle,
    hoverBackgroundColor,
    styles,
    inputStyles,
    theme: {
      other: { sizes, colors, fontFamilies },
      fontSizes,
    },
  } = useInputThemeStyles()

  return (
    <MantineSelect
      {...props}
      wrapperProps={getAttributes({
        disabled,
        ['data-error']: Boolean(error),
        ['data-warning']: Boolean(warning),
        ['data-success']: Boolean(success),
      })}
      error={error ?? success ?? warning ?? explanation}
      disabled={disabled}
      withinPortal
      styles={{
        rightSection: {
          width: 'unset',
          marginRight: sizes.padding.md,
          '& svg': { width: sizes.icon.md, height: sizes.icon.md },
          // Modify the chevron icon, this matches feather's chevron down
          '> svg': {
            display: hideChevron ? 'none' : 'unset',
            stroke: iconColor,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeWidth: '1.25px',
            '> path': {
              d: 'path("M 3.75 5.625 L 7.5 9.375 L 11.25 5.625")',
              fill: 'unset',
            },
          },
          // Modify the clear button
          '> button': clearButtonStyle,
        },
        input: inputStyles,
        dropdown: {
          background: colors.background[0],
          borderWidth: sizes.border.md,
          // Hides the border when no items are shown
          borderColor: props.maxDropdownHeight === 0 ? 'transparent' : colors.actions[0],
        },
        itemsWrapper: { padding: 'unset' },
        item: {
          fontSize: fontSizes.md,
          lineHeight: 'normal',
          paddingTop: sizes.padding.md,
          paddingBottom: sizes.padding.md,
          paddingLeft: sizes.gap.md,
          paddingRight: sizes.gap.md,
          borderRadius: 'unset',
          '&[data-hovered]': { backgroundColor: hoverBackgroundColor },
          '&[data-selected]': {
            backgroundColor: colors.actions[0],
            color: selectedFontColor,
            fontFamily: fontFamilies.bold,
            '&[data-hovered]': {
              backgroundColor: colors.actions[1],
            },
          },
        },
        ...styles,
      }}
    />
  )
}
