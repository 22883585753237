import { Skeleton } from '@shared/components'
import { PostTemplate } from './PostTemplate'

export const PostLoading = () => (
  <PostTemplate>
    <Skeleton height={20} mb='sm' />
    <Skeleton height={20} mb='sm' />
    <Skeleton height={20} />
  </PostTemplate>
)
