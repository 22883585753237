import { Box } from '@shared/components'
import React from 'react'

type PostTemplate = {
  children: React.ReactNode
  footer?: React.ReactNode
}

export const PostTemplate = ({ children, footer }: PostTemplate) => (
  <Box
    sx={theme => ({
      borderColor: theme.other.colors.background[1],
      borderRadius: theme.other.sizes.border.md,
      borderWidth: theme.other.sizes.border.md,
      borderStyle: 'solid',
    })}
  >
    <Box p='md'>{children}</Box>
    {Boolean(footer) && (
      <Box
        p='sm'
        sx={theme => ({
          background: theme.other.colors.background[1],
        })}
      >
        {footer}
      </Box>
    )}
  </Box>
)
