import { AcuityTimeSlot } from '../acuity'
import { AppointmentTypeString, PasswordType } from '..'

export type Availability = {
  time: TimeOfDay
  weekday: DayOfWeek
}

export const TimeOfDayToHourRanges: Record<TimeOfDay, number[]> = {
  // eslint-disable-next-line no-magic-numbers
  morning: [8, 9, 10, 11],
  // eslint-disable-next-line no-magic-numbers
  afternoon: [12, 13, 14, 15, 16, 17],
  // eslint-disable-next-line no-magic-numbers
  evening: [18, 19, 20, 21],
}

export type TimeOfDay = 'morning' | 'afternoon' | 'evening'
export const DAYS = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
] as const

export type DayOfWeek = (typeof DAYS)[number]

export type StandbyListNotificationSettings = {
  /**
   * optIn: indicates that a patient has confirmed they want
   * standbyList notifications
   */
  optIn?: boolean
  /**
   * availabilities: if null, the user has no time
   * preferences, so any new slot will trigger a notification
   */
  availabilities: Availability[] | null
  /**
   * previousAvailableSlots: an array of ISO timestamps
   */
  previousAvailableSlots?: AcuityTimeSlot[]
}

export const STANDBY_APPOINTMENT_FIELD: Partial<
  Record<AppointmentTypeString, keyof AccountSettings['notifications']['standbyList']>
> = {
  'Initial Visit': 'initialVisit',
}

export type AccountSettings = {
  notifications: {
    standbyList: Record<'initialVisit', StandbyListNotificationSettings>
  }
}

export type AccountData = {
  createdAt: string
  emailConfirmed?: boolean
  emailConfirmedAt?: string
  password?: string | null
  passwordType?: PasswordType
  phoneConfirmed?: boolean
  settings?: AccountSettings
  timezone?: string
}

export enum OptIn {
  Yes = 'yes',
  No = 'no',
}
