import { CallInstance } from 'twilio/lib/rest/api/v2010/account/call'
import { RecordingInstance } from 'twilio/lib/rest/api/v2010/account/recording'
import { MessageInstance } from 'twilio/lib/rest/conversations/v1/conversation/message'
import { ParticipantConversationInstance } from 'twilio/lib/rest/conversations/v1/participantConversation'
import { TaskInstance } from 'twilio/lib/rest/taskrouter/v1/workspace/task'
import { ReservationInstance } from 'twilio/lib/rest/taskrouter/v1/workspace/task/reservation'
import { ZodIssue } from 'zod'
import {
  AcuityAppointmentType,
  AcuityBlock,
  AggregateInvoiceInfo,
  Appointment,
  AppointmentMetadata,
  AppointmentStatus,
  AppointmentTypeString,
  AppointmentWithNoteDetails,
  AsqFormResponse,
  Barc10FormResponse,
  COCMRegistryItemModel,
  CarePathwayModel,
  CarePathwayOption,
  CaseReviewNote,
  CocmTimeTrackingModel,
  Configs,
  ConfigsModel,
  Consent,
  Consents,
  DatabaseMetadata,
  DecoratedCaseReviewItem,
  DecoratedCocmItem,
  DecoratedCocmTimeTracking,
  DecoratedObjectiveMeasureResponse,
  DecoratedProblem,
  DeepPartial,
  DischargeNoteModel,
  DispenseUnitId,
  DoseSpotDrugCoverageResponse,
  DoseSpotEligibilitiesResponse,
  DoseSpotIdResponse,
  DoseSpotMedication,
  DoseSpotMedicationHistoryResponse,
  DoseSpotPharmacy,
  DoseSpotPrescription,
  DoseSpotPrescriptionBenefitsResponse,
  DoseSpotPrescriptionLogEntry,
  DoseSpotRefillRequest,
  DrugScreen,
  DrugScreenModel,
  DrugScreenResults,
  EMRNotificationResponse,
  EMRTask,
  EMRTaskCounts,
  EMRTaskDecorated,
  EMRTaskModel,
  EMRTaskNote,
  EMRTaskNoteDecorated,
  EMRTaskPod,
  EMRTaskStatus,
  EMRTaskTimeEntryModel,
  Employee,
  EmployeeModel,
  Encounter,
  EncounterStatuses,
  FileCategory,
  FormData,
  FormRequestAnswer,
  FormattedStripeSubscription,
  FullWorkflowState,
  Gad7FormResponse,
  HourRange,
  ICD10Code,
  ICD10CodeType,
  ISOString,
  IneligibleNoteModel,
  Insurance,
  InsuranceModel,
  InsuranceQueue,
  InsuranceReviewData,
  InsuranceReviewQueueItem,
  InsuranceType,
  InvoiceListItem,
  LevelOfCareStatus,
  MMDDYYYY,
  MentalHealthEvaluation,
  NewEMRTaskBody,
  NonVisitEvent,
  NonVisitEventModel,
  Notification,
  OcpOverrideModel,
  OcpRecurringModel,
  OpheliaRoute,
  PaginatedListResponse,
  Patient,
  PatientFile,
  PatientFileCategoryDetails,
  PatientHistoricalChange,
  PatientHistoricalChangeModel,
  PatientOperation,
  PatientTaskRequiredFor,
  PatientTaskType,
  PaymentPlan,
  PaymentPlanFrequency,
  Phq8FormResponse,
  Phq9FormResponse,
  PrescriptionCancelationReason,
  PrescriptionCustomStatus,
  PrescriptionFavorite,
  PrescriptionFavoriteModel,
  PrescriptionIdAndSchedule,
  PrescriptionModel,
  PrimaryInsurancePlanType,
  PriorAuthorization,
  PriorAuthorizationModel,
  ProblemListProblem,
  PromptResponsePayload,
  ReengagementNoteModel,
  ReferralPartner,
  SegmentEvent,
  Shipment,
  ShippingPartner,
  StateName,
  StripePayment,
  StripePaymentMethod,
  SupportRequest,
  SupportRequestModel,
  TaskType,
  VerifiablePayerPlan,
  VisitIdAndLength,
  VisitNote,
  VisitNoteAddendum,
  VisitNoteType,
  WellnessCheckWorkflowType,
  WorkflowSession,
  WorkflowType,
  YYYYMMDD,
  ZoomWebhookEvent,
  patientStatuses,
  stateAbbreviations,
} from '..'
import { BigQueryRowTypes } from '../bigquery/queries'
import { MentalHealthEvaluationSaveRequest } from '../mentalHealthEvaluation'

export type PatientStatus = (typeof patientStatuses)[number]

export type GetPatientsWithQuery = Patient

export type Form = DatabaseMetadata & FormData

export type AppointmentTypes = {
  BASE_URL: string
  CHECK_IN_CALL_VISIT_ID: number
  CONSULTATION_QUEUE_CALENDAR_ID: number
  CONSULTATION_VISIT_ID: number
  UDS_VISIT_ID: number
  SLOT_ID: number
  ELIGIBLE: string
  FOLLOWUP_VISIT_ID: number
  FOLLOWUP_VISIT_URL: string
  INELIGIBLE: string
  IN_REVIEW: string
  ORIGIN_GS_V23: string
  followUpTypes: VisitIdAndLength[]
  bhcmVisitTypes: VisitIdAndLength[]
  INITIAL_WELLNESS_VISIT_ID: number
  PEER_COACHING_FOLLOWUP_VISIT_ID: number
  PEER_COACHING_INITIAL_VISIT_ID: number
  ENROLLMENT_SUPPORT_CALL_VISIT_ID: number
  SUPPORT_CALL_ID: number
  initialVisitTypes: Record<string, number>
  states: Record<string, string>
  stateNameToAbbr: Record<string, string>
  DOUBLE_BOOKING_FOLLOWUP_VISIT_ID: number
  STAGGERING_FOLLOWUP_VISIT_ID: number
}

export type UpdateAppointmentByVisitIdData = Appointment

export type OcpClinician = Pick<Employee<'clinician'>, 'name' | 'oid'> & {
  licensedStates: StateAbbr[]
  nadea: string | null
}

export type AddressData = {
  name?: string
  address?: string
  city?: string
  state?: string
  zip?: string
  aptSuite?: string
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type VisitNoteContent = Record<string, any>

export type AddPrescriptionFavoriteData = PrescriptionFavoriteModel

export type GetPrescriptionFavoritesData = PrescriptionFavorite

export type ShipmentResult = {
  id: string
  timestamp: string
  public_tracking_url: string
  provider: string
  rationale: string
  shipped: boolean
  label_url: string
  shipping_partner: ShippingPartner
  canceled?: boolean
  failed?: boolean
}

export type ShipmentRationale =
  | 'Transition to next level of care'
  | 'Unsuccessful delivery'
  | 'Lost or damaged shipment'
  | 'Suspected aberrant behavior'
  | 'Quarterly test'
  | 'Clinical necessity'
  | 'Transition to new PC'

export type CreateNonVisitEventForPatientData = NonVisitEventModel

export type GetNonVisitEventsForPatient = NonVisitEvent

export type PrescriptionResponse = PrescriptionModel &
  Partial<DatabaseMetadata> & {
    status?: PrescriptionCustomStatus
    medication_dose_form?: string
    medication_route?: string
    directions?: string
    refills?: string
    substitutionAllowed?: boolean
    pharmacyNotes?: string
    writtenDate?: string
    pharmacyId?: number
    dispense_unit_id?: DispenseUnitId
  }

export type RefillRequestSummary = {
  patient: Patient
  employee: Employee<'clinician'>
  request: DoseSpotRefillRequest
}

export type EligibleCCM = {
  employee: Employee<'clinician'>
  panelSize: number
  operatingStates: StateAbbr[]
  availableForScheduling?: 'open' | 'closed'
}

export type FormType = 'brief-addiction-monitor' | 'screening' | 'intake' | 'waitlist' | 'referral'

export type FormSubmission = {
  answers?: FormRequestAnswer[]
  finalized?: boolean
  responder_uuid?: string
  timestamp?: string
  variant_label?: string
  variant_uuid?: string
  phone?: string
  state?: string | undefined
  additional?: string
  source?: string
}

export type GetDrugScreensForPatientData = DrugScreen

export type CreateDrugScreenForPatientData = DrugScreenModel

export type GetPriorAuthorizationsForPatientData = PriorAuthorization

export type CreatePriorAuthorizationForPatientData = PriorAuthorizationModel

export type PrescriptionRequest = {
  patientId: string
  visitId?: number
  caseReviewNoteId?: string
  medication_name: string
  medication_strength: string
  medication_refills: number
  medication_quantity: number
  medication_days_supply?: number
  directions: string
  notes: string
  substitutionAllowed: boolean
  pharmacyId: number
  pinCode?: string
  twoFacCode?: string
  bridge: boolean
  queuedForId?: string
  type: 'queuing' | 'prescribing'
  effective_date: string
  full_medication?: DoseSpotMedication
  employeeAddress?: string
}

export type SendPrescriptionData = {
  doseSpotId: string
  patientDoseSpotId: number
  prescriptionId: number
  pinCode?: string | undefined
  twoFacCode?: string | undefined
  schedule: string
  queued: boolean
  employeeAddress?: string | undefined
}

export type SendPrescriptionsData = {
  doseSpotId: string
  patientDoseSpotId: number
  prescriptions: PrescriptionIdAndSchedule[]
  pinCode?: string
  twoFacCode?: string | undefined
  queued: boolean
}

export type CancelPrescriptionData = PrescriptionCancelationReason

export type GetNotificationsForEmployeeData = Notification

export type TaskPrescriptionData = {
  medication_days_supply: string
  medication_name: string
  medication_quantity: string
  medication_strength: string
  prescription_sent_key: string | null
  prescription_patient_key: string | null
  prescription_queued_key: string | null
  prescription_primary_key: string | null
  prescription_task_notes: string | null
  prescription_task_sent: boolean | null
  reason_for_request: string
}

// patient.insuranceData.patientProvided
export type InsuranceData = {
  provider: string
  memberId: string
  medicaidMemberId?: string
  groupId?: string
  rxbin?: string
  rxPcn?: string
  rxGroup?: string
  firstName: string
  lastName: string
  cardholderBirthday?: string
  planType?: keyof typeof PrimaryInsurancePlanType | null
  planName?: string
  eligiblePayerId?: string
  /** @deprecated use patient.insuranceData.hasInsurance */
  hasInsurance?: boolean
  hasSecondaryInsurance?: boolean
  patientIsPrimarySubscriber?: boolean
  primarySubscriberFirstName?: string
  primarySubscriberLastName?: string
  primarySubscriberBirthday?: string
  medicaidInfo?: {
    memberId?: string
    provider?: string
    payerId?: string
  }
  excludeFromBundle?: boolean
  /** legacy field, not set in new patient insurance collection */
  insuranceCardFrontPhotoUrl?: string | null
  /** legacy field, not set in new patient insurance collection */
  insuranceCardBackPhotoUrl?: string | null
}

export type GetFilesData = PatientFile

export type GetEncountersReq = {
  page: string
  per: string
  filter: string
  order?: OrderByDirection
  patientId?: string
  startAfter?: string
  payerId: string
}

export type OrderByDirection = 'desc' | 'asc'

export type EncounterWithErrors = Encounter & { errors: ZodIssue[] }

export type UpdateEncounterReq = {
  encounter: Partial<Encounter>
  id: string
}

export type ListVerifiablePayerPlansData = VerifiablePayerPlan

export type EligibleInsuranceData = {
  created_at?: string
  eligible_id?: string
  insurance: {
    id: string
    name: string
  }
  plan: {
    plan_name: string
    coverage_status_label: string
    coverage_status: string
    dates?: { date_value: string; date_type: string; date_source: string }[]
    plan_type?: keyof typeof PrimaryInsurancePlanType
  }
  demographics?: {
    dependent: {
      relationship_code?: string
    }
    eligible_id: string
    subscriber: {
      first_name?: string
      last_name?: string
      gender?: string
      address: {
        city?: string
        street_line_1?: string
        street_line_2?: string
        state?: string
        zip?: string
      }
      dob?: string
    }
  }
}

export type SupportRequestWithEmployeeName = SupportRequest & { employeeName?: string }

export type CreateSupportRequestData = SupportRequestModel

export type CreateDischargeNoteData = DischargeNoteModel
export type GetDischargesForUserData = DischargeNoteModel
export type CreateReengagementNoteData = ReengagementNoteModel
export type GetReengagementNotesForUserData = ReengagementNoteModel
export type CreateIneligibleNoteData = IneligibleNoteModel
export type GetIneligibleNotesForUserData = IneligibleNoteModel

export type GetConsentsData = Consents

export enum InsuranceQueueStatus {
  New = 'new',
  InNetwork = 'in_network',
  OutOfNetwork = 'out_of_network',
}

export type GetShipmentsQueueData = Shipment

export type OcpRecurring = OcpRecurringModel & DatabaseMetadata

export type StateAbbr = (typeof stateAbbreviations)[number]

export type OcpOverride = OcpOverrideModel & DatabaseMetadata

export type OnCallProvider = {
  employeeId: string
  employeeName: string
  nadea: string
}

export type AuthReassignmentDuplicateInfo = {
  duplicatesAreInTreatment?: boolean
  duplicateEmailAccountId?: string
  duplicatePhoneAccountId?: string
  duplicateEmailAccountName?: string
  duplicatePhoneAccountName?: string
}

export type AuthReassignmentInfo = AuthReassignmentDuplicateInfo & {
  newEmail?: string
  newPhone?: string
}

export type DecoratedPatient = Patient & {
  insuranceStatus?: 'verified' | 'unverified' | 'none'
}

type PatientTask = {
  isComplete: boolean
  isApplicable: boolean
  requiredFor: PatientTaskRequiredFor[]
  type: PatientTaskType
  title: string
}

type EmrTwilioApi = OpheliaRoute<
  'GET /patient/:patientId/twilio/conversations',
  {
    req: {
      params: {
        patientId: string
      }
    }
    res: ParticipantConversationInstance[]
  }
> &
  OpheliaRoute<
    'GET /patient/:patientId/twilio/conversations/:conversationId/messages',
    {
      req: {
        params: {
          patientId: string
          conversationId: string
        }
      }
      res: MessageInstance[]
    }
  > &
  OpheliaRoute<
    'GET /patient/:patientId/twilio/tasks',
    {
      req: {
        params: {
          patientId: string
        }
      }
      res: TaskInstance[]
    }
  > &
  OpheliaRoute<
    'DELETE /patient/:patientId/twilio/tasks/:taskId',
    {
      req: {
        params: {
          patientId: string
          taskId: string
        }
      }
      res: void
    }
  > &
  OpheliaRoute<
    'GET /patient/:patientId/twilio/tasks/:taskId/reservations',
    {
      req: {
        params: {
          patientId: string
          taskId: string
        }
      }
      res: ReservationInstance[]
    }
  > &
  OpheliaRoute<
    'GET /patient/:patientId/twilio/calls',
    {
      req: {
        params: {
          patientId: string
        }
      }
      res: CallInstance[]
    }
  > &
  OpheliaRoute<
    'GET /patient/:patientId/twilio/calls/:callId/recordings',
    {
      req: {
        params: {
          patientId: string
          callId: string
        }
      }
      res: RecordingInstance[]
    }
  > &
  OpheliaRoute<
    'PUT /patient/:patientId/twilio/conversations/:conversationId/close',
    {
      req: {
        params: {
          patientId: string
          conversationId: string
        }
      }
      res: void
    }
  > &
  OpheliaRoute<
    'POST /patient/:patientId/twilio/messages/send',
    {
      req: {
        params: {
          patientId: Patient['oid']
        }
        data: {
          phone: string
          message: string
        }
      }
      res: void
    }
  >

export type EmrApi = OpheliaRoute<
  'GET /patient/:patientId/priorAuthorizations',
  {
    req: {
      params: {
        patientId: string
      }
    }
    res: Record<string, PriorAuthorization>
  }
> &
  OpheliaRoute<
    'GET /patient/:patientId/validate-auth',
    {
      req: { params: { patientId: string }; query: { phone: string; email: string } }
      res: {
        phone?: { owner?: string; isOwner: boolean }
        email?: { owner?: string; isOwner: boolean }
      }
    }
  > &
  OpheliaRoute<
    'GET /patient/:patientId',
    {
      req: {
        params: {
          patientId: string
        }
      }
      res: Patient
    }
  > &
  OpheliaRoute<
    'GET /patient/:patientId/identification-images',
    {
      req: {
        params: {
          patientId: string
        }
      }
      res: {
        face: string
        frontIdentification: string
        backIdentification: string
      }
    }
  > &
  OpheliaRoute<
    'GET /patients/:patientId/phq9Responses',
    {
      req: {
        params: {
          patientId: string
        }
        query: {
          startAfter: string
        }
      }
      res: DecoratedObjectiveMeasureResponse<Phq9FormResponse>[]
    }
  > &
  OpheliaRoute<
    'GET /patients/:patientId/phq8Responses',
    {
      req: {
        params: {
          patientId: string
        }
        query: {
          startAfter: string
        }
      }
      res: DecoratedObjectiveMeasureResponse<Phq8FormResponse>[]
    }
  > &
  OpheliaRoute<
    'GET /patients/:patientId/gad7Responses',
    {
      req: {
        params: {
          patientId: string
        }
        query: {
          startAfter: string
        }
      }
      res: DecoratedObjectiveMeasureResponse<Gad7FormResponse>[]
    }
  > &
  OpheliaRoute<
    'GET /patient/:patientId/has-attended-initial-bhcm-visit',
    {
      req: {
        params: {
          patientId: string
        }
        query: {
          includeScheduledVisits?: 'yes' | 'no'
        }
      }
      res: boolean
    }
  > &
  OpheliaRoute<
    'GET /patients/:patientId/barc10Responses',
    {
      req: {
        params: {
          patientId: string
        }
        query: {
          startAfter: string
        }
      }
      res: DecoratedObjectiveMeasureResponse<Barc10FormResponse>[]
    }
  > &
  OpheliaRoute<
    'GET /patients/:patientId/asqResponses',
    {
      req: {
        params: {
          patientId: string
        }
        query: {
          startAfter: string
        }
      }
      res: DecoratedObjectiveMeasureResponse<AsqFormResponse>[]
    }
  > &
  OpheliaRoute<
    'GET /patient/:patientId/appointments',
    {
      req: {
        params: {
          patientId: string
        }
        query: {
          calendarId?: `${number}`
          canceled?: 'yes' | 'no'
          dateOrder?: 'asc' | 'desc'
          start?: ISOString
          end?: ISOString
          types?: AppointmentTypeString[]
        }
      }
      res: (Appointment & {
        status: AppointmentStatus
        isDischargeVisit: boolean
      })[]
    }
  > &
  OpheliaRoute<
    'GET /patient/:patientId/nonVisitEvents',
    {
      req: { params: { patientId: string } }
      res: (NonVisitEvent & { author: { name: string } })[]
    }
  > &
  OpheliaRoute<
    'POST /care-pathways',
    {
      req: {
        data: CarePathwayModel
      }
      res: void
    }
  > &
  OpheliaRoute<
    'GET /care-pathways/next',
    {
      req: {
        query: {
          patientId: Patient['oid']
        }
      }
      res: {
        nextLevelOfCare: LevelOfCareStatus
        prevCarePathway: CarePathwayOption
        currCarePathway: CarePathwayOption
        nextCarePathway: CarePathwayOption
        // This will be populated when a clinician has selected a care pathway for the most recent visit
        selectedCarePathway: CarePathwayOption | null
        nextClinicianId: Employee<'clinician'>['oid'] | null
        nextClinicianCalendarId: Employee<'clinician'>['calendarId'] | null
        nextClinicianExplanation:
          | 'Induction pathway - PC visit'
          | 'Intermediate pathway - PC visit'
          | 'Stable pathway - PC visit'
          | 'Stable pathway - CCM visit'
          | 'No assigned CCM'
          | 'Custom pathway'
          | null
        visits: {
          oid: Appointment['oid']
          datetime: ISOString
          type: Appointment['type']
        }[]
      } | null
    }
  > &
  OpheliaRoute<
    'GET /patient/:patientId/days-since-last-visit/:employeeId',
    {
      req: {
        params: {
          patientId: string
          employeeId: string
        }
      }
      res: number | null
    }
  > &
  OpheliaRoute<
    'GET /patient/:patientId/issues',
    {
      req: {
        params: {
          patientId: string
        }
      }
      res: (EMRTask & {
        wrapUpNote?: EMRTaskNote<'note_added'> | undefined
      })[]
    }
  > &
  OpheliaRoute<
    'POST /ocp/override',
    {
      req: { data: OcpOverrideModel }
      res: void
    }
  > &
  OpheliaRoute<
    'POST /ocp/recurring',
    {
      req: { data: OcpRecurringModel }
      res: void
    }
  > &
  OpheliaRoute<
    'GET /ocp/provider/:state',
    {
      req: {
        params: {
          state: StateAbbr
        }
      }
      res: OnCallProvider | null
    }
  > &
  OpheliaRoute<
    'GET /ocp/override',
    {
      req: {
        query: {
          date?: string
          state?: StateAbbr
        }
      }
      res: OcpOverride[]
    }
  > &
  OpheliaRoute<
    'POST /workflow/:type',
    {
      req: {
        params: {
          type: WorkflowType
        }
        data: {
          patientId: string
        }
      }
      res: FullWorkflowState
    }
  > &
  OpheliaRoute<
    'GET /workflow/session/:sessionId',
    {
      req: {
        params: {
          sessionId: string
        }
        query: { patientId: string }
      }
      res: FullWorkflowState
    }
  > &
  OpheliaRoute<
    'POST /workflow/session/:sessionId',
    {
      req: {
        params: { sessionId: string }
        data: {
          responses: {
            promptId: string
            promptResponse: PromptResponsePayload
          }[]
          markComplete: boolean
          patientId: string
          createdAt?: string
        }
      }
      res: void
    }
  > &
  OpheliaRoute<
    'GET /workflow/session/:sessionId/info',
    {
      req: {
        params: { sessionId: string }
      }
      res: WorkflowSession
    }
  > &
  OpheliaRoute<
    'GET /patients',
    {
      req: {
        query: {
          q?: string
          employeeId?: string
          patientStatus?: PatientStatus[]
          orderBy?: 'name'
          state?: string
          limit?: `${number}`
          nextPageToken?: string
          prevPageToken?: string
          withInsuranceStatus?: 'yes' | 'no'
        }
      }
      res: PaginatedListResponse<DecoratedPatient>
    }
  > &
  OpheliaRoute<
    'POST /patient/:patientId/shipments',
    {
      req: {
        params: { patientId: string }
        data: {
          provider: string
          forceSignature: boolean
          rationale: ShipmentRationale
          shippingAddress?: AddressData
        }
      }
      res: Response
    }
  > &
  OpheliaRoute<
    'DELETE /shipments/:shipmentId',
    {
      req: {
        params: { shipmentId: string }
      }
      res: Response
    }
  > &
  OpheliaRoute<
    'POST /cocmRegistry',
    {
      req: {
        data: {
          patientId: string
        }
      }
      res: void
    }
  > &
  OpheliaRoute<
    'GET /cocmRegistry',
    {
      req: {
        query: {
          limit?: string
          endBefore?: string
          startAfter?: string
          search?: string
          employeeId?: string
          needsReview?: string
        }
      }
      res: DecoratedCocmItem[]
    }
  > &
  OpheliaRoute<
    'GET /cocmRegistry/patient/:patientId',
    {
      req: {
        params: { patientId: string }
        query: { includeDeleted?: 'yes' | 'no' }
      }
      res: DecoratedCocmItem
    }
  > &
  OpheliaRoute<
    'PUT /cocmRegistry/:patientId',
    {
      req: {
        params: { patientId: string }
        data: Partial<COCMRegistryItemModel>
      }
      res: void
    }
  > &
  OpheliaRoute<
    'DELETE /cocmRegistry/:id',
    {
      req: {
        params: { id: string }
        data: { patientId: string }
      }
      res: void
    }
  > &
  OpheliaRoute<
    'POST /timeTracking',
    {
      req: {
        data: Pick<CocmTimeTrackingModel, 'date' | 'totalMinutes' | 'patientId' | 'activityType'>
      }
      res: void
    }
  > &
  OpheliaRoute<
    'PUT /timeTracking/:id',
    {
      req: {
        params: { id: string }
        data: Pick<CocmTimeTrackingModel, 'date' | 'totalMinutes' | 'activityType'>
      }
      res: void
    }
  > &
  OpheliaRoute<
    'DELETE /timeTracking/:id',
    {
      req: {
        params: { id: string }
      }
      res: void
    }
  > &
  OpheliaRoute<
    'GET /patient/:patientId/timeTracking',
    {
      req: {
        params: { patientId: string }
        query: { date: string }
      }
      res: DecoratedCocmTimeTracking[]
    }
  > &
  OpheliaRoute<
    'POST /patient',
    {
      req: { data: { firstName: string; lastName: string; phone: string; state: StateName } }
      res: { id: string }
    }
  > &
  OpheliaRoute<
    'GET /patient/:patientId/historicalChanges',
    {
      req: {
        params: { patientId: string }
        query: { property?: PatientHistoricalChangeModel['property'] }
      }
      res: PatientHistoricalChange[]
    }
  > &
  OpheliaRoute<
    'GET /patient/:patientId/events',
    {
      req: { params: { patientId: string } }
      res: SegmentEvent[]
    }
  > &
  OpheliaRoute<
    'GET /caseReviewNote/patient/:patientId',
    {
      req: {
        params: { patientId: string }
        query: { limit?: `${number}`; isLocked?: `${boolean}`; isAttested?: `${boolean}` }
      }
      res: CaseReviewNote[]
    }
  > &
  OpheliaRoute<
    'GET /caseReview',
    {
      req: { query: { employeeId?: string; needsReview?: string } }
      res: DecoratedCaseReviewItem[]
    }
  > &
  OpheliaRoute<
    'GET /caseReview/:patientId',
    {
      req: { params: { patientId: string } }
      res: DecoratedCaseReviewItem
    }
  > &
  OpheliaRoute<
    'GET /insurance/freeformData/:patientId',
    { req: { params: { patientId: string } }; res: string }
  > &
  OpheliaRoute<
    'PUT /insurance/:patientId/update',
    {
      req: {
        params: { patientId: string }
        data: DeepPartial<{
          insuranceQueueData?: InsuranceReviewData | null
          freeformData?: string
          hasInsurance?: boolean
          hasSecondaryInsurance?: boolean
        }>
      }
      res: void
    }
  > &
  OpheliaRoute<
    'PUT /patient/:patientId/insurance/update',
    {
      req: {
        params: { patientId: string }
        data: {
          provider?: string
          memberId?: string
          groupId?: string
          rxbin?: string
          rxPcn?: string
          rxGroup?: string
          firstName?: string
          lastName?: string
          cardholderBirthday?: string
          planType?: keyof typeof PrimaryInsurancePlanType | null
          planName?: string
          eligiblePayerId?: string
          hasInsurance?: boolean
          hasSecondaryInsurance?: boolean
          patientIsPrimarySubscriber?: boolean
          primarySubscriberFirstName?: string
          primarySubscriberLastName?: string
          primarySubscriberBirthday?: string
          insuranceType: InsuranceType
          insuranceCardFrontId?: string
          insuranceCardBackId?: string
          excludeFromBundle?: boolean
          medicaidInfo?: {
            memberId?: string
            provider?: string
            payerId?: string
          }
        }
      }
      res: void
    }
  > &
  OpheliaRoute<'PUT /encounters', { req: { data: UpdateEncounterReq }; res: void }> &
  OpheliaRoute<
    'GET /encounters/:encounterId',
    { req: { params: { encounterId: string } }; res: EncounterWithErrors }
  > &
  OpheliaRoute<
    'GET /patient/:patientId/operations',
    {
      req: { params: { patientId: string } }
      res: PatientOperation[]
    }
  > &
  OpheliaRoute<
    'PUT /patient-operations/:operationId/revert',
    {
      req: { params: { operationId: string } }
      res: void
    }
  > &
  OpheliaRoute<
    'GET /patient/:patientId/subcollections',
    {
      req: { params: { patientId: string } }
      res: string[]
    }
  > &
  OpheliaRoute<
    'GET /patient/:patientId/subcollections/:subcollection',
    {
      req: { params: { patientId: string; subcollection: string } }
      res: string[]
    }
  > &
  OpheliaRoute<
    'GET /patient/:patientId/subcollections/:subcollection/:documentId',
    {
      req: { params: { patientId: string; subcollection: string; documentId: string } }
      res: object
    }
  > &
  OpheliaRoute<
    'GET /patient/:patientId/collections',
    {
      req: { params: { patientId: string } }
      res: string[]
    }
  > &
  OpheliaRoute<
    'GET /patient/:patientId/collections/:collection',
    {
      req: { params: { patientId: string; collection: string } }
      res: string[]
    }
  > &
  OpheliaRoute<
    'GET /patient/:patientId/collections/:collection/:documentId',
    {
      req: { params: { patientId: string; collection: string; documentId: string } }
      res: object
    }
  > &
  OpheliaRoute<
    'DELETE /patient/:patientId/subcollections',
    {
      req: {
        params: { patientId: string }
        data: { subcollections: string[] }
      }
      res: void
    }
  > &
  OpheliaRoute<
    'DELETE /patient/:patientId/collections',
    {
      req: {
        params: { patientId: string }
        data: { collections: string[] }
      }
      res: void
    }
  > &
  OpheliaRoute<
    'DELETE /patient/:patientId/subcollections/:subcollection',
    {
      req: {
        params: { patientId: string; subcollection: string }
        data: { documentIds: string[] }
      }
      res: void
    }
  > &
  OpheliaRoute<
    'DELETE /patient/:patientId/collections/:collection',
    {
      req: {
        params: { patientId: string; collection: string }
        data: { documentIds: string[] }
      }
      res: void
    }
  > &
  OpheliaRoute<
    'PUT /patient/:patientId/subcollections/transfer',
    {
      req: {
        params: { patientId: string }
        data: { toPatientId: string; subcollections: string[] }
      }
      res: void
    }
  > &
  OpheliaRoute<
    'PUT /patient/:patientId/collections/transfer',
    {
      req: {
        params: { patientId: string }
        data: { toPatientId: string; collections: string[] }
      }
      res: void
    }
  > &
  OpheliaRoute<
    'PUT /patient/:patientId/subcollections/:subcollection/transfer',
    {
      req: {
        params: { patientId: string; subcollection: string }
        data: { toPatientId: string; documentIds: string[] }
      }
      res: void
    }
  > &
  OpheliaRoute<
    'PUT /patient/:patientId/collections/:collection/transfer',
    {
      req: {
        params: { patientId: string; collection: string }
        data: { toPatientId: string; documentIds: string[] }
      }
      res: void
    }
  > &
  OpheliaRoute<
    'POST /patient/:patientId/subcollections/:subcollection/:documentId',
    {
      req: {
        params: { patientId: string; subcollection: string; documentId: string }
        data: { before: object; after: object }
      }
      res: void
    }
  > &
  OpheliaRoute<
    'POST /patient/:patientId/collections/:collection/:documentId',
    {
      req: {
        params: { patientId: string; collection: string; documentId: string }
        data: { before: object; after: object }
      }
      res: void
    }
  > &
  OpheliaRoute<
    'GET /patient/:patientId/auth',
    {
      req: { params: { patientId: string } }
      res: { email?: string | undefined; phone?: string | undefined; exists: boolean }
    }
  > &
  OpheliaRoute<
    'PUT /patient/:patientId/remove-auth-phone',
    {
      req: { params: { patientId: string } }
      res: void
    }
  > &
  OpheliaRoute<
    'DELETE /patient/:patientId/auth',
    {
      req: { params: { patientId: string } }
      res: void
    }
  > &
  OpheliaRoute<
    'PUT /patient/:patientId/auth',
    {
      req: {
        params: { patientId: string }
        data: {
          phone?: string
          email?: string
          forceReassignment?: boolean
        }
      }
      res: {
        additionalInfo?: AuthReassignmentDuplicateInfo
      }
    }
  > &
  OpheliaRoute<
    'GET /insurance/eligibleData/:patientId',
    { req: { params: { patientId: string } }; res: EligibleInsuranceData }
  > &
  OpheliaRoute<
    'GET /encounters/count',
    { req: { query: { status: EncounterStatuses; payerId?: string } }; res: number }
  > &
  OpheliaRoute<
    'POST /candid',
    {
      req: {
        data: {
          encounterId: string
          reviewerId: string
          reviewedDate: string
          includeSecondaryInsurance?: boolean
        }
      }
      res: void
    }
  > &
  OpheliaRoute<
    'GET /tasks/v2',
    {
      req: {
        query: {
          status?: EMRTaskStatus
          pod?: EMRTaskPod
          patientId?: string
          remindAtBefore?: string
          employeeId?: string
          limit?: `${number}`
          startAfter?: string
          endBefore?: string
          orderByDirection?: 'asc' | 'desc'
          orderBy?: 'createdAt' | 'updatedAt' | 'remindAt' | 'priority' | 'details.scheduledFor'
          types?: TaskType[]
        }
      }
      res: EMRTaskDecorated[]
    }
  > &
  OpheliaRoute<
    'POST /tasks/v2',
    {
      req: {
        data: NewEMRTaskBody
      }
      res: { emrTaskId: string }
    }
  > &
  OpheliaRoute<
    'POST /tasks/v2/:taskId/batch',
    {
      req: {
        params: { taskId: string }
        data: {
          task?: Partial<Omit<EMRTaskModel, 'createdAt' | 'createdBy'>>
          note?: { text: string }
          results?: EMRTaskModel['results']
          timeEntry?: Omit<EMRTaskTimeEntryModel, 'employeeId' | 'createdAt'>
        }
      }
      res: void
    }
  > &
  OpheliaRoute<
    'GET /tasks/v2/:taskId/notes',
    { req: { params: { taskId: string } }; res: EMRTaskNoteDecorated[] }
  > &
  OpheliaRoute<
    'GET /tasks/v2/counts',
    {
      req: {
        query: {
          statuses: EMRTaskStatus[]
          pod?: EMRTaskPod
          employeeId?: string
          patientId?: string
          types?: TaskType[]
        }
      }
      res: EMRTaskCounts
    }
  > &
  OpheliaRoute<
    'GET /tasks/v2/:taskId/files',
    {
      req: {
        params: { taskId: string }
      }
      res: (PatientFile & { metadata: { employeeName: string } })[]
    }
  > &
  OpheliaRoute<
    'GET /tasks/v2/:taskId',
    {
      req: {
        params: { taskId: string }
      }
      res: EMRTaskDecorated
    }
  > &
  OpheliaRoute<
    'PUT /patient/:patientId',
    {
      req: {
        params: {
          patientId: string
        }
        data: DeepPartial<Patient>
      }
      res: void
    }
  > &
  OpheliaRoute<
    'GET /patient/:patientId/consents',
    {
      req: {
        params: { patientId: string }
      }
      res: Consent[]
    }
  > &
  OpheliaRoute<
    'GET /patient/:patientId/consents/:type/:version',
    {
      req: {
        params: { patientId: string; type: 'financial' | 'treatment'; version: string }
      }
      res: Consent | undefined
    }
  > &
  OpheliaRoute<
    'GET /patient/id',
    { req: { query: { phoneNumber?: string; email?: string } }; res: { patientId: string | null } }
  > &
  OpheliaRoute<
    'POST /employees',
    {
      req: {
        data: {
          name: EmployeeModel['name']
          email: EmployeeModel['email']
          role: EmployeeModel['role']
        }
      }
      res: void
    }
  > &
  OpheliaRoute<
    'GET /employees/list',
    {
      req: {
        query: {
          role?: Employee['role'] | Employee['role'][]
          status?: Employee['status']
          orderBy?: 'name'
          orderByDirection?: 'asc' | 'desc'
        }
      }
      res: Employee[]
    }
  > &
  OpheliaRoute<
    'GET /employee/:employeeId',
    {
      req: {
        params: {
          employeeId: string
        }
      }
      res: Employee
    }
  > &
  OpheliaRoute<
    'GET /employees/cocm-panel-size',
    {
      req: never
      res: { employee: Employee<'clinician'>; patientCount: number }[]
    }
  > &
  OpheliaRoute<
    'GET /patients/:patientId/appointment-counts',
    {
      req: {
        params: {
          patientId: string
        }
        query: {
          appointmentTypes?: AppointmentTypeString[]
          startDate?: ISOString
          endDate?: ISOString
        }
      }
      res: number
    }
  > &
  OpheliaRoute<
    'GET /patient/:patientId/files',
    {
      req: {
        params: { patientId: string }
        query:
          | {
              category?: undefined
              withUrl?: 'no' | undefined
            }
          | {
              category: FileCategory | 'PRIMARY_INSURANCE' | 'SECONDARY_INSURANCE'
              withUrl?: 'yes' | 'no'
            }
      }
      res: Record<string, PatientFile & { signedUrl?: string }>
    }
  > &
  OpheliaRoute<
    'GET /patient/:patientId/insurance/:insuranceId',
    { req: { params: { patientId: string; insuranceId: string } }; res: Insurance }
  > &
  OpheliaRoute<
    'GET /patient/:patientId/is-eligible-for-cocm',
    {
      req: {
        params: {
          patientId: string
        }
      }
      res: boolean
    }
  > &
  OpheliaRoute<
    'PUT /patient/:patientId/insurance/:insuranceId',
    {
      req: {
        data: DeepPartial<InsuranceModel>
        params: {
          patientId: string
          insuranceId: string
        }
      }
      res: void
    }
  > &
  OpheliaRoute<
    'GET /patient/:patientId/insurances',
    { req: { params: { patientId: string } }; res: Insurance[] }
  > &
  OpheliaRoute<
    'GET /patient/:patientId/file/write',
    { req: { params: { patientId: string } }; res: { fileId: string; signedUrl: string } }
  > &
  OpheliaRoute<
    'DELETE /patient/:patientId/tempFile/:fileId',
    { req: { params: { patientId: string; fileId: string } }; res: void }
  > &
  OpheliaRoute<
    'POST /patient/:patientId/files/:fileId',
    {
      req: {
        params: { patientId: string; fileId: string }
        data: PatientFileCategoryDetails & { name?: string }
      }
      res: void
    }
  > &
  OpheliaRoute<
    'GET /patient/:patientId/shipments',
    { req: { params: { patientId: string } }; res: ShipmentResult[] }
  > &
  OpheliaRoute<
    'GET /patient/:patientId/drugScreens',
    { req: { params: { patientId: string } }; res: DrugScreen[] }
  > &
  OpheliaRoute<
    'POST /patient/:patientId/drugScreens/create',
    {
      req: {
        params: { patientId: string }
        data: {
          authorId: string
          administeredAt: string
          results: DrugScreenResults
        }
      }
      res: Response
    }
  > &
  OpheliaRoute<
    'PUT /patient/:patientId/drugScreens/:id',
    {
      req: {
        params: { patientId: string; id: string }
        data: {
          administeredAt: string
          results: DrugScreenResults
        }
      }
      res: Response
    }
  > &
  OpheliaRoute<
    'DELETE /patient/:patientId/drugScreens/:id',
    {
      req: {
        params: { patientId: string; id: string }
      }
      res: Response
    }
  > &
  OpheliaRoute<
    'GET /appointments',
    {
      req: {
        query: {
          minDate: ISOString
          maxDate: ISOString
          calendarID?: string
          withNoteDetails?: 'yes' | 'no'
        }
      }
      res: (Appointment | AppointmentWithNoteDetails)[]
    }
  > &
  OpheliaRoute<
    'GET /appointments/blocks',
    {
      req: {
        query: {
          minDate: string
          maxDate: string
          calendarID: string
        }
      }
      res: AcuityBlock[]
    }
  > &
  OpheliaRoute<
    'GET /appointments/calendar/:type/days',
    {
      req: {
        params: { type: string }
        query: {
          calendarId?: `${number}` | undefined
          from: string
          to: string
          timezone?: string | undefined
          patientID: string
        }
      }
      res: {
        dateslots: { date: string; calendarIDs: number[] }[]
        insuranceMatchedCalendarIDs: number[]
      }
    }
  > &
  OpheliaRoute<
    'GET /appointments/calendar/:type/slots',
    {
      req: {
        params: { type: string }
        query: {
          calendarId?: `${number}` | undefined
          date: string
          timezone?: string | undefined
          patientID: string
        }
      }
      res: { time: string; calendarIDs: number[]; slotsAvailable: number }[]
    }
  > &
  OpheliaRoute<
    'GET /appointments/follow-up-occupancy-rate',
    {
      req: {
        query: {
          calendarId: `${number}`
          startDate: MMDDYYYY
          endDate: MMDDYYYY
        }
      }
      res: {
        occupancyRate: number
      }
    }
  > &
  OpheliaRoute<
    'GET /appointments/slots',
    {
      req: {
        query: {
          minDate: string
          maxDate: string
          timezone: string
          calendarId: string
          appointmentTypeId: string
          patientId: string
        }
      }
      res: { time: string; calendarIDs: number[]; slotsAvailable: number }[]
    }
  > &
  OpheliaRoute<
    'GET /appointments/type',
    {
      req: {
        query: {
          appointmentTypeId: string
        }
      }
      res: AcuityAppointmentType
    }
  > &
  OpheliaRoute<
    'PUT /patient/:patientId/appointments/:appointmentId/reschedule',
    {
      req: {
        params: {
          patientId: string
          appointmentId: string
        }
        data: {
          calendarId: number
          datetime: string
          timezone: string
          metadata?: AppointmentMetadata
        }
      }
      res: void
    }
  > &
  OpheliaRoute<
    'GET /patient/:patientId/appointments/:appointmentId',
    {
      req: {
        params: {
          patientId: string
          appointmentId: string
        }
      }
      res: Appointment
    }
  > &
  OpheliaRoute<
    'POST /patient/:patientId/appointments/call-me-now',
    {
      req: {
        params: {
          patientId: string
        }
      }
      res: never
    }
  > &
  OpheliaRoute<
    'GET /appointments/:appointmentId/history',
    {
      req: {
        params: {
          appointmentId: string
        }
      }
      res: Appointment[]
    }
  > &
  OpheliaRoute<
    'GET /zoomWebhookEvents/:meetingId',
    {
      req: {
        params: {
          meetingId: string
        }
      }
      res: ZoomWebhookEvent[]
    }
  > &
  OpheliaRoute<
    'POST /insurance/review-queue',
    {
      req: {
        data: {
          patientId: string
          primaryInNetwork?: boolean
          secondaryInNetwork?: boolean
        }
      }
      res: void
    }
  > &
  OpheliaRoute<
    'GET /insurance/review-queue',
    {
      req: {
        query: {
          limit: `${number}`
          queue: InsuranceQueue
        }
      }
      res: {
        irqItems: InsuranceReviewQueueItem[]
        counts: {
          payorsQueue: number
          enrollmentQueue: number
          flaggedQueue: number
        }
      }
    }
  > &
  OpheliaRoute<
    'POST /insurance/review-queue/billing-team',
    { req: { data: { patientId: string } }; res: void }
  > &
  OpheliaRoute<
    'GET /emrNotifications',
    {
      req: {
        query: {
          employeeId: string
        }
      }
      res: EMRNotificationResponse[]
    }
  > &
  OpheliaRoute<
    'GET /emrNotifications/count',
    {
      req: {
        query: {
          employeeId: string
        }
      }
      res: number
    }
  > &
  OpheliaRoute<
    'PUT /emrNotifications/:notificationId',
    {
      req: {
        params: {
          notificationId: string
        }
        data: {
          seenAt?: string
        }
      }
      res: void
    }
  > &
  OpheliaRoute<
    'POST /patient/:patientId/appointments/:appointmentId/cancel',
    {
      req: {
        params: {
          patientId: string
          appointmentId: string
        }
        data: {
          noShow?: boolean
          noEmail?: boolean
          minutesWaiting?: number | null
        }
      }
      res: void
    }
  > &
  OpheliaRoute<
    'GET /patient/:patientId/dosespotEligibilities',
    {
      req: {
        params: {
          patientId: string
        }
      }
      res: DoseSpotEligibilitiesResponse
    }
  > &
  OpheliaRoute<
    'GET /patient/:patientId/dosespotCoverage',
    {
      req: {
        params: {
          patientId: string
        }
        query: {
          patientEligibilityId: string
          ndc: string
        }
      }
      res: DoseSpotDrugCoverageResponse
    }
  > &
  OpheliaRoute<
    'GET /patient/:patientId/dosespot',
    {
      req: { params: { patientId: string } }
      res: object
    }
  > &
  OpheliaRoute<
    'GET /patient/:patientId/prescriptions',
    {
      req: {
        params: { patientId: string }
        query: { useDoseSpot?: `${boolean}`; doseSpotOnly?: `${boolean}` }
      }
      res: PrescriptionResponse[]
    }
  > &
  OpheliaRoute<
    'GET /patient/:patientId/prescription/:prescriptionId',
    {
      req: {
        params: { patientId: string; prescriptionId: string }
      }
      res: {
        prescription: DoseSpotPrescription
        patient: Patient | undefined
        employee: Employee | undefined
        logs: DoseSpotPrescriptionLogEntry[]
      }
    }
  > &
  OpheliaRoute<
    'POST /patient/:patientId/prescriptions',
    {
      req: { params: { patientId: string }; data: Omit<PrescriptionRequest, 'patientId'> }
      res: DoseSpotIdResponse
    }
  > &
  OpheliaRoute<
    'GET /patient/:patientId/legacyPrescription/:prescriptionId',
    {
      req: {
        params: { patientId: string; prescriptionId: string }
      }
      res: PrescriptionResponse | undefined
    }
  > &
  OpheliaRoute<
    'GET /patient/:patientId/dosespotRealtimePrescriptionBenefits',
    {
      req: {
        params: {
          patientId: string
        }
        query: {
          patientEligibilityId?: string
          ndc: string
          pharmacyId: string
          quantity: string
          daysSupply: string
          dispenseUnitTypeID: string
        }
      }
      res: DoseSpotPrescriptionBenefitsResponse
    }
  > &
  OpheliaRoute<
    'GET /patient/:patientId/medicationHistory',
    {
      req: {
        params: {
          patientId: string
        }
      }
      res: DoseSpotMedicationHistoryResponse
    }
  > &
  OpheliaRoute<
    'POST /patient/:patientId/medicationHistory/consent',
    {
      req: {
        params: {
          patientId: string
        }
      }
      res: void
    }
  > &
  OpheliaRoute<
    'GET /patient/:patientId/problemsList',
    {
      req: {
        params: {
          patientId: string
        }
      }
      res: DecoratedProblem[]
    }
  > &
  OpheliaRoute<
    'POST /patient/:patientId/problemsList',
    {
      req: {
        params: {
          patientId: string
        }
        data: {
          problems: ProblemListProblem[]
        }
      }
      res: void
    }
  > &
  OpheliaRoute<
    'PUT /appointments/:patientId/:visitId',
    {
      req: {
        params: {
          patientId: string
          visitId: string
        }
        data: Partial<Appointment>
      }
      res: void
    }
  > &
  OpheliaRoute<
    'PUT /patient/:patientId/diagnosis/:diagnosisId/problemsList',
    {
      req: {
        params: {
          patientId: string
          diagnosisId: string
        }
        data: {
          diagnosis: ProblemListProblem
        }
      }
      res: void
    }
  > &
  OpheliaRoute<
    'GET /appointments/working-hours',
    {
      req: {
        query: {
          calendarId: string
          minDate: string
          maxDate: string
        }
      }
      res: Record<string, HourRange[]>
    }
  > &
  OpheliaRoute<
    'GET /patient/:patientId/visits/:visitId/notes',
    {
      req: {
        params: { patientId: string; visitId: string }
      }
      res: Partial<Record<VisitNoteType, VisitNoteContent>> | null
    }
  > &
  OpheliaRoute<
    'GET /patients/:patientId/visit-notes/:visitNoteId',
    {
      req: {
        params: {
          patientId: string
          visitNoteId: string
        }
      }
      res: VisitNote | null
    }
  > &
  OpheliaRoute<
    'POST /patients/:patientId/visit-notes/:visitNoteId/addendums',
    {
      req: {
        params: {
          patientId: string
          visitNoteId: string
        }
        data: {
          content: string
        }
      }
      res: void
    }
  > &
  OpheliaRoute<
    'GET /patients/:patientId/visit-notes/:visitNoteId/addendums',
    {
      req: {
        params: {
          patientId: string
          visitNoteId: string
        }
      }
      res: VisitNoteAddendum[]
    }
  > &
  OpheliaRoute<
    'PUT /patient/:patientId/visits/:visitId/notes',
    {
      req: {
        params: { patientId: string; visitId: string }
        data: {
          type: VisitNoteType
          content: VisitNoteContent
          mentalHealthEvaluation?: MentalHealthEvaluationSaveRequest
        }
      }
      res: void
    }
  > &
  OpheliaRoute<
    'GET /patient/:patientId/mental-health-evals',
    {
      req: {
        params: { patientId: string }
        query: { isEvaluated?: 'yes' | 'no' }
      }
      res: MentalHealthEvaluation[]
    }
  > &
  OpheliaRoute<
    'GET /visit/:visitId/mental-health-evals',
    {
      req: {
        params: { visitId: string }
      }
      res: MentalHealthEvaluation
    }
  > &
  OpheliaRoute<
    'GET /icd-10-codes',
    {
      req: {
        query: {
          type: ICD10CodeType
        }
      }
      res: ICD10Code[]
    }
  > &
  OpheliaRoute<'GET /configs', { req: never; res: Configs }> &
  OpheliaRoute<
    'PUT /configs',
    {
      req: {
        data: {
          onCallEngineer?: string
          consultationQueue?: Partial<ConfigsModel['consultationQueue']>
        }
      }
      res: void
    }
  > &
  OpheliaRoute<
    'POST /patient/:patientId/send-mbc-link/:type',
    {
      req: {
        params: { patientId: string; type: WellnessCheckWorkflowType }
      }
      res: void
    }
  > &
  OpheliaRoute<
    'GET /ineligibleNotes',
    {
      req: {
        query: {
          patientId: string
        }
      }
      res: (IneligibleNoteModel & { employeeName: string; id: string })[]
    }
  > &
  OpheliaRoute<
    'GET /dischargeNotes',
    {
      req: {
        query: { patientId: string }
      }
      res: (DischargeNoteModel & { employeeName: string; id: string })[]
    }
  > &
  OpheliaRoute<
    'GET /pharmacy/:pharmacyId',
    {
      req: {
        params: { pharmacyId: string }
      }
      res: DoseSpotPharmacy
    }
  > &
  OpheliaRoute<
    'POST /invoices/pdf',
    {
      req: {
        data: { patientId: string; invoiceId: string }
      }
      res: { name: string; signedUrl: string }
    }
  > &
  OpheliaRoute<
    'GET /invoices',
    {
      req: {
        query: {
          patientId: string
        }
      }
      res: { invoiceListItems: InvoiceListItem[]; aggregateInvoiceInfo: AggregateInvoiceInfo }
    }
  > &
  OpheliaRoute<
    'POST /invoices/:invoiceId/void',
    {
      req: {
        params: {
          invoiceId: string
        }
        data: {
          details: string | null
        }
      }
      res: void
    }
  > &
  OpheliaRoute<
    'POST /invoices/:invoiceId/refund',
    {
      req: {
        params: {
          invoiceId: string
        }
        data: {
          stripePaymentIntentId: string
          reason: 'duplicate' | 'fraudulent' | 'requested_by_customer'
          details: string | null
        }
      }
      res: void
    }
  > &
  OpheliaRoute<
    'GET /invoices/:invoiceId/payments',
    {
      req: {
        params: {
          invoiceId: string
        }
      }
      res: StripePayment[]
    }
  > &
  OpheliaRoute<
    'POST /invoices/:invoiceId/pay',
    {
      req: {
        params: {
          invoiceId: string
        }
        data: {
          amountInCents: number
          stripePaymentMethodId: string
        }
      }
      res: void
    }
  > &
  OpheliaRoute<
    'GET /patient/:patientId/payment/methods',
    {
      req: {
        params: { patientId: string }
      }
      res: StripePaymentMethod[]
    }
  > &
  OpheliaRoute<
    'GET /patient/:patientId/subscription',
    {
      req: {
        params: { patientId: string }
      }
      res: FormattedStripeSubscription
    }
  > &
  EmrTwilioApi &
  OpheliaRoute<
    'GET /patient/:patientId/tasks',
    {
      req: { params: { patientId: string } }
      res: PatientTask[]
    }
  > &
  OpheliaRoute<
    'GET /referral-partners',
    {
      req: never
      res: ReferralPartner[]
    }
  > &
  OpheliaRoute<
    'GET /referral-partners/:referralPartnerId',
    {
      req: { params: { referralPartnerId: string } }
      res: ReferralPartner
    }
  > &
  OpheliaRoute<
    'PUT /patient/:patientId/appointments/:appointmentId/task-extension',
    {
      req: {
        params: {
          patientId: string
          appointmentId: string
        }
        data: { patientTaskExpirationDatetime: string }
      }
      res: void
    }
  > &
  OpheliaRoute<
    'GET /enrollment-panel/patients',
    {
      req: never
      res: BigQueryRowTypes['enrollmentPanel'][]
    }
  > &
  OpheliaRoute<
    'GET /enrollment-panel/leads',
    {
      req: never
      res: BigQueryRowTypes['referralLeads'][]
    }
  > &
  OpheliaRoute<
    'POST /patient/:patientId/payment-plan',
    {
      req: {
        params: { patientId: Patient['oid'] }
        data: {
          employeeId: Employee['oid']
          totalAmountInCents: number
          numPayments: number
          paymentFrequency: PaymentPlanFrequency
          firstPaymentDate: ISOString
        }
      }
      res: void
    }
  > &
  OpheliaRoute<
    'GET /patient/:patientId/payment-plans',
    {
      req: {
        params: { patientId: Patient['oid'] }
      }
      res: PaymentPlan[]
    }
  > &
  OpheliaRoute<
    'GET /payment-plan/payments',
    {
      req: {
        query: { paymentPlanId: PaymentPlan['oid'] }
      }
      res: StripePayment[]
    }
  > &
  OpheliaRoute<
    'PUT /payment-plan/:paymentPlanId',
    {
      req: {
        params: { paymentPlanId: PaymentPlan['oid'] }
        data: { nextPaymentDate: ISOString }
      }
      res: void
    }
  > &
  OpheliaRoute<
    'POST /payment-plan/:paymentPlanId/retry-payment',
    {
      req: {
        params: { paymentPlanId: PaymentPlan['oid'] }
        data: { stripePaymentMethodId: string; stripePaymentIntentIdToRetry: string }
      }
      res: void
    }
  > &
  OpheliaRoute<
    'DELETE /payment-plan/:paymentPlanId/cancel-payment',
    {
      req: {
        params: { paymentPlanId: PaymentPlan['oid'] }
        data: { paymentDateToRemove: YYYYMMDD }
      }
      res: void
    }
  > &
  OpheliaRoute<
    'GET /fullstory-url/:uid',
    {
      req: {
        params: {
          uid: Patient['oid'] | Employee['oid']
        }
      }
      res: {
        appUrl: string | null
      }
    }
  > &
  OpheliaRoute<
    'POST /caseReviewNote/patient/:patientId',
    {
      req: {
        params: {
          patientId: Patient['oid']
        }
        data: {
          content?: {
            preWorkNotes?: string
          }
          visitId?: Appointment['id']
        }
      }
      res: void
    }
  > &
  OpheliaRoute<
    'GET /caseReviewNote/visit/:visitId',
    { req: { params: { visitId: `${number}` } }; res: CaseReviewNote | null }
  > &
  OpheliaRoute<
    'POST /caseReviewNote/:caseReviewNoteId/attestation',
    {
      req: {
        params: { caseReviewNoteId: string }
        data: { comments: string }
      }
      res: void
    }
  > &
  OpheliaRoute<
    'GET /enrollment-panel/booked-candidates',
    {
      req: { query: { employeeId?: string } }
      res: {
        patientId: string
        patientName: string
        patientState: string
        patientTasks: PatientTask[]
        visitDatetime: string
        tasksDue: string
        visitConfirmationDue: string
        visitConfirmed: boolean
        visitConfirmedAt: string
        visitConfirmedBy: string
        claimed: boolean
        enrollmentCoordinatorName: string
        enrollmentCoordinatorId: string
      }[]
    }
  > &
  OpheliaRoute<
    'POST /referral/friend-or-family',
    {
      req: {
        data: {
          referrerPatientId: Patient['oid']
          anonymousId: string
        }
      }
      res: {
        referralLink: string
        referralId: string
      }
    }
  > &
  OpheliaRoute<
    'GET /patients/:patientId/ccmMatching',
    {
      req: {
        params: {
          patientId: Patient['oid']
        }
        query: {
          shouldFilterByInNetworkStatus?: 'yes' | 'no'
        }
      }
      res: EligibleCCM[]
    }
  >

export type PatientReleaseOfInformationAddChangeRequestBody = { changesRequested: string }
