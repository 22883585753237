import { BridgeApi, OpheliaClient } from '@shared/types'
import { BaseApiConfig, toTime } from '@shared/utils'
import axios from 'axios'
import { Config } from './config'

const axiosInstance = axios.create({
  baseURL: Config.BACKEND_BASE_URL,
  headers: { 'Content-Type': 'application/json' },
  timeout: toTime('10 sec').ms(),
})

const apiConfig: BaseApiConfig = { axiosInstance }

export const bridgeApi = new OpheliaClient<BridgeApi>(apiConfig)
