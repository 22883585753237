import {
  EQUINOX_FONT_FAMILIES,
  EQUINOX_FONT_FILE_NAMES,
  EQUINOX_THEME,
  Global,
  MantineProvider,
  createEmotionCache,
} from '@shared/components'
import { isAxiosError } from 'axios'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Join } from './Join'
import { Landing } from './Landing'
import { Config } from './config'
import { routes } from './routes'

const { FONT_CDN_BASE_URL } = Config
const { regular, bold, pro } = EQUINOX_FONT_FILE_NAMES

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: (failureCount, error) => {
        if (isAxiosError(error)) {
          const errorStatus = error.response?.status
          // Only retry 5xx errors
          if (errorStatus && errorStatus < 500) {
            return false
          }
        }

        return failureCount < 3
      },
    },
  },
})

export const App = () => {
  const appendCache = createEmotionCache({ key: 'mantine', prepend: false })

  return (
    <QueryClientProvider client={queryClient}>
      <MantineProvider theme={EQUINOX_THEME} emotionCache={appendCache} withNormalizeCSS>
        <Global
          styles={[
            {
              '@font-face': {
                fontFamily: EQUINOX_FONT_FAMILIES.regular,
                src: `url(${new URL(`/fonts/${regular}`, FONT_CDN_BASE_URL)}) format('woff2')`,
                fontWeight: 400,
                fontStyle: 'normal',
              },
            },
            {
              '@font-face': {
                fontFamily: EQUINOX_FONT_FAMILIES.bold,
                src: `url(${new URL(`/fonts/${bold}`, FONT_CDN_BASE_URL)}) format('woff2')`,
                fontWeight: 700,
                fontStyle: 'normal',
              },
            },
            {
              '@font-face': {
                src: `url(${new URL(`/fonts/${pro}`, FONT_CDN_BASE_URL)}) format('woff2')`,
                fontFamily: EQUINOX_FONT_FAMILIES.pro,
                fontWeight: 900,
                fontStyle: 'normal',
              },
            },
          ]}
        />
        <BrowserRouter>
          <Routes>
            <Route path={routes.join.index} element={<Join />} />
            <Route path={routes.landing.index} element={<Landing />} />
          </Routes>
        </BrowserRouter>
      </MantineProvider>
    </QueryClientProvider>
  )
}
