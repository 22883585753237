/* eslint-disable no-magic-numbers */

export const EN_DASH = '\u2013'

const normalizePhoneNumber = (phoneNumber: string, countryCode = '+1') => {
  const normalized = String(phoneNumber).replace(/[^0-9+]/g, '')
  if (normalized.startsWith('+')) {
    return normalized
  }

  if (normalized.length === 10) {
    return `${countryCode}${normalized}`
  }

  if (normalized.length === 11) {
    return `+${normalized}`
  }
  return normalized
}

const formatPhoneNumber = (value: string) => {
  if (!value) {
    return value
  }

  const phoneNumber = value.replace('+1', '')

  if (phoneNumber.length < 4) {
    return `${phoneNumber}`
  }

  if (phoneNumber.length < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`
  }

  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}${EN_DASH}${phoneNumber.slice(
    6,
    10,
  )}`
}

export const phone = (phoneNumber: string | undefined) => {
  if (!phoneNumber) {
    return {
      normalized: '',
      formatted: '',
      arealessFormat: '',
      isValid: false,
    }
  }

  const normalized = normalizePhoneNumber(phoneNumber)
  const arealessformat = formatPhoneNumber(normalized)

  return {
    normalized,
    formatted: `+1 ${arealessformat}`,
    isValid: normalized.length === 12,
    arealessFormat: arealessformat,
    maskedFormat: `(***) ***${EN_DASH}${normalized.slice(-4)}`,
  }
}
