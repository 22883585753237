// eslint-disable-next-line no-restricted-imports
import { List as MantineList, ListProps as MantineListProps } from '@mantine/core'
import React from 'react'

export const List = ({ sx, icon = '•', ...rest }: MantineListProps) => {
  return (
    <MantineList
      icon={icon}
      sx={[theme => ({ paddingLeft: theme.spacing.xs }), ...(Array.isArray(sx) ? sx : [sx])]}
      {...rest}
    />
  )
}

export const ListItem = MantineList.Item
export type ListProps = MantineListProps
