/* eslint-disable prefer-destructuring */
import {
  DischargeReason,
  ISOString,
  InvoiceListItem,
  InvoicePaymentStatus,
  InvoiceType,
  PatientModel,
  StripeLineItem,
  StripeSubscriptionInterval,
  SubscriptionInterval,
} from '.'

/**
 * When a patient is paused, we use the discharge reason to determine whether to void invoices for a subscription or generate draft invoices.
 * Voiding invoices will prevent the patient from being charged for the subscription. Generating draft invoices will allow the patient to be charged for the subscription.
 */

export const dischargeReasonToSubscriptionPauseBehavior = (
  dischargeReason?: DischargeReason | '',
): 'keep_as_draft' | 'void' => {
  const voidInvoiceReasons: DischargeReason[] = [
    'clinicial',
    'death',
    'self-determined end of care',
    'non-compliance',
  ]
  if (voidInvoiceReasons.some(reason => reason === dischargeReason)) {
    return 'void'
  }
  return 'keep_as_draft'
}

export const isAutoPayEnabled = (patient?: { autoPayment?: PatientModel['autoPayment'] }) => {
  if (!patient) {
    return false
  }

  const { autoPayment } = patient

  if (!autoPayment?.pausedUntil) {
    return true
  }

  const today = new Date()
  const pausedUntil = new Date(autoPayment.pausedUntil)
  if (today < pausedUntil) {
    return false
  }

  return true
}

export const UNPAID_INVOICE_STATUSES: InvoicePaymentStatus[] = [
  'unpaid',
  'partiallyPaid',
  'pastDue',
]

export const isInvoicePaid = (invoice: InvoiceListItem) => {
  return invoice.paymentStatus === 'paid'
}

export const isTrialPeriodPayment = (invoice: InvoiceListItem) => {
  if (invoice.platform !== 'stripe') {
    return false
  }

  const lineItems = invoice.rawExternalData?.lines?.data

  if (!lineItems) {
    return false
  }

  return lineItems.some((lineItem: StripeLineItem) =>
    lineItem.description.toLowerCase().includes('trial period'),
  )
}

export const isInvoicePastDue = ({
  dueDate,
  includeNoShows = true,
  paymentStatus,
  type,
}: {
  dueDate: ISOString | undefined
  includeNoShows?: boolean
  paymentStatus: InvoicePaymentStatus
  type?: InvoiceType | null | undefined
}) => {
  if (!dueDate) {
    return false
  }

  const invoiceDueDate = new Date(dueDate)
  const now = new Date()
  const eligibleInvoiceTypes: InvoiceType[] = ['copay', 'subscription']

  if (includeNoShows) {
    eligibleInvoiceTypes.push('no-show')
  }

  return (
    invoiceDueDate < now &&
    isPaymentNeededForInvoice({ paymentStatus }) &&
    type &&
    eligibleInvoiceTypes.includes(type)
  )
}

export const isPaymentNeededForInvoice = ({
  paymentStatus,
}: {
  paymentStatus: InvoicePaymentStatus
}) => {
  return UNPAID_INVOICE_STATUSES.includes(paymentStatus)
}

export const getSubscriptionInterval = (props: {
  interval: StripeSubscriptionInterval
  interval_count: number
}) => {
  const { interval, interval_count: intervalCount } = props

  let subscriptionInterval: SubscriptionInterval
  switch (interval) {
    case 'week':
      switch (intervalCount) {
        case 2:
          subscriptionInterval = 'biweekly'
          break
        default:
          subscriptionInterval = 'weekly'
      }
      break
    case 'month':
      subscriptionInterval = 'monthly'
      break
    /*
     * Note: currently, we only offer weekly, biweekly, and monthly subscriptions.
     * If that changes, we'll need to update this switch statement
     */
    default:
      subscriptionInterval = 'monthly'
  }
  return subscriptionInterval
}
