import { TwilightIcon } from '../twilight/icons'

export type PatientWorkflowTaskType =
  | 'phq-9-form'
  | 'phq-8-form'
  | 'gad-7-form'
  | 'barc-10-form'
  | 'asq-form'
  | 'medical-intake-form'

export type PatientFormTaskType =
  | 'billing'
  | 'emergency-contact'
  | 'insurance'
  | 'profile'
  | 'upload-id'
  | 'intake-visit-standby-list'
  | 'preferred-pharmacy'
  | 'treatment-consent'
  | 'treatment-consent-and-financial-agreement'
  | 'how-did-you-hear-about-us'
  | 'financial-agreement'
  | 'thank-you-note'

export type PatientScheduleTaskType = 'schedule-intake-visit' | 'schedule-follow-up-visit'

export type PatientTaskType =
  | PatientWorkflowTaskType
  | PatientFormTaskType
  | PatientScheduleTaskType

export enum PortalRoute {
  ScheduleIntakeVisit = '/portal/task/schedule-intake-visit',
  ScheduleFollowUpVisit = '/portal/task/schedule-follow-up-visit',
  Profile = '/portal/task/profile',
  UploadId = '/portal/task/upload-id',
  Insurance = '/portal/task/insurance',
  Billing = '/portal/task/billing',
  FinancialAgreement = '/portal/task/financial-agreement',
  MedicalIntakeForm = '/portal/task/medical-intake-form',
  HowDidYouHearAboutUs = '/portal/task/how-did-you-hear-about-us',
  EmergencyContact = '/portal/task/emergency-contact',
  PreferredPharmacy = '/portal/task/preferred-pharmacy',
  IntakeVisitStandbyList = '/portal/intake-visit-standby-list',
  Phq9Form = '/portal/task/phq-9-form',
  Phq8Form = '/portal/task/phq-8-form',
  Gad7Form = '/portal/task/gad-7-form',
  Barc10Form = '/portal/task/barc-10-form',
  TreatmentConsent = '/portal/task/treatment-consent',
  TreatmentConsentAndFinancialAgreement = '/portal/task/treatment-consent-and-financial-agreement',
  AsqForm = '/portal/task/asq-form',
  ThankYouNote = '/portal/task/thank-you-note',
}

export type PatientTaskRequiredFor = 'intake-visit'

export type PatientPortalTaskCard = {
  title: string
  type: PatientTaskType
  icon: TwilightIcon
  path: PortalRoute
  priority: 0 | 1 | 2
  requiredFor: PatientTaskRequiredFor[]
  disabled: boolean
  footer?: string | undefined
  requiredMessage?: string | undefined
  isComplete: boolean
  isApplicable: boolean
}
