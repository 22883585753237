// eslint-disable-next-line no-restricted-imports
import { Flex, useMantineTheme } from '@mantine/core'
import React, { PropsWithChildren } from 'react'
import { HelpCircleIcon } from './icons'
import { getAttributes } from './inputs'
import { Tooltip, TooltipProps } from './Tooltip'

export const TooltipLabel = ({
  label,
  children,
  disabled = false,
  ...rest
}: PropsWithChildren<TooltipProps>) => {
  const {
    other: { sizes, colors },
  } = useMantineTheme()

  if (!label) {
    return <>{children}</>
  }

  return (
    <Flex sx={{ gap: sizes.gap.md }} align='center'>
      {children}
      <Tooltip label={label} {...rest}>
        <Flex
          {...getAttributes({ disabled })}
          sx={{
            ':hover': { '& circle': { stroke: colors.actions[1] } },
            '&[disabled]': {
              '> div': { color: colors.actions[2] },
              '& circle': { stroke: colors.actions[2] },
            },
          }}
        >
          <HelpCircleIcon styled />
        </Flex>
      </Tooltip>
    </Flex>
  )
}
