import { sortBy } from './sort'
export const KEYSTONE_FIRST_PAYER_ID = '23284'
export const KEYSTONE_ELIGIBLE_PAYER_IDS = [KEYSTONE_FIRST_PAYER_ID, '77741', '42344']
export const AMERIHEALTH_ELIGIBLE_PAYER_IDS = ['22248', '77062']
export const GEISINGER_PAYER_ID = '75273'
export const EMPIRE_BLUE_PAYER_ID = 'NYBLS'
export const HIGHMARK_PAYER_IDS = ['35145', 'PABLS']

export type PayerSearchOption =
  | 'member_dob'
  | 'member_id'
  | 'member_first_name'
  | 'member_last_name'
  | 'member_gender'

type PayerOnShortList = {
  value: string | typeof INSURANCE_OTHER_OPTION_ID
  label: string
  states?: string[]
  minRequiredInfo: PayerSearchOption[]
  // Do not add field unless wanting to skip payment info
  hasMedicaidPlanType?: boolean
}

/**
 * The list of Medicaid payers Ophelia currently accepts.
 */
export const MedicaidInsuranceList: PayerOnShortList[] = [
  {
    value: 'AZMCD',
    label: 'Medicaid of Arizona',
    states: ['Arizona'],
    minRequiredInfo: ['member_id', 'member_dob'],
  },
  {
    value: 'MEMCD',
    label: 'Medicaid of Maine',
    states: ['Maine'],
    minRequiredInfo: ['member_id', 'member_first_name', 'member_last_name', 'member_dob'],
  },
  {
    value: 'MTMCD',
    label: 'Medicaid of Montana',
    states: ['Montana'],
    minRequiredInfo: ['member_id'],
  },
  {
    value: 'NYMCD',
    label: 'Medicaid of New York',
    states: ['New York'],
    minRequiredInfo: ['member_id', 'member_first_name', 'member_last_name', 'member_dob'],
  },
  {
    value: 'PAMCD',
    label: 'Medicaid of Pennsylvania',
    states: ['Pennsylvania'],
    minRequiredInfo: ['member_id'],
    hasMedicaidPlanType: true,
  },
  {
    value: 'VAMCD',
    label: 'Medicaid of Virginia',
    states: ['Virginia'],
    minRequiredInfo: ['member_id'],
  },
  {
    value: 'VTMCD',
    label: 'Medicaid of Vermont',
    states: ['Vermont'],
    minRequiredInfo: ['member_id', 'member_first_name', 'member_last_name', 'member_dob'],
  },
  {
    value: 'WAMCD',
    label: 'Medicaid of Washington',
    states: ['Washington'],
    minRequiredInfo: ['member_id', 'member_dob'],
  },
]

/**
 * The list of payers Ophelia currently accepts, used as a
 * selectable list in the Welcome Flow. Allows for an optional
 * `states` key for valid states - if missing, it is available
 * in all states.
 *
 * The minRequiredInfo array includes the minimum search
 * criteria required for Eligible API to return any coverage info
 * for that payer. If the array is empty, coverage/eligibility
 * requests are not supported through Eligible API.
 */
export const PayersShortList: PayerOnShortList[] = [
  {
    value: '22248',
    label: 'AmeriHealth Caritas PA',
    states: ['Pennsylvania'],
    minRequiredInfo: ['member_id'],
    hasMedicaidPlanType: true,
  },
  {
    value: 'CTBLS',
    label: 'Anthem BCBS of Connecticut',
    states: ['Connecticut'],
    minRequiredInfo: ['member_id', 'member_first_name', 'member_last_name', 'member_dob'],
  },
  {
    value: 'MEBLS',
    label: 'Anthem BCBS of Maine',
    states: ['Maine'],
    minRequiredInfo: ['member_id', 'member_first_name', 'member_last_name', 'member_dob'],
  },
  {
    value: 'VABLS',
    label: 'Anthem BCBS of Virginia',
    states: ['Virginia'],
    minRequiredInfo: ['member_id', 'member_first_name', 'member_last_name', 'member_dob'],
  },
  {
    value: 'MDBLS',
    label: 'BCBS Carefirst Maryland',
    states: ['Maryland'],
    minRequiredInfo: ['member_id', 'member_first_name', 'member_last_name', 'member_dob'],
  },
  {
    value: 'MTBLS',
    label: 'Blue Cross Blue Shield of Montana',
    states: ['Montana'],
    minRequiredInfo: ['member_id', 'member_dob'],
  },
  {
    value: 'VTBLS',
    label: 'Blue Cross Blue Shield of Vermont',
    states: ['Vermont'],
    minRequiredInfo: [
      'member_id',
      'member_first_name',
      'member_last_name',
      'member_dob',
      'member_gender',
    ],
  },
  {
    value: 'WABLC',
    label: 'Blue Cross of Washington (Premera)',
    states: ['Washington'],
    minRequiredInfo: ['member_id', 'member_dob'],
  },
  {
    value: '23045',
    label: 'Capital Blue Cross',
    states: ['Pennsylvania'],
    minRequiredInfo: ['member_id', 'member_first_name', 'member_last_name', 'member_dob'],
  },
  { value: 'SX065', label: 'CDPHP', states: ['New York'], minRequiredInfo: ['member_id'] },
  {
    value: 'NYBLS',
    label: 'Empire Blue',
    states: ['New York'],
    minRequiredInfo: ['member_id', 'member_dob'],
  },
  {
    value: '11315',
    label: 'Fidelis Care of New York',
    states: ['New York'],
    minRequiredInfo: ['member_id', 'member_first_name', 'member_last_name', 'member_dob'],
  },
  {
    value: '75273',
    label: 'Geisinger Health Plan',
    states: ['Pennsylvania'],
    minRequiredInfo: ['member_id', 'member_first_name', 'member_last_name', 'member_dob'],
    hasMedicaidPlanType: true,
  },
  {
    value: '80141',
    label: 'Healthfirst of NY',
    states: ['New York'],
    minRequiredInfo: ['member_id', 'member_dob'],
  },
  {
    value: '35145',
    label: 'Highmark PA',
    states: ['Pennsylvania'],
    minRequiredInfo: ['member_id', 'member_dob'],
  },
  // Humana does not support coverage/eligibility requests via Eligible.com
  { value: '61101', label: 'Humana', minRequiredInfo: [] },
  {
    value: 'JHHP',
    label: 'Johns Hopkins Healthcare, LLC',
    states: ['Maryland'],
    minRequiredInfo: ['member_id'],
  },
  {
    value: '23284',
    label: 'Keystone Mercy Health Plan',
    states: ['Pennsylvania'],
    minRequiredInfo: ['member_id', 'member_dob'],
    hasMedicaidPlanType: true,
  },
  {
    value: 'MEMCD',
    label: 'MaineCare',
    states: ['Maine'],
    minRequiredInfo: ['member_id', 'member_first_name', 'member_last_name', 'member_dob'],
  },
  {
    value: '14165',
    label: 'MVP Health Plan of NY',
    states: ['New York', 'Vermont'],
    minRequiredInfo: ['member_id', 'member_dob'],
  },
  {
    value: 'TREST',
    label: 'Tricare East',
    states: [
      'New York',
      'Connecticut',
      'Maine',
      'Maryland',
      'Texas',
      'Virginia',
      'Vermont',
      'Florida',
      'New Jersey',
      'Pennsylvania',
    ],
    minRequiredInfo: ['member_id', 'member_first_name', 'member_last_name', 'member_dob'],
  },
  {
    value: '87726',
    label: 'United HealthCare',
    states: ['Pennsylvania', 'Arizona', 'Washington'],
    minRequiredInfo: ['member_id', 'member_dob'],
    hasMedicaidPlanType: true,
  },
  // United Mines Worker Association does not support coverage/eligibility requests via Eligible.com
  { value: '52180', label: 'United Mines Worker Association', minRequiredInfo: [] },
  {
    value: 'VABLS',
    label: 'Blue Card PPO',
    states: [
      'Pennsylvania',
      'New York',
      'Connecticut',
      'Maine',
      'Montana',
      'Maryland',
      'Washington',
      'Virginia',
    ],
    minRequiredInfo: ['member_id', 'member_first_name', 'member_last_name', 'member_dob'],
  },
  {
    value: 'AZMCR',
    label: 'Arizona Medicare',
    states: ['Arizona'],
    minRequiredInfo: ['member_id', 'member_first_name', 'member_last_name'],
  },
  {
    value: 'CSMCR',
    label: 'California Medicare',
    states: ['California'],
    minRequiredInfo: ['member_id', 'member_first_name', 'member_last_name'],
  },
  {
    value: 'CTMCR',
    label: 'Connecticut Medicare',
    states: ['Connecticut'],
    minRequiredInfo: ['member_id', 'member_first_name', 'member_last_name'],
  },
  {
    value: 'FLMCR',
    label: 'Florida Medicare',
    states: ['Florida'],
    minRequiredInfo: ['member_id', 'member_first_name', 'member_last_name'],
  },
  {
    value: '00901',
    label: 'Maryland Medicare',
    states: ['Maryland'],
    minRequiredInfo: ['member_id', 'member_first_name', 'member_last_name'],
  },
  {
    value: 'MEMCR',
    label: 'Maine Medicare',
    states: ['Maine'],
    minRequiredInfo: ['member_id', 'member_first_name', 'member_last_name'],
  },
  {
    value: 'MTMCR',
    label: 'Montana Medicare',
    states: ['Montana'],
    minRequiredInfo: ['member_id', 'member_first_name', 'member_last_name'],
  },
  {
    value: 'NJMCR',
    label: 'New Jersey Medicare',
    states: ['New Jersey'],
    minRequiredInfo: ['member_id', 'member_first_name', 'member_last_name'],
  },
  {
    value: 'NYMCR',
    label: 'New York Medicare',
    states: ['New York'],
    minRequiredInfo: ['member_id', 'member_first_name', 'member_last_name'],
  },
  {
    value: 'PAMCR',
    label: 'Pennsylvania Medicare',
    states: ['Pennsylvania'],
    minRequiredInfo: ['member_id', 'member_first_name', 'member_last_name'],
  },
  {
    value: 'TXMCR',
    label: 'Texas Medicare',
    states: ['Texas'],
    minRequiredInfo: ['member_id', 'member_first_name', 'member_last_name'],
  },
  {
    value: 'VAMCR',
    label: 'Virginia Medicare',
    states: ['Virginia'],
    minRequiredInfo: ['member_id', 'member_first_name', 'member_last_name'],
  },
  {
    value: 'VTMCR',
    label: 'Vermont Medicare',
    states: ['Vermont'],
    minRequiredInfo: ['member_id', 'member_first_name', 'member_last_name'],
  },
  {
    value: 'WAMCR',
    label: 'Washington Medicare',
    states: ['Washington'],
    minRequiredInfo: ['member_id', 'member_first_name', 'member_last_name'],
  },
  ...MedicaidInsuranceList,
]

const INSURANCE_OTHER_OPTION_ID = 'other_none_of_these' as const
export const INSURANCE_OTHER_OPTION: PayerOnShortList = {
  label: 'Other/none of these',
  value: INSURANCE_OTHER_OPTION_ID,
  minRequiredInfo: [],
}

type InsurancePlanId = '' | `${string}__${string}` | 'other_none_of_these'

/**
 * Transforms the eligiblePayerId and provider values into a insurance plan unique identifier.
 * This is used when selecting an insurance plan on a select form.
 */
export function getInsurancePlanId(
  eligiblePayerId: string | undefined,
  provider: string | undefined,
): InsurancePlanId {
  if (eligiblePayerId === INSURANCE_OTHER_OPTION_ID) {
    return INSURANCE_OTHER_OPTION_ID
  } else if (eligiblePayerId && provider) {
    return `${eligiblePayerId}__${provider}`
  }

  return ''
}

/**
 * Separates an insurance plan unique identifier into two fields:
 * eligiblePayerId & provider
 */
export function getPayerIdAndProvider(value: InsurancePlanId): {
  eligiblePayerId: string
  provider: string
} {
  if (!value) {
    return {
      eligiblePayerId: '',
      provider: '',
    }
  }

  if (value === INSURANCE_OTHER_OPTION.value) {
    return {
      eligiblePayerId: INSURANCE_OTHER_OPTION.value,
      provider: INSURANCE_OTHER_OPTION.label,
    }
  }
  const insurance = value.split('__') as [string, string]

  return {
    eligiblePayerId: insurance[0],
    provider: insurance[1],
  }
}

/**
 * Returns the appropriate list to display for selecting an insurance plan.
 */
export function getPayersShortList(state?: string, showOtherOption = true) {
  return (
    PayersShortList
      // Remove plans from other states
      .filter(payer => !payer.states || !state || payer.states.includes(state))
      // Sort alphabetically
      .sort(sortBy({ key: 'label', order: 'ASC' }))
      // add the OTHER_OPTION as the last one
      .concat(showOtherOption ? [INSURANCE_OTHER_OPTION] : [])
      // Create unique values
      .map(payer => ({
        value: `${payer.value}__${payer.label}`,
        label: payer.label,
      }))
  )
}

/**
 * Returns minimum search criteria required to request coverage info
 * from Eligible API for a given payer
 */
export const getPayerSearchOptions = (value: InsurancePlanId) => {
  const { eligiblePayerId } = getPayerIdAndProvider(value)
  const payerFromShortList = PayersShortList.find(payer => payer.value === eligiblePayerId)
  return payerFromShortList?.minRequiredInfo || []
}

export type PlanType = '12' | '13' | '14' | 'CH' | 'CI' | 'FI' | 'HM' | 'MB' | 'MC' | 'VA' | 'WC'

export function isEmpireBlueBundle({
  eligiblePayerId,
  planType,
}: {
  eligiblePayerId: string
  planType: PlanType | null
}) {
  return Boolean(
    eligiblePayerId === EMPIRE_BLUE_PAYER_ID && planType && planType !== 'MC' && planType !== 'MB',
  )
}

export function isHighmarkBundle({
  eligiblePayerId,
  planType,
}: {
  eligiblePayerId: string
  planType: PlanType | null
}) {
  return Boolean(
    HIGHMARK_PAYER_IDS.includes(eligiblePayerId) &&
      planType &&
      planType !== 'MC' &&
      planType !== 'MB',
  )
}

export function isBundlePlan({
  eligiblePayerId,
  planType,
}: {
  eligiblePayerId: string
  planType: PlanType | null
}) {
  return (
    isEmpireBlueBundle({ eligiblePayerId, planType }) ||
    isHighmarkBundle({ eligiblePayerId, planType })
  )
}
