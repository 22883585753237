import { PasswordType, Patient } from '.'
import { AccountSettings } from './account'
import { PaymentCard } from './chargebee'

export type PaymentCardParams = {
  card: PaymentCard
}

export type InsurancePayer = { name: string; payerId: string }

export type CurrentUserPatient = Omit<Patient, 'account' | 'discharge'> & {
  account: {
    hasPassword: boolean
    passwordType: PasswordType
    profileComplete: boolean
    settings?: AccountSettings | undefined
    timezone: string
    waitingForWelcomeCall: boolean
    requiresOnboarding: boolean
    readyForPortal: boolean
  }
  files: {
    idCardFront?: string | undefined
    idCardBack?: string | undefined
  }
  discharge?: {
    date: string
    displayDischargeBanner: boolean
  }
  onlyOnePaymentMethodAllowed: boolean
}

export type PatientProvidedInsuranceData = {
  insuranceInfo: PatientInsurance
  secondaryInsuranceInfo?: PatientInsurance | undefined
}

export type PatientInsurance = {
  provider: string
  eligiblePayerId: string
  memberId: string
  groupId: string
  rxBin: string
  cardholderFirstName: string
  cardholderLastName: string
  cardholderBirthday: string
  insuranceCardFront?: string | undefined
  insuranceCardBack?: string | undefined
  patientIsPrimarySubscriber: boolean
  primarySubscriberFirstName: string
  primarySubscriberLastName: string
  primarySubscriberBirthday: string
}

export type PatientAttributes = {
  gclid?: string
}

export type Consents = {
  privacyPolicy?: boolean
  telehealthInformedConsent?: boolean
  termsOfUse?: boolean
  textMessageConsent?: boolean
}
