import {
  careTeamPods,
  ConsultationCallTaskType,
  DatabaseMetadata,
  Employee,
  ISOString,
  OtherString,
  TaskType,
} from '..'

export const EMRTaskStatuses = ['open', 'snoozed', 'closed'] as const
export type EMRTaskStatus = (typeof EMRTaskStatuses)[number]

export const getEMRTaskStatusLabel = (status: EMRTaskStatus) => {
  return {
    open: 'Open',
    snoozed: 'Snoozed',
    closed: 'Closed',
  }[status]
}

export const EMRTaskPods = [
  ...careTeamPods,
  'fc',
  'lcc',
  'lcc_specialist',
  'scc',
  'tn',
  'legal',
  'ec',
  'rn',
  'engineer',
  'ocp',
] as const
export type EMRTaskPod = (typeof EMRTaskPods)[number]

export type EMRTaskPodLabel<T extends 'pod' | 'role' = 'pod' | 'role'> = {
  label: string
  shortLabel: string
  type: T
  value: Employee['role'][]
}

export const EMRTaskPodLabels: Record<EMRTaskPod, EMRTaskPodLabel> = {
  alpha: {
    label: 'Alpha pod',
    shortLabel: 'Alpha pod',
    type: 'role',
    value: ['careCoordinator', 'leadCareCoordinator'],
  },
  gamma: {
    label: 'Gamma pod',
    shortLabel: 'Gamma pod',
    type: 'role',
    value: ['careCoordinator', 'leadCareCoordinator'],
  },
  fc: {
    label: 'Financial counselors',
    shortLabel: 'FC',
    type: 'role',
    value: ['financialCounselor'],
  },
  lcc: { label: 'Lead CCs', shortLabel: 'LCC', type: 'role', value: ['leadCareCoordinator'] },
  lcc_specialist: {
    label: 'Lead CC specialist',
    shortLabel: 'LCC specialist',
    type: 'role',
    value: ['leadCareCoordinator'],
  },
  scc: {
    label: 'Senior CCs',
    shortLabel: 'SCC',
    type: 'role',
    value: ['careCoordinator', 'leadCareCoordinator'],
  },
  tn: {
    label: 'Triage nurses',
    shortLabel: 'TN',
    type: 'role',
    value: [
      'tn',
      // NCMs (ie CCMs) help TNs with OCP coverage
      'ncm',
      'ncm_tn',
    ],
  },
  legal: { label: 'Legal', shortLabel: 'Legal', type: 'role', value: ['legal'] },
  ec: {
    label: 'Enrollment coordinators',
    shortLabel: 'EC',
    type: 'role',
    value: ['enrollmentCoordinator', 'leadEnrollmentCoordinator'],
  },
  rn: {
    label: 'Resource navigators',
    shortLabel: 'Resource',
    type: 'role',
    value: ['resourceNavigator'],
  },
  engineer: {
    label: 'Engineering',
    shortLabel: 'Eng',
    type: 'role',
    value: ['engineer'],
  },
  ocp: {
    label: 'OCP',
    shortLabel: 'OCP',
    type: 'role',
    value: ['pc', 'spc'],
  },
}
export const POD_OPTIONS = Object.entries(EMRTaskPodLabels).map(([value, { label }]) => ({
  value,
  label,
}))

export const getEMRTaskPodShortLabel = (pod: EMRTaskPod | undefined): string | undefined => {
  return pod ? EMRTaskPodLabels[pod]?.shortLabel : undefined
}

export const EMR_TASK_PRIORITIES = [1, 2, 3, 4, 5] as const

export type EMRTaskPriority = (typeof EMR_TASK_PRIORITIES)[number]

export type EMRTaskCounts = Partial<Record<EMRTaskStatus, number>>

export type EMRTaskNoteType = 'description' | 'note_added' | 'status_changed' | 'assignee_changed'

type EMRTaskNoteBase = {
  employeeId: 'system' | OtherString
  createdAt: string
}

export type EMRTaskNoteDecorated<T extends EMRTaskNoteType = EMRTaskNoteType> = EMRTaskNote<T> & {
  metadata: {
    employeeName: string
    assigneeBeforeName?: string
    assigneeAfterName?: string
  }
}
export type EMRTaskNote<T extends EMRTaskNoteType = EMRTaskNoteType> = DatabaseMetadata &
  EMRTaskNoteModel<T>
export type EMRTaskNoteModel<T extends EMRTaskNoteType = EMRTaskNoteType> = EMRTaskNoteBase &
  (T extends 'assignee_changed'
    ? {
        type: 'assignee_changed'
        assigneeBefore?: string
        assigneeAfter?: string
      }
    : T extends 'status_changed'
    ? {
        type: 'status_changed'
        statusBefore: EMRTaskStatus
        statusAfter: EMRTaskStatus
      }
    : T extends 'note_added'
    ? {
        type: 'note_added'
        text: string
        status: EMRTaskStatus
      }
    : {
        type: 'description'
        text: string
      })

export const WINBACK_CALL_TASK_OUTCOMES = [
  'Answered, booked',
  'Answered, needs more time',
  'Answered, not interested',
  'Missed',
  'Voicemail',
  'Not in service',
  'Canceled',
] as const
type WinbackCallTaskOutcomes = (typeof WINBACK_CALL_TASK_OUTCOMES)[number]

export type EMRTaskResults<
  T extends ConsultationCallTaskType | TaskType = ConsultationCallTaskType | TaskType,
> = T extends 'winback_call'
  ? {
      callOutcome?: {
        label: 'Call outcome'
        value: WinbackCallTaskOutcomes | null
        optional: false
      }
      nextWinbackCallDatetime?: {
        label: 'Schedule next winback call (optional)'
        value: ISOString | null
        optional: true
      }
    }
  : never

export type EMRTaskModel<
  T extends ConsultationCallTaskType | TaskType = ConsultationCallTaskType | TaskType,
> = {
  patientId: string
  updatedAt: string
  createdAt: string
  priority: EMRTaskPriority
  status: EMRTaskStatus
  employeeId?: string
  pod?: EMRTaskPod
  remindAt?: string
  /*
   * Some tasks will collect additional outcome data when being closed out.
   * This results data will be concatenated onto the wrap-up description in a
   * bulleted list.
   */
  results?: EMRTaskResults<T>
  // Who created this task? 'system' means it was auto-created.
  createdBy: 'system' | OtherString
  type: T
} & (T extends ConsultationCallTaskType
  ? {
      details: {
        appointmentId: number
        scheduledFor: string
      }
    }
  : {})

export type EMRTask<
  T extends ConsultationCallTaskType | TaskType = ConsultationCallTaskType | TaskType,
> = EMRTaskModel<T> & DatabaseMetadata

export type NewEMRTaskBody = Omit<
  EMRTaskModel,
  'updatedAt' | 'createdAt' | 'createdBy' | 'remindAt' | 'status'
> & {
  minutesTriaging?: number
  description: string
  setSystemGenerated?: boolean
}

export type EMRTaskMetadata = {
  patientFirstName: string
  patientLastName: string
  patientPhone: string
  patientState: string
  employeeName: string
  notesCount: number
  primaryClinicianFullName: string
}

export type EMRTaskDecorated = EMRTask & { metadata: EMRTaskMetadata }

export type EMRTaskTimeEntryModel = {
  createdAt: string
  employeeId: string
  minutes: number
  /** isBillableCoCM is set to true when the patient was active on the CoCM Registry at the time of the activity */
  isBillableCoCM: boolean
}

export type EMRTaskTimeEntry = EMRTaskTimeEntryModel & DatabaseMetadata
