// eslint-disable-next-line no-restricted-imports
import { Center } from '@mantine/core'
import React from 'react'
import { Checkbox, CheckboxProps } from './checkbox/Checkbox'

export const RoundCheckbox = ({
  disabled,
  children,
  fz = 'xs',
  ...props
}: Omit<CheckboxProps, 'label' | 'w' | 'h' | 'radius' | 'iconless' | 'sx'>) => {
  return (
    <Checkbox
      w={36}
      h={36}
      radius='xl'
      fz={fz}
      disabled={disabled}
      iconless
      sx={{ opacity: disabled ? '50%' : '100%' }}
      label={<Center>{children}</Center>}
      {...props}
    />
  )
}
