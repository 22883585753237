import { DatabaseMetadata } from '..'

export type OphelinkType = 'friend_referral' | 'friend_referral_personalized'

export type OphelinkModel = {
  type: OphelinkType
  sourceUrl: string
}

export type Ophelink = OphelinkModel & DatabaseMetadata
