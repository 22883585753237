import {
  Center,
  ExternalLinkText,
  Group,
  PhoneIcon,
  PrimaryButton,
  SecondaryButton,
  Stack,
  Text,
  UsersIcon,
} from '@shared/components'
import React from 'react'
import { OpheliaCommunity } from './OpheliaCommunity'
import { routes } from './routes'
import { useCommunityDims } from './useCommunityDims'

type FooterProps = {
  hideActions?: boolean
}

export const Footer = ({ hideActions }: FooterProps) => {
  const { isMobile } = useCommunityDims()

  if (isMobile) {
    return (
      <Stack p='lg' spacing='lg'>
        {!hideActions && (
          <Stack>
            <ExternalLinkText href='https://my.ophelia.com/welcome?utm_source=community&utm_medium=hub&utm_campaign=footer'>
              <PrimaryButton fullWidth leftIcon={<PhoneIcon />}>
                Schedule welcome call
              </PrimaryButton>
            </ExternalLinkText>
            <SecondaryButton
              fullWidth
              leftIcon={<UsersIcon />}
              component='a'
              href={routes.join.index}
            >
              Join the Ophelia Community
            </SecondaryButton>
          </Stack>
        )}
        <Text align='center' size='xs'>
          In alignment with our vision to create a safe space for people who have personally
          experienced or are looking for information about opioid use disorder to be seen, heal, and
          grow, we reserve the right to moderate this space and modify or remove content at our
          discretion.
        </Text>
        <Text align='center' size='xs'>
          Please review our{' '}
          <ExternalLinkText href='https://ophelia.com/terms-of-use'>Terms of use</ExternalLinkText>{' '}
          and{' '}
          <ExternalLinkText href='https://ophelia.com/privacy-policy'>
            Privacy policy
          </ExternalLinkText>
        </Text>
      </Stack>
    )
  }

  return (
    <Stack
      spacing='lg'
      p='xl'
      sx={theme => ({ backgroundColor: theme.other.colors.background[2] })}
    >
      {!hideActions && (
        <Group spacing='sm' position='center'>
          <ExternalLinkText href='https://my.ophelia.com/welcome?utm_source=community&utm_medium=hub&utm_campaign=footer'>
            <PrimaryButton leftIcon={<PhoneIcon />}>Schedule welcome call</PrimaryButton>
          </ExternalLinkText>
          <SecondaryButton leftIcon={<UsersIcon />} component='a' href={routes.join.index}>
            Join the Ophelia Community
          </SecondaryButton>
        </Group>
      )}
      <Center>
        <Text align='center' w='65%'>
          In alignment with our vision to create a safe space for people who have personally
          experienced or are looking for information about opioid use disorder to be seen, heal, and
          grow, we reserve the right to moderate this space and modify or remove content at our
          discretion. Please review our{' '}
          <ExternalLinkText href='https://ophelia.com/terms-of-use'>Terms of use</ExternalLinkText>{' '}
          and{' '}
          <ExternalLinkText href='https://ophelia.com/privacy-policy'>
            Privacy policy
          </ExternalLinkText>
          .
        </Text>
      </Center>
      <Center>
        <OpheliaCommunity />
      </Center>
    </Stack>
  )
}
