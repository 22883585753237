import { WorkflowType } from '../workflows'
import { AsqFormPromptContextKey, AsqFormPromptResponseContextKey } from './asqForm'
import { Barc10FormPromptContextKey, Barc10FormPromptResponseContextKey } from './barc10form'
import { Gad7FormPromptContextKey, Gad7FormPromptResponseContextKey } from './gad7Form'
import {
  MedicalIntakeFormPromptContextKey,
  MedicalIntakeFormPromptResponseContextKey,
} from './medicalIntakeForm'
import {
  Phq8FormPromptContextKey,
  Phq8FormPromptResponseContextKey,
  Phq9FormPromptContextKey,
  Phq9FormPromptResponseContextKey,
} from './phq9Form'
import {
  PatientReferralFormPromptContextKey,
  PatientReferralFormPromptResponseContextKey,
} from './referrals'
import { WelcomeCallPromptContextKey, WelcomeCallPromptResponseContextKey } from './welcomeCall'

/** Unique Reference to a prompt's meaning, separate from how it was phrased or which workflows it lives in */
export type PromptContextKey<T extends WorkflowType = WorkflowType> = T extends 'onboarding'
  ? WelcomeCallPromptContextKey
  : T extends 'medical_intake_form'
  ? MedicalIntakeFormPromptContextKey
  : T extends 'patient_referral_form'
  ? PatientReferralFormPromptContextKey
  : T extends 'patient_referral_form_partner'
  ? PatientReferralFormPromptContextKey
  : T extends 'phq_9_form'
  ? Phq9FormPromptContextKey
  : T extends 'phq_8_form'
  ? Phq8FormPromptContextKey
  : T extends 'gad_7_form'
  ? Gad7FormPromptContextKey
  : T extends 'barc_10_form'
  ? Barc10FormPromptContextKey
  : T extends 'asq_form'
  ? AsqFormPromptContextKey
  : never

/** Unique Reference to a prompt answer's meaning */
export type PromptResponseContextKey<T extends WorkflowType = WorkflowType> = T extends 'onboarding'
  ? WelcomeCallPromptResponseContextKey
  : T extends 'medical_intake_form'
  ? MedicalIntakeFormPromptResponseContextKey
  : T extends 'patient_referral_form'
  ? PatientReferralFormPromptResponseContextKey
  : T extends 'phq_9_form'
  ? Phq9FormPromptResponseContextKey
  : T extends 'phq_8_form'
  ? Phq8FormPromptResponseContextKey
  : T extends 'gad_7_form'
  ? Gad7FormPromptResponseContextKey
  : T extends 'barc_10_form'
  ? Barc10FormPromptResponseContextKey
  : T extends 'asq_form'
  ? AsqFormPromptResponseContextKey
  : never
