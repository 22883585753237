import capitalize from 'lodash/capitalize'
import { Appointment } from './models'

export type AppointmentTypeString =
  | 'Free Consultation Call'
  | 'Initial Visit'
  | 'Check-In Call'
  | 'Follow-Up Visit'
  | 'Peer Coaching Initial Visit'
  | 'Peer Coaching Follow Up Visit'
  | 'Support Call'
  | 'UDS Visit'
  | 'Wellness Visit'
  | 'Initial Wellness Visit'
  | 'Enrollment Support Call'

export const hasAppointmentType = (
  appointment: Appointment | undefined | null,
  ...types: AppointmentTypeString[]
): boolean => {
  return appointment ? types.includes(appointment.type) : false
}

export const getVisitTypeName = (type: AppointmentTypeString, lowercase?: boolean) => {
  if (type === 'Free Consultation Call') {
    return lowercase ? 'welcome call' : 'Welcome call'
  }

  if (type === 'Initial Visit') {
    return lowercase ? 'intake visit' : 'Intake visit'
  }

  if (type === 'UDS Visit') {
    return 'UDS visit'
  }

  return lowercase ? type.toLowerCase() : capitalize(type)
}

export const MEETING_LINK_PATHNAME = 'meeting'
