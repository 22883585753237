import { DatabaseMetadata, MMMMDoYYYYhmma } from '../../'
import { VisitNoteContent } from '../../api'
import {
  ConsultationVisitNoteContent,
  FollowupBHCMVisitNoteContent,
  InductionNoteContent,
  InitialBHCMVisitNoteContent,
  LegacyVisitNoteContent,
  PeerCheckinVisitNoteContent,
  UDSVisitNoteContent,
} from './content'

export * from './content'

export type VisitNoteType =
  | 'legacy'
  | 'induction'
  | 'consultation'
  | 'peercheckin'
  | 'uds'
  | 'bhcm'
  | 'initialBhcm'
  | 'maintenance'
  | 'blank'

export type VisitNoteModel<T extends VisitNoteType = VisitNoteType> = {
  userId: string
  createdAt: string
  visitId: number
  updatedAt?: string
  doseSpotPrescriptionIds?: number[]
  type: T
  content: T extends 'legacy'
    ? LegacyVisitNoteContent
    : T extends 'induction'
    ? InductionNoteContent
    : T extends 'consultation'
    ? ConsultationVisitNoteContent
    : T extends 'peercheckin'
    ? PeerCheckinVisitNoteContent
    : T extends 'uds'
    ? UDSVisitNoteContent
    : T extends 'bhcm'
    ? FollowupBHCMVisitNoteContent
    : T extends 'initialBhcm'
    ? InitialBHCMVisitNoteContent
    : VisitNoteContent
}

export type VisitNote = VisitNoteModel & DatabaseMetadata

export type VisitNoteVersionHistoryModel = Omit<VisitNoteModel, 'updatedAt'>

export type VisitNoteVersionHistory = VisitNoteVersionHistoryModel & DatabaseMetadata

export type VisitNoteAddendumModel = {
  content: string
  locked_by_calendar_id: number
  locked_by_user_id: string
  locked_by_name: string
  locked_at: MMMMDoYYYYhmma
}

export type VisitNoteAddendum = VisitNoteAddendumModel & DatabaseMetadata

export const isIntakeVisit = (vn: VisitNoteModel): vn is VisitNoteModel<'induction'> =>
  vn.type === 'induction'
export const isWelcomeCall = (vn: VisitNoteModel): vn is VisitNoteModel<'consultation'> =>
  vn.type === 'consultation'
export const isFollowUpVisit = (vn: VisitNoteModel): vn is VisitNoteModel<'maintenance'> =>
  vn.type === 'maintenance'
export const isCheckInCall = (vn: VisitNoteModel): vn is VisitNoteModel<'blank'> =>
  vn.type === 'blank'
export const isUDSVisit = (vn: VisitNoteModel): vn is VisitNoteModel<'uds'> => vn.type === 'uds'
export const isFollowupWellnessVisit = (vn: VisitNoteModel): vn is VisitNoteModel<'bhcm'> =>
  vn.type === 'bhcm'
export const isInitialWellnessVisit = (vn: VisitNoteModel): vn is VisitNoteModel<'initialBhcm'> =>
  vn.type === 'initialBhcm'
