// eslint-disable-next-line no-restricted-imports
import {
  Box,
  Group,
  Alert as MantineAlert,
  AlertProps as MantineAlertProps,
  useMantineTheme,
} from '@mantine/core'
import React, { ReactNode } from 'react'
import { Colors } from './theme'

type Variant = 'primary' | 'secondary' | 'warning' | 'success' | 'error'

function getThemeStyles({ colors, variant }: { colors: Colors; variant: Variant }) {
  switch (variant) {
    case 'primary':
      return {
        iconColor: colors.text[2],
        fontColor: colors.text[3],
        backgroundColor: colors.background[7],
      }
    case 'secondary':
      return {
        iconColor: colors.text[1],
        fontColor: colors.text[0],
        backgroundColor: colors.background[1],
      }
    case 'warning':
      return {
        iconColor: colors.warning[0],
        fontColor: colors.text[0],
        backgroundColor: colors.warning[1],
      }
    case 'error':
      return {
        iconColor: colors.error[0],
        fontColor: colors.text[0],
        backgroundColor: colors.error[1],
      }
    case 'success':
      return {
        iconColor: colors.success[0],
        fontColor: colors.text[0],
        backgroundColor: colors.success[1],
      }
  }
}

export type AlertProps = Omit<MantineAlertProps, 'variant' | 'styles' | 'title'> & {
  variant: Variant
  title?: ReactNode
  actionIcon?: ReactNode
}

export const Alert = ({
  variant = 'primary',
  title,
  actionIcon,
  icon,
  onClick,
  ...rest
}: AlertProps) => {
  const {
    fontSizes,
    other: { colors, sizes },
  } = useMantineTheme()

  const { iconColor, fontColor, backgroundColor } = getThemeStyles({ colors, variant })

  return (
    <MantineAlert
      {...rest}
      onClick={onClick}
      variant='filled'
      title={
        title && (
          <Group spacing='sm' noWrap sx={{ flexGrow: 1 }} position='apart' align='flex-start'>
            <Group spacing='sm' c={fontColor} noWrap align='center'>
              <Box sx={{ color: iconColor }}>{icon}</Box>
              {title}
            </Group>
            {actionIcon}
          </Group>
        )
      }
      // Don't use icon div if using title to allow message to use full width
      icon={!title && icon}
      styles={{
        label: {
          color: fontColor,
          fontSize: fontSizes.md,
          lineHeight: 'normal',
          flexGrow: 1,
          '& svg': { height: sizes.icon.md, width: sizes.icon.md },
        },
        body: { flexGrow: 1, flexDirection: 'column', display: 'flex', justifyContent: 'center' },
        message: {
          color: fontColor,
          fontSize: fontSizes.xs,
          lineHeight: 'normal',
        },
        title: {
          color: fontColor,
        },
        root: {
          color: colors.text[0],
          backgroundColor,
          paddingTop: sizes.padding.md,
          paddingBottom: sizes.padding.md,
          paddingLeft: sizes.gap.md,
          paddingRight: sizes.gap.md,
          cursor: onClick ? 'pointer' : 'unset',
        },
        icon: {
          color: iconColor,
          height: sizes.icon.md,
          width: sizes.icon.md,
          margin: 0,
          marginRight: sizes.gap.md,
        },
        closeButton: {
          color: colors.actions[0],
        },
      }}
    />
  )
}
